<script>
  import { onDestroy } from 'svelte'
  import { lookup as lookupContact, unopenedParcels as unopenedParcelsStore, logger, sendToContact, viewStack } from '../stores'
  import { decryptImageParcel } from '../functions/encryption'
  import { decryptAttachment } from '../functions/parcel-attachment-encryption.js'
  import { getCachedParcel } from '../functions/receive-parcels.js'
  import { inboxApi } from '../api'
  import MediaLookupWithTimer from './MediaLookupWithTimer'
  import { PARCEL_TYPES } from '../constants.js'
  import { dumeToNow } from '../functions/dume'

  const openedParcels = new Map()
  let visibleParcelId
  let errorMessage
  let isLoading = false
  let cleanupInterval

  function close () {
    lookupContact.clear()
    visibleParcelId = null
    isLoading = false
    errorMessage = null
  }

  function isParcelExpired (openedAt, duration) {
    const fallbackDuration = 9
    return openedAt && (Date.now() - openedAt) / 1000 > (duration ?? fallbackDuration)
  }

  function initializeCleanupInterval () {
    if (cleanupInterval) {
      return
    }

    cleanupInterval = setInterval(() => {
      if (openedParcels.size === 0) {
        clearInterval(cleanupInterval)
        cleanupInterval = null
        return
      }

      for (const [parcelId, parcel] of openedParcels) {
        if (isParcelExpired(parcel.openedAt, parcel.attachment?.meta?.duration)) {
          deleteParcel(parcelId)
        }
      }
    }, 400)
  }

  function deleteParcel (parcelId) {
    unopenedParcelsStore.setExpired(parcelId)
    openedParcels.delete(parcelId)
    inboxApi.parcelRequests.deleteFromCacheById(parcelId)
  }

  function deleteParcelManually () {
    deleteParcel(visibleParcelId)
    close()
  }

  async function showNext () {
    errorMessage = null

    if (document.visibilityState === 'hidden') {
      logger.log('Lookup/ showNext was called but lookup is closed because the app is in background')
      close()
      return
    }

    isLoading = true

    const parcels = $unopenedParcelsStore
      .filter((parcel) => parcel.uploadedBy === $lookupContact.id)
      .sort((a, b) => (a.uploadedAt < b.uploadedAt) ? -1 : ((a.uploadedAt > b.uploadedAt) ? 1 : 0))

    let nextParcelIndex

    for (let i = 0; i < parcels.length; i++) {
      if (!visibleParcelId) {
        // Take the first parcel from the contact if no parcel is visible
        nextParcelIndex = i
        break
      }

      if (visibleParcelId === parcels[i].id) {
        // If a parcel is already visible take the one that comes after current visible one
        nextParcelIndex = i + 1
        break
      }
    }

    const nextParcel = parcels[nextParcelIndex]

    if (nextParcel) {
      visibleParcelId = nextParcel.id

      if (!openedParcels.has(visibleParcelId)) {
        openedParcels.set(visibleParcelId, { uploadedAt: nextParcel.uploadedAt })
      }
    } else {
      close()
      return
    }

    const visibleParcel = openedParcels.get(visibleParcelId)

    if (!visibleParcel.attachment) {
      try {
        const response = await getCachedParcel(nextParcel)
        const buffer = await response.arrayBuffer()

        if (nextParcel.content?.secretKey) {
          visibleParcel.attachment = await decryptAttachment(new Uint8Array(buffer), nextParcel.content.secretKey, $lookupContact.publicKey)
        } else if (nextParcel.type === PARCEL_TYPES.IMAGE) {
          visibleParcel.attachment = await decryptImageParcel(new Uint8Array(buffer), $lookupContact.publicKey)
        }
      } catch (error) {
        if (error instanceof Response) {
          errorMessage = `Server Response (${error.status}): ${error.statusText}`
        } else {
          logger.error(error)
          errorMessage = error
        }

        return
      }
    }

    visibleParcel.contactDisplayName = $lookupContact.displayName

    if (nextParcel.openedAt == null) {
      visibleParcel.openedAt = Date.now()
      nextParcel.openedAt = visibleParcel.openedAt
      unopenedParcelsStore.put(nextParcel) // no await necessary

      // NOTE: It already tries to delete the parcel remotely, so it can be shown as "Openend" to the sender.
      // The actual cleanup is done later and will also try to delete the parcel remotely.
      inboxApi.parcelRequests.delete(nextParcel.id) // no await necessary
    } else {
      visibleParcel.openedAt = nextParcel.openedAt
    }

    isLoading = false

    initializeCleanupInterval()

    if (isParcelExpired(visibleParcel.openedAt, visibleParcel.attachment?.meta?.duration)) {
      showNext()
    }
  }

  function openCamera () {
    $sendToContact = {
      id: $lookupContact.id,
      displayName: $lookupContact.displayName
    }

    close()

    viewStack.clear()
  }

  onDestroy(() => clearInterval(cleanupInterval))

  $: if ($lookupContact) {
    showNext()
  }

  for (const parcel of $unopenedParcelsStore) {
    if (parcel.openedAt != null) {
      logger.log('Lookup/ set parcel as opened when setting up component, so it will be cleaned up properly')
      openedParcels.set(parcel.id, parcel)
    }
  }

  initializeCleanupInterval()
</script>

{#if isLoading || errorMessage}
  <div class="lookup-image">
    <div class="loading-and-error-screen">
      <div class="inner-container">
        {#if errorMessage}
          <p class="text-color-error">This pic can't be shown due to an error.</p>
          <p style="color: var(--dark-grey)"><small>{errorMessage}</small></p>

          <div class="vertical-empty-space-2x" />

          <p class="display-flex justify-content-space-around">
            <button class="-button-secondary background-color-inherit" on:click={deleteParcelManually}>Delete</button>
            <button class="-button-secondary background-color-inherit" on:click={showNext}>Show next</button>
          </p>
        {:else}
          <p>Loading pic... <svg class="feather-icon -rotate-animation -normalize"><use href="feather-sprite.svg#loader"/></svg></p>

          <div class="vertical-empty-space-2x" />

          <p class="text-align-center"><button class="-button-secondary -button-small background-color-inherit" on:click={close}>Close</button></p>
        {/if}
      </div>

      <footer>
        <p>
          <small>
            {visibleParcelId}<br />
            uploaded {dumeToNow(new Date(openedParcels.get(visibleParcelId)?.uploadedAt))} ago by {$lookupContact.displayName}
          </small>
        </p>
      </footer>
    </div>
  </div>
{:else if visibleParcelId}
  {@const visibleParcel = openedParcels.get(visibleParcelId)}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="lookup-image" on:click={showNext}>
    {#key visibleParcelId}
      <MediaLookupWithTimer
        content={visibleParcel.attachment}
        contactName={visibleParcel.contactDisplayName}
        openedAt={visibleParcel.openedAt}
        on:finish={showNext}
        on:close={close}
        on:reply={openCamera}
      />
    {/key}
  </div>
{/if}

<style>
  .lookup-image {
    position: absolute;
    z-index: 99999;
    top: 0;
    left: 0;
    height: var(--viewport-height);
    width: 100%;
    background-color: black;
  }

  .loading-and-error-screen {
    display: flex;
    flex-direction: column;
    justify-content: end;
    text-align: center;
    color: var(--grey);
    width: 100%;
    height: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
  }

  .loading-and-error-screen > footer {
    color: var(--dark-grey);
    margin-top: 10rem;
  }
</style>
