<script>
  import { onMount, createEventDispatcher } from 'svelte'
  import { fade } from 'svelte/transition'
  import { MEDIA_WIDTH, MEDIA_HEIGHT } from '../constants.js'
  import EditorColorPicker from './EditorColorPicker.svelte'
  import ActionBar from './ActionBar'

  export let canvasElement
  export let hide = false
  export let active

  const dispatch = createEventDispatcher()
  let selectedColor = 'rgb(255, 255, 255)'
  const lines = []
  let linesLength = 0
  let showColorPicker = false

  function throttle (func, timeFrame) {
    let lastTime = 0

    return (event) => {
      const now = Date.now()
      if (now - lastTime >= timeFrame) {
        func(event)
        lastTime = now
      }
    }
  }

  function dragStart (event) {
    if (!active) {
      return
    }

    dispatch('dragChange', { isDragging: true })

    const ctx = canvasElement.getContext('2d')
    const { top: canvasTopOffset, left: canvasLeftOffset } = canvasElement.getBoundingClientRect()
    const canvasScale = canvasElement.width / canvasElement.clientWidth

    const initialTouchPoint = {
      y: (event.touches[0].clientY - canvasTopOffset) * canvasScale,
      x: (event.touches[0].clientX - canvasLeftOffset) * canvasScale
    }

    const points = []

    setCanvasStyles(ctx)
    ctx.strokeStyle = selectedColor

    ctx.beginPath()
    ctx.moveTo(initialTouchPoint.x, initialTouchPoint.y)

    points.push({ x: initialTouchPoint.x, y: initialTouchPoint.y })

    const dragMove = throttle(draw, 20)

    function draw (event) {
      let touchPoint

      if (event.touches) {
        touchPoint = {
          y: (event.touches[0].clientY - canvasTopOffset) * canvasScale,
          x: (event.touches[0].clientX - canvasLeftOffset) * canvasScale
        }
      } else {
        touchPoint = event
      }

      ctx.lineTo(touchPoint.x, touchPoint.y)
      ctx.stroke()

      points.push({ x: Math.round(touchPoint.x), y: Math.round(touchPoint.y) })
    }

    function dragEnd () {
      if (points.length === 1) {
        draw(points[0])
      }

      ctx.closePath()

      lines.push({ color: selectedColor, points })
      linesLength = lines.length

      dispatch('dragChange', { isDragging: false })

      document.removeEventListener('touchmove', dragMove)
      document.removeEventListener('touchend', dragEnd)
    }

    document.addEventListener('touchmove', dragMove)
    document.addEventListener('touchend', dragEnd)
  }

  function undo () {
    if (lines.length === 0) {
      return
    }

    lines.pop()
    linesLength = lines.length

    const ctx = canvasElement.getContext('2d')
    ctx.clearRect(0, 0, canvasElement.width, canvasElement.height)

    setCanvasStyles(ctx)

    for (const line of lines) {
      const begin = line.points[0]

      ctx.beginPath()
      ctx.moveTo(begin.x, begin.y)
      ctx.strokeStyle = line.color

      for (const point of line.points) {
        ctx.lineTo(point.x, point.y)
        ctx.stroke()
      }
    }

    ctx.closePath()
  }

  function setCanvasStyles (ctx) {
    ctx.lineJoin = 'round'
    ctx.lineCap = 'round'
    ctx.lineWidth = 14
  }

  onMount(() => {
    canvasElement.width = MEDIA_WIDTH
    canvasElement.height = MEDIA_HEIGHT

    // NOTE: prevent `context-filter-polyfill` from monkey patching as this is causing bad perfomance and breaks some canvas behavior
    Object.defineProperty(canvasElement, '__skipFilterPatch', { value: true })
  })

  function toggleTouchStartListener (isActive) {
    if (!canvasElement) {
      return
    }

    if (isActive) {
      canvasElement.addEventListener('touchstart', dragStart)
    } else {
      /* This is the fix to enable iOS long press of image preview in ContactSelection */
      canvasElement.removeEventListener('touchstart', dragStart)
    }
  }

  function hideColorPickerWhenDeactived (isActive) {
    if (!isActive) {
      showColorPicker = false
    }
  }

  $: toggleTouchStartListener(active)
  $: hideColorPickerWhenDeactived(active)
</script>

<canvas
  bind:this={canvasElement}
  class:-hide={hide}
  class:-active={active}
/>

{#if active}
  <ActionBar left>
    <div slot="middle" transition:fade={{ duration: 100 }}>
      {#if linesLength > 0}
        <button class="-button-reset" on:click={undo}>
          <svg class="feather-icon -with-glow"><use href="feather-sprite.svg#rotate-ccw"/></svg>
        </button>
      {/if}

      <button
        class="-button-reset"
        on:click={() => { showColorPicker = !showColorPicker }}
        style:background-color={selectedColor}
        style:color={selectedColor.includes('rgb') ? 'var(--brand-color)' : 'var(--black)'}
        style:opacity={showColorPicker ? 0 : 1}
      >
        <svg class="feather-icon -with-glow"><use href="feather-sprite.svg#droplet"/></svg>
      </button>
    </div>
  </ActionBar>

  <EditorColorPicker bind:color={selectedColor} bind:isVisible={showColorPicker} />
{/if}

<style>
  canvas {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    touch-action: none;
    pointer-events: none;
  }

  canvas.-hide {
    opacity: 0.2;
  }

  canvas.-active {
    touch-action: unset;
    pointer-events: unset;
  }

  button {
    margin-top: 1rem;
  }
</style>
