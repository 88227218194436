<script>
  import { settings, logger } from '../stores'
  import { updateProfileBackup, createRecoveryString } from '../functions/profile-backup.js'
  import { dumeToNow } from '../functions/dume.js'
  import { toggleRecoveryKeyLock, optionalAuthBeforeCopy } from '../functions/recovery-key-lock.js'
  import CopyButton from './CopyButton.svelte'
  import PopoverList from './PopoverList.svelte'

  export let showOnlyCopyPrompt = false
  export let copyPromptPrefix = ''
  export let copyPromptSuffix = ''
  export let backupInSetup = false

  const recoveryString = createRecoveryString($settings)
  let isLoading = false
  let updateProfileBackupFailed = false
  let isUnlocked = false

  async function update () {
    isLoading = true

    try {
      await updateProfileBackup($settings)
    } catch (error) {
      updateProfileBackupFailed = true
      logger.error(error, 'updateProfileBackup')
    }

    isLoading = false
  }

  async function showRecoveryKey () {
    isUnlocked = await optionalAuthBeforeCopy()
  }

  if (backupInSetup) {
    update()
  }
</script>

<div class="content-box">
  {#if !showOnlyCopyPrompt}
    <header>
      <span>
        <svg class="feather-icon -normalize"><use href="feather-sprite.svg#upload-cloud"/></svg>
        Profile Backup
      </span>

      <PopoverList options={[
        { label: 'Backup now', handler: update },
        { label: `${$settings.isRecoveryKeyLocked ? 'Disable' : 'Enable'} recovery key lock`, handler: toggleRecoveryKeyLock }
      ]} />
    </header>

    <p>
      Your profile is saved automatically as encrypted backup on your inbox server.
      If you ever need or want to use the backup, you will need your recovery key.
    </p>
  {/if}

  <p>
    {#if copyPromptPrefix}{copyPromptPrefix} {/if}
    Copy your recovery key to a safe and secret location.
    {#if copyPromptSuffix} {copyPromptSuffix}{/if}
  </p>

  {#if $settings.isRecoveryKeyLocked && !isUnlocked}
    <p>
      <button class="-button-secondary -button-small full-width" on:click={showRecoveryKey}>
        Unlock to copy

        <svg class="feather-icon"><use href="feather-sprite.svg#unlock" /></svg>
      </button>
    </p>
  {:else}
    <p class="scrollable-code-line"><code>Recovery Key: {recoveryString}</code></p>

    <p>
      <CopyButton class="-button-secondary -button-small full-width" content={recoveryString}>
        Copy recovery key
      </CopyButton>
    </p>
  {/if}

  {#if isLoading && !updateProfileBackupFailed && !showOnlyCopyPrompt}
    <p><small>Backing up...</small></p>
  {:else if $settings.lastProfileBackup && ((!showOnlyCopyPrompt) || (showOnlyCopyPrompt && updateProfileBackupFailed))}
    {@const duration = dumeToNow(new Date($settings.lastProfileBackup))}

    <p>
      <small>
        {#if updateProfileBackupFailed}Failed to upload new backup. {/if}
        Last Backup: {#if duration}{duration} ago{:else}now{/if}
      </small>
    </p>
  {:else if updateProfileBackupFailed}
    <p><small>Failed to upload backup</small></p>
  {/if}
</div>
