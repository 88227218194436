import { writable } from 'svelte/store'
import { database } from './indexeddb'
import { createServiceWorkerKeyValueStore } from '../functions/service-worker-key-value-store.js'
import { sendMessageToAppWrapper, isInAppWrapper } from '../functions/app-wrapper-communication.js'
import { APP_WRAPPER_MESSAGE_TYPES } from '../constants.js'

const STORE_NAME = 'received_parcels'

export function createUnopenedParcelsStore () {
  const { subscribe, set } = writable([])

  async function updateStore (tx) {
    let parcels

    if (tx) {
      parcels = await tx.store.getAll()
    } else {
      parcels = await database.getAll(STORE_NAME)
    }

    const filteredParcels = parcels.filter((parcel) => !parcel.isExpired)

    set(filteredParcels)

    const count = parseInt(filteredParcels.length) || 0

    if ('setAppBadge' in window.navigator) {
      window.navigator.setAppBadge(count)

      createServiceWorkerKeyValueStore()
        .then((swKeyValueStore) => {
          // It's own then-callback, because i'm not sure if there are any problems
          // opening two database at the same time...
          swKeyValueStore.set('badgeCount', count)
        })
    }

    if (isInAppWrapper()) {
      sendMessageToAppWrapper({ type: APP_WRAPPER_MESSAGE_TYPES.OUTGOING.NOTIFICATION_SET_BADGE, value: count })
    }

    return parcels
  }

  function load () {
    return updateStore()
  }

  return {
    subscribe,
    load,

    async put (parcel) {
      await database.put(STORE_NAME, parcel)
      await load()
    },

    get (id) {
      return database.get(STORE_NAME, id)
    },

    async getAllExpired () {
      const tx = database.transaction(STORE_NAME, 'readonly')
      const result = []

      let cursor = await tx.store.openCursor()

      while (cursor) {
        if (cursor.value.isExpired) {
          result.push(cursor.value)
        }

        cursor = await cursor.continue()
      }

      return result
    },

    async deleteByIds (ids) {
      const tx = database.transaction(STORE_NAME, 'readwrite')

      for (const id of ids) {
        await tx.store.delete(id)
      }

      await load(tx)
    },

    async setExpired (id) {
      const tx = database.transaction(STORE_NAME, 'readwrite')
      const parcel = await tx.store.get(id)

      parcel.isExpired = true
      await tx.store.put(parcel)

      await tx.done
      await load()
    },

    async setAll (parcels, contactIds) {
      const tx = database.transaction(STORE_NAME, 'readwrite')

      const allKnown = (await tx.store.getAll()).map((parcel) => parcel.id)

      for (const parcel of parcels) {
        if (allKnown.includes(parcel.id)) {
          // All parcels that are already persisted (including expired) should not
          // get overwritten, because the may include a changed property (like openedAt).
          continue
        }

        if (!contactIds.includes(parcel.uploadedBy)) {
          console.log(`Parcel "${parcel.id}" won't be downloaded because no corresponding contact to id "${parcel.uploadedBy}" can be found.`)
          continue
        }

        await tx.store.put({
          id: parcel.id,
          type: parcel.type,
          uploadedBy: parcel.uploadedBy,
          uploadedAt: parcel.uploadedAt,
          content: parcel.content,
          isCached: false,
          openedAt: null,
          isExpired: false
        })
      }

      const parcelsInStore = await updateStore(tx)

      await tx.done

      return parcelsInStore
    },

    async clearAll () {
      await database.clear(STORE_NAME)
      await load()
    }
  }
}
