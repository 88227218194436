import Landingpage from './Landingpage'
import InstallAppResponse from './InstallAppResponse.svelte'
import CreateProfile from './CreateProfile'
import SetupFinish from './SetupFinish'
import Inbox from './Inbox'
import Profile from './Profile'
import ContactExchangeOverview from './ContactExchangeOverview'
import ContactExchangeAccept from './ContactExchangeAccept'
import ContactExchangeSignupInboxServer from './ContactExchangeSignupInboxServer'
import ContactExchangeSaveContact from './ContactExchangeSaveContact.svelte'
import ContactExchangeExplanation from './ContactExchangeExplanation.svelte'
import MoveInboxServer from './MoveInboxServer'
import ContactDetail from './ContactDetail'
import Settings from './Settings'
import RecoverProfile from './RecoverProfile'
import Debug from './Debug'
import ClientPrivacyPolicy from './ClientPrivacyPolicy'
import IosQuirksExplanation from './IosQuirksExplanation.svelte'
import InboxServerExplanation from './InboxServerExplanation.svelte'
import InboxServerDetail from './InboxServerDetail.svelte'
import CreditsAndThanks from './CreditsAndThanks.svelte'
import InviteTree from './InviteTree.svelte'
import ContactMatcher from './ContactMatcher.svelte'
import LogoutProfile from './LogoutProfile.svelte'
import DeleteProfile from './DeleteProfile.svelte'

const COMPONENTS = {
  Landingpage,
  InstallAppResponse,
  CreateProfile,
  SetupFinish,
  Inbox,
  Profile,
  ContactExchangeOverview,
  ContactExchangeAccept,
  ContactExchangeSignupInboxServer,
  ContactExchangeSaveContact,
  ContactExchangeExplanation,
  MoveInboxServer,
  ContactDetail,
  Settings,
  RecoverProfile,
  Debug,
  ClientPrivacyPolicy,
  IosQuirksExplanation,
  InboxServerExplanation,
  InboxServerDetail,
  CreditsAndThanks,
  InviteTree,
  ContactMatcher,
  LogoutProfile,
  DeleteProfile
}

export function mapNameToComponent (view) {
  return COMPONENTS[view.name]
}
