<script>
  import { viewStack, settings, logger, loadAll } from '../stores'
  import { openAndMigrateDatabase } from '../stores/indexeddb'
  import CreateMediaView from './CreateMediaView'
  import Lookup from './Lookup'
  import GlobalLayeredView from '../components/GlobalLayeredView'
  import { backgroundSync } from '../functions/background-sync.js'
  import { setupWindowResizeListener } from '../functions/window-resize-listener.js'
  import { setupOnlineOfflineEventListener } from '../functions/setup-online-offline-event-listener.js'
  import { inboxApi } from '../api'
  import { IS_IOS } from '../constants.js'
  import { longpress } from '../actions/longpress.js'
  import { handleServiceWorkerMessages } from '../functions/handle-service-worker-messages.js'
  import { handleBrowserBackNavigation } from '../functions/handle-browser-back-navigation.js'

  const isStandalone = window.matchMedia('(display-mode: standalone)').matches || !!localStorage.getItem('piqchat-ignore-is-standalone-check')
  let isStoreReady = false
  let hasProfile = false

  $: inboxApi.updateApiState($settings)
  $: visibleGlobalLayeredViews = $viewStack.filter((item) => item.name != null)

  async function setup () {
    await openAndMigrateDatabase()
    await loadAll()

    isStoreReady = true

    checkWhatToShow()

    backgroundSync($settings)
  }

  setup()

  $: checkWhatToShow($settings)

  async function checkWhatToShow () {
    if (!isStandalone) {
      viewStack.push('Landingpage', {}, { once: true })
      return
    }

    if (!isStoreReady) {
      return
    }

    hasProfile = hasProfile || await settings.hasProfile()

    if (!hasProfile) {
      viewStack.push('CreateProfile', {}, { ignoreWhen: ['RecoverProfile'] })
      return
    }

    if (!$settings.inboxServerHost) {
      viewStack.push('ContactExchangeAccept', { changeInboxServerHost: true }, { ignoreWhen: ['ContactExchangeSignupInboxServer', 'RecoverProfile', 'ContactExchangeSaveContact'] })
    }
  }

  window.addEventListener('error', ({ message, url, linenumber }) => {
    logger.error({ message, url, linenumber })
    return true
  })

  window.addEventListener('unhandledrejection', (event) => {
    // TODO: sadly this is not as simple, because also rejected fetches can get in here...
    /* const confirmResult = window.confirm(`An error occured. Please reload the app by clicking "ok".\n\n${event.reason}`) */
    /* if (confirmResult) { */
    /*   window.location.reload() */
    /* } */
  })

  navigator.serviceWorker.addEventListener('message', (event) => {
    if (isStoreReady && hasProfile) {
      handleServiceWorkerMessages(event.data, $settings)
    }
  })

  window.addEventListener('visibilitychange', () => {
    if (!isStoreReady || !hasProfile) {
      return
    }

    if (document.visibilityState === 'visible') {
      backgroundSync($settings)
    }
  })

  setupWindowResizeListener()
  setupOnlineOfflineEventListener()
  handleBrowserBackNavigation()
</script>

<svelte:document on:dblclick={(event) => { if (IS_IOS && $settings.isDebugMode) { event.preventDefault(); logger.log('track dblclick') } }} />

<button class="-button-small debug" use:longpress={1000} on:longpress={() => viewStack.push('Debug', { json: null })}>
  <svg class="feather-icon -normalize -with-glow">
    <use href="feather-sprite.svg#terminal"/>
  </svg>
</button>

<main>
  {#if isStoreReady}
    {#if isStandalone && hasProfile}
      <CreateMediaView />

      <Lookup />
    {/if}

    {#each visibleGlobalLayeredViews as view}
      {#key view.id}
        <GlobalLayeredView {view} />
      {/key}
    {/each}
  {/if}
</main>

<div class="display-none orientation-warning">
  <strong>Turn your phone back to potrait orientation</strong><br />
  or activate orientation lock in your control center.
</div>

<style>
  main {
    margin: 0 auto;
    max-width: var(--max-width);
  }

  button.debug {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1000;
    transition: opacity 1s linear;
  }

  button.debug:global(.longpress-active) {
    opacity: 1;
  }

  @media (max-height: 500px) and (orientation: landscape) {
    .orientation-warning {
      display: block;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.8);
      text-align: center;
      color: var(--white);
      font-size: 1.5rem;
      padding: 5rem;
      position: absolute;
      top: 0;
      left: 0;
      line-height: 1.4;
    }
  }
</style>
