import { openDB, deleteDB } from 'idb'
import { BROWSER_STORAGE_PREFIX } from '../constants.js'

const DATABASE_NAME = `${BROWSER_STORAGE_PREFIX}-service-worker-keyvalue` // NOTE: Duplicated in service-worker!
const OBJECT_STORE_NAME = 'keyvalue' // NOTE: Duplicated in service-worker!

/**
 * The service worker itself uses the indexeddb directly without the idb package.
 * A keyvalue store is used to keep the api between the client and the
 * service worker as simple as possible, otherwise I imagine app updates could
 * get complicated. It's only used as simple transfer storage.
 */
export async function createServiceWorkerKeyValueStore () {
  const db = await openDB(DATABASE_NAME, 1, {
    upgrade (db) {
      db.createObjectStore(OBJECT_STORE_NAME)
    }
  })

  return {
    set (key, value) {
      return db.put(OBJECT_STORE_NAME, value, key)
    },

    get (key) {
      return db.get('keyvalue', key)
    }
  }
}

export function deleteServiceWorkerKeyValueStore () {
  return deleteDB(DATABASE_NAME)
}
