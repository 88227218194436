export async function uploadRequestWithProgress (url, options) {
  options = {
    onProgressFn: () => {},
    body: null,
    method: 'POST',
    headers: {},
    ...options
  }

  const xhr = new XMLHttpRequest()

  return await new Promise((resolve, reject) => {
    xhr.addEventListener('load', () => {
      if (xhr.readyState) {
        const options = {
          url: xhr.responseURL,
          statusText: xhr.statusText,
          status: xhr.status
        }

        const body = 'response' in xhr ? xhr.response : xhr.responseText

        resolve(new Response(body, options))
      }
    })

    xhr.addEventListener('error', () => {
      reject(new TypeError('XHR Network request failed'))
    })

    xhr.addEventListener('abort', () => {
      reject(new DOMException('XHR Aborted', 'AbortError'))
    })

    xhr.addEventListener('timeout', () => {
      reject(new TypeError('XHR Network request timed out'))
    })

    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const progressPercentage = event.loaded / event.total * 100

        options.onProgressFn(progressPercentage)
      }
    })

    xhr.open(options.method, url)

    for (const [key, value] of Object.entries(options.headers)) {
      xhr.setRequestHeader(key, value)
    }

    xhr.send(options.body)
  })
}
