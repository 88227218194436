<script>
  import ViewHeader from '../ViewHeader'
  import PushNotificationSetting from '../PushNotificationSetting'
  import { viewStack, settings } from '../../stores'
  import { isSupported } from '../../functions/push-notifications.js'
  import { IS_IOS } from '../../constants.js'

  const isNotificationSupported = isSupported()

  if (IS_IOS && settings.disableAutomaticCameraStart == null) {
    settings.set('disableAutomaticCameraStart', true)
  }
</script>

<ViewHeader disableClose>Almost done 🤩</ViewHeader>

{#if isNotificationSupported}
  <PushNotificationSetting />

  <div class="vertical-empty-space-half" />
{/if}

{#if isNotificationSupported}<h4>A few notes</h4>{/if}

<p>
  Under the inbox icon <svg class="feather-icon"><use href="feather-sprite.svg#inbox"/></svg>
  you can find all your friends, view received pics and manage friend invites.
</p>

<p>
  Click on <svg class="feather-icon"><use href="feather-sprite.svg#user"/></svg> to go to your profile.
</p>

<div class="vertical-empty-space" />

<p>
  <button class="-button-primary full-width" on:click={() => viewStack.clear()}>Awesome 🙌</button>
</p>

<style>
  p > .feather-icon {
    width: 1.7rem;
    height: 1.7rem;
    background-color: var(--light-grey);
    padding: 0.3rem;
    border-radius: 0.2rem;
    vertical-align: bottom;
  }
</style>
