import { contacts as contactStore, logger, uploadingProgress } from '../stores'
import { inboxApi } from '../api'
import { updateLevelPointsCausedByMe, createPointsOfContact } from './contact-points.js'
import { parseIsoString } from './dume.js'

// TODO: This function could be abstracted further to allow a general grouped parcel upload - not only for MEDIA with attachments and contact points
export async function uploadMediaGroupParcel (queueItem, parcelType) {
  const recipients = await contactStore.getSelection(queueItem.recipientIds)

  const recipientsGroupedByInbox = {}

  for (const recipient of recipients) {
    if (recipient.inboxServerHost in recipientsGroupedByInbox) {
      recipientsGroupedByInbox[recipient.inboxServerHost].push(recipient)
    } else {
      recipientsGroupedByInbox[recipient.inboxServerHost] = [recipient]
    }
  }

  const totalFailedRecipientIds = []

  for (const [inboxServerHost, recipients] of Object.entries(recipientsGroupedByInbox)) {
    for (const contact of recipients) {
      await updateContactBeforeRequest(contact)
    }

    const contactPointsMap = calculateContactPoints(recipients)

    const parcel = {
      recipientContents: createRecipientContents(queueItem.secretKeys, recipients, contactPointsMap),
      attachment: queueItem.attachment,
      type: parcelType
    }

    const uploadResult = await uploadToInboxServer(inboxServerHost, parcel)

    totalFailedRecipientIds.push(...uploadResult.failedRecipientIds)

    for (const contact of recipients) {
      let errorType

      if (uploadResult.type === 'NETWORK_ERROR' || uploadResult.type === 'REQUEST_ERROR') {
        errorType = uploadResult.type
      } else if (uploadResult.type === 'FORBIDDEN' && uploadResult.failedRecipientIds.includes(contact.id)) {
        errorType = 'FORBIDDEN'
      }

      await updateContactAfterRequest(contact, contactPointsMap.get(contact.id), errorType)
    }
  }

  if (totalFailedRecipientIds.length === queueItem.recipientIds.length) {
    return 'UNCHANGED'
  } else if (totalFailedRecipientIds.length > 0) {
    return {
      ...queueItem,
      recipientIds: totalFailedRecipientIds
    }
  } else {
    return 'DROP'
  }
}

function onProgressFn (recipientIds) {
  return (percentage) => {
    uploadingProgress.set({ percentage: Math.round(percentage), recipientIds })
  }
}

async function uploadToInboxServer (inboxServerHost, parcel) {
  const recipientIds = parcel.recipientContents.map(({ recipientId }) => recipientId)

  try {
    uploadingProgress.set({ percentage: 10, recipientIds })

    const response = await inboxApi.parcelRequests.upload(parcel, inboxServerHost, onProgressFn(recipientIds))

    const { forbiddenRecipientIds } = await response.json()

    uploadingProgress.set(null)

    if (forbiddenRecipientIds?.length > 0) {
      return { type: 'FORBIDDEN', failedRecipientIds: forbiddenRecipientIds }
    }

    return { type: 'SUCCESS', failedRecipientIds: [] }
  } catch (error) {
    uploadingProgress.set(null)

    if (error instanceof Response && error.status >= 400 && error.status < 500) {
      if (error.status === 403) {
        return { type: 'FORBIDDEN', failedRecipientIds: recipientIds }
      } else {
        logger.warn(`A parcel could not be uploaded because the inbox server rejected it with "${error.status}" (${error.url})`)

        return { type: 'REQUEST_ERROR', failedRecipientIds: recipientIds }
      }
    } else {
      logger.error(error)
    }

    logger.warn(`Failed to upload to inbox server ${inboxServerHost} for ${recipientIds.length} recipient(s)`)

    return { type: 'NETWORK_ERROR', failedRecipientIds: recipientIds }
  }
}

function createRecipientContents (secretKeys, recipients, contactPointsMap) {
  const recipientContents = []

  for (const contact of recipients) {
    const contactPoints = contactPointsMap.get(contact.id)

    recipientContents.push({
      recipientId: contact.id,
      content: JSON.stringify({
        secretKey: secretKeys[contact.id],
        points: {
          count: contactPoints.count,
          updatedAt: contactPoints.updatedAt
        }
      })
    })
  }

  return recipientContents
}

function calculateContactPoints (contacts) {
  const pointChanges = new Map()

  for (const contact of contacts) {
    const pointsOfContact = contact.points ?? createPointsOfContact()
    const newPointsOfContact = updateLevelPointsCausedByMe(pointsOfContact)

    pointChanges.set(contact.id, newPointsOfContact)
  }

  return pointChanges
}

async function updateContactBeforeRequest (contact) {
  if (parseIsoString(contact.lastSentAt) < new Date()) {
    contact.lastSentAt = parseIsoString(new Date())
  }

  await contactStore.set(contact)
}

async function updateContactAfterRequest (contact, contactPoints, errorType) {
  if (errorType) {
    contact.error = { type: errorType, time: (new Date()).toISOString() }
  } else {
    contact.error = null

    if (parseIsoString(contact.lastSuccessfullySentAt) < new Date()) {
      contact.lastSuccessfullySentAt = parseIsoString(new Date())
    }

    contact.points = contactPoints
  }

  await contactStore.set(contact)
}
