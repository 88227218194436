<script>
  import { viewStack } from '../../stores'
  import ViewHeader from '../ViewHeader'
</script>

<ViewHeader>What is a friend invite?</ViewHeader>

<p>
  You can create a friend invite to exchange contact information with someone.
  Afterwards you can send pics to each other.
</p>

<p>A friend invite can only be used once and is only valid for a limited time.</p>

<p>The friend invite also needs to be used to sign up a new user to an inbox server.</p>

<div class="vertical-empty-space-half" />

<details class="content-box">
  <summary>What information will be shared?</summary>

  <p>When exchanging contact information, some of your personal data is shared with your friend:</p>

  <p>Your choosen <strong>nickname</strong> as suggestion.</p>
  <p>Your <strong>piqchat identifier</strong> to be able to identify and encrypt messages for you.</p>
  <p>Which <strong>inbox server</strong> you are signed up to, so they can reach you.</p>
  <p>
    The inbox server of your friend will see your <strong>piqchat identifier</strong> and <strong>IP address and other metadata</strong>.
  </p>

  <p>Your contact information are end-to-end encrypted.</p>
</details>

<p>
  <button on:click={() => viewStack.push('InboxServerExplanation')} class="-button-link">What is an inbox server?</button>
</p>
