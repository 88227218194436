<script>
  import ViewHeader from '../ViewHeader'
  import { settings, viewStack, isOnline } from '../../stores'
  import { applyProfileBackup, parseRecoveryString } from '../../functions/profile-backup.js'
  import { handleError } from '../../functions/handle-error.js'

  const RECOVERY_MODE = { BACKUP: 'BACKUP', ONLY_IDENTITY: 'ONLY_IDENTITY', IDENTITY_WITH_ACCOUNT: 'IDENTITY_WITH_ACCOUNT' }

  let recoveryString = null
  let parsedRecoveryInfo
  let errorMessage = ''
  let isLoading = false
  let recoveryMode = RECOVERY_MODE.BACKUP

  $: isRecoveryStringValid = recoveryString?.trim() !== ''

  async function recoverFromBackup () {
    isLoading = true

    try {
      await applyProfileBackup(recoveryString)

      window.location.reload()
    } catch (error) {
      if (error.message === 'REQUEST_INBOX_SERVER_FAILED') {
        if (error.cause instanceof Response && error.cause.url.includes('backup') && error.cause.status === 404) {
          // NOTE: If there was no issue reaching the inbox server but the backup is just missing
          // the user should not need to sign up to the server again.
          recoveryMode = RECOVERY_MODE.IDENTITY_WITH_ACCOUNT
        } else {
          recoveryMode = RECOVERY_MODE.ONLY_IDENTITY
        }

        parsedRecoveryInfo = parseRecoveryString(recoveryString)
      } else {
        errorMessage = handleError(error)
      }

      isLoading = false
    }
  }

  async function recoverIdentity () {
    isLoading = true

    await Promise.allSettled([
      settings.set('publicKey', parsedRecoveryInfo.publicKey),
      settings.set('privateKey', parsedRecoveryInfo.privateKey)
    ])

    if (recoveryMode === RECOVERY_MODE.IDENTITY_WITH_ACCOUNT) {
      await settings.set('inboxServerHost', parsedRecoveryInfo.inboxServerHost)
    }

    viewStack.pop()
  }

  settings.set('allInboxServerMap', {})
  settings.set('publicKey', null)
  settings.set('privateKey', null)
</script>

<ViewHeader disableClose={isLoading}>Recover existing profile</ViewHeader>

{#if recoveryMode === RECOVERY_MODE.BACKUP}
  <p>
    You can recover your profile with your secret recovery key.
    It then downloads the most recent backup with your contacts and some other settings.
  </p>

  {#if !$isOnline}
    <div class="vertical-empty-space-half" />

    <div class="content-box -has-warning">
      <p>
        <strong>You are offline.</strong>
        To recover your profile backup you need to be connected to the internet.
      </p>
    </div>
  {/if}

  <div class="vertical-empty-space" />

  <div class="form">
    <label for="recoveryString" class="label">Recovery key</label>
    <input id="recoveryString" class="input" type="password" bind:value={recoveryString} disabled={isLoading} autocomplete="off" placeholder="Paste..." />
  </div>

  {#if recoveryString != null && !isRecoveryStringValid}
    <p class="text-color-error">The recovery key is invalid</p>
  {/if}

  {#if errorMessage}
    <p class="text-color-error">{errorMessage}</p>
  {/if}
{:else}
  <div class="content-box -has-warning">
    <p>
      <strong>There was a problem downloading your profile backup.</strong>

      {#if recoveryMode === RECOVERY_MODE.ONLY_IDENTITY}
        The inbox server <code>{parsedRecoveryInfo.inboxServerHost}</code> can't be reached or you are not authorized.
      {:else}
        Your profile backup couldn't be found.
      {/if}
    </p>
  </div>

  <p>
    However you can create a profile with the same piqchat identifier.
    {#if recoveryMode === RECOVERY_MODE.ONLY_IDENTITY}You will need a friend invite to sign up to an inbox server.{/if}
  </p>
{/if}

<div class="vertical-empty-space" />

<p class="text-align-center">
  <button
    on:click={() => recoveryMode === RECOVERY_MODE.BACKUP ? recoverFromBackup() : recoverIdentity()}
    disabled={recoveryString == null || !isRecoveryStringValid || isLoading || !$isOnline}
    class="-button-primary"
  >
    Recover {#if recoveryMode !== RECOVERY_MODE.BACKUP}only identity <strong>without</strong> backup{/if}

    {#if isLoading}
      <svg class="feather-icon -normalize -rotate-animation"><use href="feather-sprite.svg#loader"/></svg>
    {/if}
  </button>
</p>

<div class="vertical-empty-space" />

{#if recoveryMode === RECOVERY_MODE.BACKUP}
  <p><small>Please make sure you only use <em>one</em> client/app with your profile, otherwise this can lead to unexpected behaviour.</small></p>
{:else}
  <p class="text-align-center">
    <button class="-button-link" on:click={() => viewStack.pop()}>Cancel</button>
  </p>
{/if}
