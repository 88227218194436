import { contacts as contactStore } from '../stores'
import { inboxApi } from '../api'
import { parseIsoString } from '../functions/dume.js'

export async function checkForChangedOpenedStatus (contacts) {
  const relevantContacts = contacts.filter((contact) => {
    // Only contacts that have sent something since the last time the recipient opened something of them are of interest
    return parseIsoString(contact.lastSuccessfullySentAt) > parseIsoString(contact.lastOpenedAt)
  })

  const contactIds = new Set(relevantContacts.map((contact) => contact.id))

  const lastOpenedAtChanges = await contactStore
    .getBulkTemplate('lastOpenedAt', contactIds)

  const requests = []

  for (const contact of relevantContacts) {
    requests.push(inboxApi.parcelRequests.getContactStatus(contact.id, contact.inboxServerHost)
      .then((response) => response.json())
      .then((json) => {
        if (json.count === 0) {
          lastOpenedAtChanges.set(contact.id, (new Date()).toISOString())
        }
      })
    )
  }

  await Promise.allSettled(requests)

  contactStore.bulkUpdate('lastOpenedAt', lastOpenedAtChanges)
}
