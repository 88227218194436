<script>
  import { updateApp } from '../functions/update-app.js'
  import { RELOAD_HANDLING_TYPES } from '../constants.js'

  const { version, commit } = ROLLUP.BUILD_INFO
</script>

<p class="text-align-center">
  🎀<br/>
  piqchat version {version} ({commit})<br />

  <button class="-button-link" on:click={() => updateApp(RELOAD_HANDLING_TYPES.ASK)}>
    force app update
  </button>
</p>

<style>
  p,
  button {
    font-size: 0.8rem;
  }
</style>
