<script>
  import { onDestroy, createEventDispatcher } from 'svelte'
  import { viewStack } from '../stores'
  import { fly, fade } from 'svelte/transition'

  const dispatch = createEventDispatcher()

  export let visible = false
  export let viewId = crypto.randomUUID()
  export let cssClass

  let level = 0
  let isInForeground = false

  $: toggleViewVisibility(visible)

  const unsubscribeViewStack = viewStack.subscribe((stack) => {
    const index = stack.findIndex((item) => item.id === viewId)

    level = 100 + ((stack.length > 0 && index) * 2)
    isInForeground = stack.length > 0 && index === stack.length - 1
  })

  function close () {
    dispatch('close')
  }

  function toggleViewVisibility (visible) {
    if (visible) {
      viewStack.show(viewId)
    } else {
      viewStack.close(viewId)
    }
  }

  onDestroy(() => {
    unsubscribeViewStack()
    if (viewId) {
      viewStack.close(viewId)
    }
  })
</script>

<!-- TODO: @keyup.esc="close()" -->
<div
  class="view {cssClass}"
  class:view--in-background={!isInForeground}
>
  {#if visible}
    <div class="view__content" style:z-index={level} transition:fly="{{ y: 200, duration: 300, opactiy: 0.7 }}">
      <slot></slot>
    </div>
  {/if}

  {#if visible}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="view__backdrop" style:z-index={level - 1} on:click={close} transition:fade={{ duration: 300 }}></div>
  {/if}
</div>

<style>
  .view__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: var(--viewport-height);
    background-color: #000;
    opacity: 0.6;
  }

  .view__content {
    background-color: var(--white);
    position: fixed;
    width: 100%;
    left: 50%;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    bottom: 0;
    transform: translateX(-50%);
    max-width: 500px;
    min-height: 200px;
    max-height: calc(var(--viewport-height) - max(env(safe-area-inset-top), 4rem));
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    background: var(--white);
    box-shadow: 0 0 30px 0 rgba(0,0,0,.6);
    padding-bottom: max(1rem, env(safe-area-inset-bottom));

    transition: transform .1s cubic-bezier(.3,.6,.4,1);
  }

  .view--in-background .view__backdrop {
    display: none;
  }

  .view--in-background .view__content {
    transform: translateX(-50%) scale(0.9);
    box-shadow: 0 0 30px 0 rgba(0,0,0,.3);
  }
</style>
