<script>
  import { createEventDispatcher } from 'svelte'
  import { MEDIA_DURATION_OPTIONS_LABELS } from '../constants.js'
  import ActionBar from './ActionBar.svelte'
  import EditorDurationOptions from './EditorDurationOptions.svelte'

  const dispatch = createEventDispatcher()

  export let isDurationSelectVisible = false

  let selectedDuration = 9
  let showDurationOptions = false
  let isExporting = false

  function submit () {
    isExporting = true

    dispatch('submit', { selectedDuration })

    setTimeout(() => {
      // If the switch to false would be immediately, the button would be enabled for the short
      // amount of time where the exporting is finished and the view animation of the
      // contact selection starts. To prevent this, this timeout exists.
      isExporting = false
    }, 100)
  }
</script>

<ActionBar>
  <div slot="left">
    <button class="-button-reset" on:click={() => dispatch('cancel')}>
      <svg class="feather-icon -with-glow">
        <use href="feather-sprite.svg#x"/>
      </svg>
    </button>
  </div>

  <div slot="right" class="editor-action-bar">
    {#if isDurationSelectVisible}
      <button class="-button-reset select-duration" on:click={() => { showDurationOptions = !showDurationOptions }}>
        <svg class="feather-icon -with-glow"><use href="feather-sprite.svg#clock"/></svg>

        <span class="-with-glow">
          {#if selectedDuration === 1}
            {MEDIA_DURATION_OPTIONS_LABELS[0]}
          {:else if selectedDuration === 4}
            {MEDIA_DURATION_OPTIONS_LABELS[1]}
          {:else if selectedDuration === 9}
            {MEDIA_DURATION_OPTIONS_LABELS[2]}
          {:else if selectedDuration === null}
            {MEDIA_DURATION_OPTIONS_LABELS[3]}
          {/if}
        </span>
      </button>
    {/if}

    <button class="-button-reset send" on:click={submit} disabled={isExporting}>
      <svg class="feather-icon"><use href="feather-sprite.svg#send"/></svg>
    </button>
  </div>
</ActionBar>

<EditorDurationOptions bind:selectedDuration bind:isVisible={showDurationOptions} />

<style>
  .editor-action-bar {
    display: flex;
    column-gap: 1rem;
    align-items: center;
  }

  button.send {
    width: 3.5em;
    background-color: var(--brand-color);
  }

  button.send > .feather-icon {
    margin-left: -0.3em;
    transform: rotate(45deg);
  }

  .select-duration {
    border-radius: 1rem;
    aspect-ratio: auto;
    width: 7rem;
    height: 3rem;
    padding: 0;
    padding-left: 0.8rem;
    text-align: left;
  }

  .select-duration > span {
    position: relative;
    top: -3px;
    font-weight: bold;
  }
</style>
