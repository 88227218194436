import { decodeBase64 } from 'tweetnacl-util'
import { createInboxApiUrl } from './create-inbox-api-url.js'
import { normalizeErrorHandling } from './normalize-error-handling.js'
import { trackInboxServerReachability, trackInboxServerUnreachability } from './track-inbox-server-reachability.js'
import { settings } from '../stores'

export function metaRequests (getApiStateFn) {
  return {
    async getServerInfo (inboxServerHost, persist = true) {
      const apiState = getApiStateFn()
      apiState.inboxServerHost = inboxServerHost

      const response = await fetch(createInboxApiUrl(apiState, '/info'))
        .then(trackInboxServerReachability(apiState))
        .catch(trackInboxServerUnreachability(apiState))
        .then(normalizeErrorHandling)

      const info = await response.json()

      info.lastConnectedAt = (new Date()).toISOString()
      info.isDown = false

      if (persist) {
        const allInboxServerMap = apiState.allInboxServerMap ?? {}

        allInboxServerMap[inboxServerHost] = {
          publicKey: decodeBase64(info.publicKey),
          adminDisplayName: info.adminDisplayName,
          adminMessage: info.adminMessage,
          privacyPolicyUrl: info.privacyPolicyUrl,
          lastConnectedAt: info.lastConnectedAt,
          isDown: info.isDown
        }

        await settings.set('allInboxServerMap', allInboxServerMap)
      }

      return info
    }
  }
}
