import { parcelRequests } from './parcel-requests.js'
import { accountRequests } from './account-requests.js'
import { contactExchangeRequests } from './contact-exchange-requests.js'
import { metaRequests } from './meta-requests.js'

export function createInboxApi () {
  const apiState = {
    publicKey: null,
    privateKey: null,
    allInboxServerMap: null,
    inboxServerHost: null,
    inboxServerPublicKey: null
  }

  const getApiStateFn = () => {
    return {
      publicKey: apiState.publicKey,
      privateKey: apiState.privateKey,
      allInboxServerMap: apiState.allInboxServerMap,
      inboxServerHost: apiState.inboxServerHost,
      inboxServerPublicKey: apiState.inboxServerPublicKey
    }
  }

  return {
    updateApiState (settings) {
      apiState.publicKey = settings.publicKey
      apiState.privateKey = settings.privateKey
      apiState.allInboxServerMap = settings.allInboxServerMap
      apiState.inboxServerHost = settings.inboxServerHost

      if (settings.inboxServerHost && settings.allInboxServerMap && settings.allInboxServerMap[settings.inboxServerHost]) {
        apiState.inboxServerPublicKey = settings.allInboxServerMap[settings.inboxServerHost].publicKey
      }
    },

    parcelRequests: parcelRequests(getApiStateFn),
    accountRequests: accountRequests(getApiStateFn),
    contactExchangeRequests: contactExchangeRequests(getApiStateFn),
    metaRequests: metaRequests(getApiStateFn)
  }
}
