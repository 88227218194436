<script>
  import { onDestroy } from 'svelte'
  import { viewStack, settings, contactExchangeActive } from '../../stores'
  import { inboxApi } from '../../api'
  import { createEncryptedContact, createExchangeQrCode, createExchangeUrl } from '../../functions/contact-exchange-coder.js'
  import { checkActiveContactExchange } from '../../functions/check-contact-exchange.js'
  import { handleError } from '../../functions/handle-error.js'
  import ViewHeader from '../ViewHeader'
  import { dumeToNow } from '../../functions/dume'
  import CopyButton from '../CopyButton.svelte'

  let isUpdating = false
  let isLoading = false
  let interval
  let activeExchangeDataUrl
  let activeExchangeUrl
  let errorMessage
  let relativeTimeToLive

  async function createOffer () {
    isLoading = true

    try {
      const { encryptedContact, secretKey } = createEncryptedContact(
        $settings.publicKey,
        $settings.displayName,
        $settings.inboxServerHost
      )

      const response = await inboxApi.contactExchangeRequests.create(encryptedContact)
      const data = await response.json()

      await contactExchangeActive.set(data.oneTimeToken, data.acceptUrl, secretKey, data.timeToLive)
      await loadActiveExchange()
    } catch (error) {
      errorMessage = handleError(error)
    }

    isLoading = false
  }

  async function revoke () {
    if (!window.confirm('Delete friend invite? The link and QR code will be invalid.')) {
      return
    }

    try {
      await inboxApi.contactExchangeRequests.revoke($contactExchangeActive.oneTimeToken)
    } catch (error) {
      errorMessage = handleError(error)

      if (error instanceof Response && error.status !== 404) {
        return
      }
    }

    await contactExchangeActive.clear()
    clearInterval(interval)

    viewStack.pop()
  }

  async function openShareMenuForExchangeUrl () {
    if (navigator.share) {
      navigator.share({
        title: 'piqchat invite',
        url: activeExchangeUrl
      })
    }
  }

  async function loadActiveExchange () {
    isLoading = true

    if (!$contactExchangeActive) {
      isLoading = false

      return createOffer()
    }

    if ($contactExchangeActive) {
      try {
        if (interval == null) {
          // This if condition maybe fixes a bug where the ContactSave view kept reopening every 5 seconds
          interval = setInterval(checkIfAccepted, 5000)
        }

        activeExchangeUrl = createExchangeUrl(
          $contactExchangeActive.acceptUrl,
          $contactExchangeActive.secretKey
        )

        await checkIfAccepted()

        activeExchangeDataUrl = createExchangeQrCode(activeExchangeUrl)
      } catch (error) {
        errorMessage = handleError(error)
      }
    }

    isLoading = false
  }

  async function checkIfAccepted () {
    if (!$contactExchangeActive || isUpdating) {
      return
    }

    relativeTimeToLive = dumeToNow(new Date($contactExchangeActive.timeToLive))

    isUpdating = true
    const hasAccepted = await checkActiveContactExchange()
    isUpdating = false

    if (hasAccepted || !$contactExchangeActive) {
      viewStack.pop()
    }
  }

  onDestroy(() => {
    // By registering the onDestroy function BEFORE any code (and therefore initialization
    // of the interval) it may fix a bug where the ContactSave view kept reopening every 5 seconds
    clearInterval(interval)
  })

  loadActiveExchange()
</script>

<ViewHeader>
  Friend invite

  <span slot="secondary">
    <button class="-button-reset" on:click={() => viewStack.push('ContactExchangeExplanation')}>
      <svg class="feather-icon"><use href="feather-sprite.svg#help-circle"/></svg>
    </button>
  </span>
</ViewHeader>

{#if errorMessage}
  <p class="text-color-error">An error occurred. {errorMessage}</p>
{/if}

{#if isLoading}
  <p>
    Loading...
    <svg class="feather-icon -normalize -rotate-animation">
      <use href="feather-sprite.svg#loader"/>
    </svg>
    <br /><br />
  </p>
{:else if $contactExchangeActive}
  <p>To add a friend, have the QR code scanned or share the friend invitation as a link!</p>

  <p>
    You can only create one invite at a time.
    It expires in {relativeTimeToLive} and can only be used once.
  </p>
{/if}

<div class="invite-share-container">
  <img src={activeExchangeDataUrl} alt="Friend invite as QR code" />

  <p class="scrollable-code-line">
    <code>{activeExchangeUrl ?? 'http://...'}</code>
  </p>

  <div class="action-bar">
    <CopyButton class="-button-secondary -button-small" content={activeExchangeUrl} disabled={!activeExchangeUrl}>
      Copy link
    </CopyButton>

    <button class="-button-secondary -button-small" on:click={openShareMenuForExchangeUrl} disabled={!navigator.share || !activeExchangeUrl}>
      Share link
      <svg class="feather-icon"><use href="feather-sprite.svg#share"/></svg>
    </button>
  </div>
</div>

{#if activeExchangeUrl}
  <p>
    <button class="-button-link" on:click={revoke}>Delete invite...</button>
  </p>
{/if}

<style>
  .invite-share-container {
    background-color: #fff;
    border-radius: 1rem;
    overflow: hidden;
    margin: 1rem;
  }

  .invite-share-container > .action-bar {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    padding-top: 0;
  }

  .invite-share-container > .action-bar > :global(button) {
    background-color: inherit;
  }

  .invite-share-container > img {
    display: block;
    aspect-ratio: 1/1;
    width: 100%;
    color: #fff;
  }

  .scrollable-code-line {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .scrollable-code-line > code {
    color: var(--dark-grey);
  }
</style>
