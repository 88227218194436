<script>
  import { createEventDispatcher, onMount, onDestroy } from 'svelte'
  import { settings, logger } from '../stores'
  import Editor from './Editor.svelte'
  import { MEDIA_WIDTH, MEDIA_HEIGHT } from '../constants.js'
  import { drawImageOnCanvas, calculateCoordinatesWithFit } from '../functions/draw-image-on-canvas.js'
  import { createVideoRecorder } from '../functions/create-video-recorder.js'

  export let originalSource
  export let isInBackground = false

  const dispatch = createEventDispatcher()

  let videoRecorderAbortFn
  let videoElement
  let currentPosition
  let canvasElement
  let videoFrameCallbackId

  if (!('requestVideoFrameCallback' in HTMLVideoElement.prototype)) {
    logger.warn('EditorVideo/onPlay requestVideoFrameCallback is not supported, instead the less perfoment requestAnimationFrame is used.')
  }

  onMount(() => {
    videoElement.src = URL.createObjectURL(originalSource)
    logger.log(`EditorVideo/onMount originalSource name '${originalSource.name}', type '${originalSource.type}' and size '${originalSource.size}'`)

    // NOTE: prevent `context-filter-polyfill` from monkey patching as this is causing bad perfomance and breaks some canvas behavior
    Object.defineProperty(canvasElement, '__skipFilterPatch', { value: true })
  })

  onDestroy(() => {
    URL.revokeObjectURL(videoElement.src)
  })

  function onPlay () {
    logger.log('EditorVideo/onPlay')

    cancelVideoFrameCallback()

    if (!isInBackground) {
      currentPosition = calculateCoordinatesWithFit(videoElement.videoWidth, videoElement.videoHeight, 'cover')

      drawVideoFrameForEditorPreview()
    }
  }

  function drawVideoFrameForEditorPreview () {
    drawImageOnCanvas(videoElement, canvasElement, currentPosition)

    if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
      videoFrameCallbackId = videoElement.requestVideoFrameCallback(drawVideoFrameForEditorPreview)
    } else {
      videoFrameCallbackId = requestAnimationFrame(drawVideoFrameForEditorPreview)
    }
  }

  function cancel () {
    videoElement.pause()

    cancelVideoFrameCallback()

    dispatch('retake')
  }

  function cancelVideoFrameCallback () {
    if ('cancelVideoFrameCallback' in HTMLVideoElement.prototype) {
      videoElement.cancelVideoFrameCallback(videoFrameCallbackId)
    } else {
      cancelAnimationFrame(videoFrameCallbackId)
    }
  }

  async function exportVideo (event) {
    try {
      const canvasWithText = event.detail.canvasWithText
      const canvasWithDrawing = event.detail.canvasWithDrawing

      const videoRecorder = createVideoRecorder(videoElement, (context) => {
        context.drawImage(canvasWithDrawing, 0, 0, MEDIA_WIDTH, MEDIA_HEIGHT)
        context.drawImage(canvasWithText, 0, 0, MEDIA_WIDTH, MEDIA_HEIGHT)
      })

      dispatch('submit', { waitForExport: true, duration: videoElement.duration })

      videoRecorderAbortFn = videoRecorder.abort
      const blob = await videoRecorder.record()

      dispatch('export', { blob })
    } catch (error) {
      logger.error(error, 'EditorVideo/exportVideo export failed')
    }

    videoRecorderAbortFn = null
  }

  function reactToIsInBackground () {
    logger.log(`EditorVideo/reactToIsInBackground ${isInBackground}`)

    if (!isInBackground && videoElement) {
      if (videoRecorderAbortFn) {
        videoRecorderAbortFn()
        videoElement.pause()
      }

      videoElement.play()
    }
  }

  $: reactToIsInBackground(isInBackground)
</script>

<video
  bind:this={videoElement}
  class:-is-visible={$settings.isDebugMode}
  controls
  playsinline
  muted
  loop
  autoplay
  on:play={onPlay}
/>

<Editor on:submit={exportVideo} on:cancel={cancel}>
  <canvas slot="base" bind:this={canvasElement} class="full-width" />
</Editor>

<style>
  video {
    width: 10%;
    position: absolute;
    top: 0;
  }

  video.-is-visible {
    width: 50%;
    border: 1px solid green;
    z-index: 1;
  }
</style>
