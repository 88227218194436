<script>
  import { createEventDispatcher } from 'svelte'
  import { MEDIA_TEXT_TYPES } from '../constants.js'
  import EditorTextLayerInput from './EditorTextLayerInput.svelte'

  export let containerElement
  export let hide = false
  export let active
  export const createTextFn = createText

  const dispatch = createEventDispatcher()
  let trashElement
  let sentenceOverTrashIndex
  let focusedSentenceIndex
  let isDragging = false
  let sentences = []

  function createText () {
    sentences.push({
      id: crypto.randomUUID(),
      type: MEDIA_TEXT_TYPES.OVERLAY,
      value: '',
      rotation: 0,
      scale: 1,
      position: { y: 100, x: null },
      alignment: 'center',
      color: 'rgb(255, 255, 255)',
      font: null
    })

    updateSentences()

    editSentence(sentences.length - 1)
  }

  function updateSentences () {
    sentences = sentences
  }

  function dragStart (event, sentence) {
    if (isDragging) {
      return
    }

    isDragging = true
    dispatch('dragChange', { isDragging })

    const element = event.target
    const initialPosition = {
      x: sentence.position.x ?? 0,
      y: sentence.position.y
    }
    const initialTouchPoint = {
      y: event.touches[0].clientY,
      x: event.touches[0].clientX
    }
    let lastAbsoluteDistance = null
    let lastAbsoluteRotation = null

    document.addEventListener('touchmove', dragMove)
    document.addEventListener('touchend', dragEnd)

    function dragMove (moveEvent) {
      const touchPoint1 = {
        y: moveEvent.touches[0].clientY,
        x: moveEvent.touches[0].clientX
      }

      const newTop = (touchPoint1.y - initialTouchPoint.y) + initialPosition.y
      const newLeft = (touchPoint1.x - initialTouchPoint.x) + initialPosition.x

      updateTrashProximity(moveEvent.touches, sentence)

      if (sentence.type === MEDIA_TEXT_TYPES.FREE) {
        const hasTwoTouchPoints = moveEvent.touches.length >= 2

        if (hasTwoTouchPoints) {
          const touchPoint2 = {
            y: moveEvent.touches[1].clientY,
            x: moveEvent.touches[1].clientX
          }

          const touchPointsRotation = Math.atan2(touchPoint1.y - touchPoint2.y, touchPoint1.x - touchPoint2.x) * 180 / Math.PI
          const touchPointsDistance = Math.sqrt((touchPoint1.x - touchPoint2.x) ** 2 + (touchPoint1.y - touchPoint2.y) ** 2)

          if (lastAbsoluteDistance == null) {
            lastAbsoluteDistance = touchPointsDistance
          } else if (lastAbsoluteDistance !== touchPointsDistance) {
            const scaleRate = 100
            const change = (lastAbsoluteDistance - touchPointsDistance) / scaleRate
            const newScale = sentence.scale - change

            if (newScale > 0.5 && newScale < 10) {
              sentence.scale = sentence.scale - change
            }

            lastAbsoluteDistance = touchPointsDistance
          }

          if (lastAbsoluteRotation == null) {
            lastAbsoluteRotation = touchPointsRotation
          } else if (lastAbsoluteRotation !== touchPointsRotation) {
            const degreeChange = lastAbsoluteRotation - touchPointsRotation
            sentence.rotation = sentence.rotation - degreeChange
            lastAbsoluteRotation = touchPointsRotation
          }
        }

        sentence.position.y = newTop
        sentence.position.x = newLeft

        moveSentenceToForeground(sentence)
        updateSentences()

        return
      }

      // The overlay text type should stay inside the container
      if (newTop < 0) {
        sentence.position.y = 0
      } else if (newTop + element.clientHeight > element.parentElement.clientHeight) {
        // The parentElement is the container which has a 9:16 ratio and is centered in the window
        sentence.position.y = element.parentElement.clientHeight - element.clientHeight
      } else {
        sentence.position.y = newTop
      }

      moveSentenceToForeground(sentence)
      updateSentences()
    }

    function dragEnd (endEvent) {
      if (endEvent.touches.length === 1) {
        lastAbsoluteRotation = null
        lastAbsoluteDistance = null
        initialTouchPoint.x = endEvent.touches[0].clientX
        initialTouchPoint.y = endEvent.touches[0].clientY
        initialPosition.x = sentence.position.x
        initialPosition.y = sentence.position.y

        updateSentences()
      }

      if (endEvent.touches.length === 0) {
        isDragging = false
        dispatch('dragChange', { isDragging })
        document.removeEventListener('touchmove', dragMove)
        document.removeEventListener('touchend', dragEnd)

        if (sentenceOverTrashIndex != null) {
          deleteSentence(sentenceOverTrashIndex)
          sentenceOverTrashIndex = null
        }
      }
    }
  }

  function updateTrashProximity (touches, sentence) {
    if (touches.length !== 1) {
      return
    }

    const touchY = touches[0].clientY
    const touchX = touches[0].clientX
    const trashElementRect = trashElement.getBoundingClientRect()

    const distance = Math.sqrt((touchX - trashElementRect.x) ** 2 + (touchY - trashElementRect.y) ** 2)

    if (distance < 100) {
      sentenceOverTrashIndex = sentences.indexOf(sentence)
    } else {
      sentenceOverTrashIndex = null
    }
  }

  function moveSentenceToForeground (sentence) {
    sentences.push(sentences.splice(sentences.indexOf(sentence), 1)[0])
  }

  function finishEditSentence () {
    if (focusedSentenceIndex == null) {
      return
    }

    // This is the sentence that was just edited (before the input is going to be closed)
    const sentence = sentences[focusedSentenceIndex]

    if (sentence.value.trim() === '') {
      deleteSentence(focusedSentenceIndex)
      updateSentences()
    } else if (sentence.position.x == null) {
      sentences[focusedSentenceIndex].position.x = calculateVerticalCenterSentencePosition(sentence)
      moveSentenceToForeground(sentence)
    }

    focusedSentenceIndex = null

    dispatch('dragChange', { isDragging: false })
  }

  function calculateVerticalCenterSentencePosition (sentence) {
    if (sentence.type === MEDIA_TEXT_TYPES.FREE) {
      const element = containerElement.querySelector(`[data-sentence-id="${sentence.id}"`)

      return (containerElement.getBoundingClientRect().width - element.getBoundingClientRect().width) / 2
    }

    return 0
  }

  function deleteSentence (index) {
    sentences.splice(index, 1)
  }

  function editSentence (index) {
    focusedSentenceIndex = index

    dispatch('dragChange', { isDragging: true })
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="text-container"
  class:-active={active}
  class:-is-dragging-sentence={isDragging}
  style:opacity={focusedSentenceIndex != null ? 0 : (hide ? 0.2 : 1)}
  bind:this={containerElement}
>
  {#each sentences as sentence, index}
    <div
      class="sentence"
      data-sentence-id="{sentence.id}"
      class:-type-overlay={sentence.type === MEDIA_TEXT_TYPES.OVERLAY}
      class:-type-free={sentence.type === MEDIA_TEXT_TYPES.FREE}
      class:-over-trash={sentenceOverTrashIndex === index}
      style:color={sentence.color}
      style:transform={`translate(${sentence.position.x ?? 0}px, ${sentence.position.y}px) rotate(${sentence.rotation}deg) scale(${sentence.scale})`}
      style:text-align={sentence.alignment}
      style:font-family={sentence.font}
      on:touchstart={(event) => dragStart(event, sentence)}
      on:click={() => editSentence(index)}
    >{sentence.value}</div>
  {/each}
</div>

<div
  class="trash"
  class:display-none={!isDragging}
  class:-active={sentenceOverTrashIndex != null}
  bind:this={trashElement}
>
  <div>
    <svg class="feather-icon -with-glow">
      <use href="feather-sprite.svg#trash-2"/>
    </svg>
  </div>
</div>

{#if active && focusedSentenceIndex != null}
  <EditorTextLayerInput
    bind:sentence={sentences[focusedSentenceIndex]}
    {containerElement}
    on:close={finishEditSentence}
  />
{/if}

<style>
  .text-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: none;
    pointer-events: none;

    /* NOTE: When using toCanvas from html-to-image, "white-space" can't be cloned on iOS,
       so it is set additionally on the toCanvas options.
       It is set here, so in both cases the .sentnence element inherits this property. */
    white-space: pre-wrap;
  }

  .-is-dragging-sentence {
    touch-action: unset;
    pointer-events: unset;
  }

  :global(.create-media.blur .media-view-container) .text-container {
    display: none; /* This is the fix to enable iOS long press of image preview in ContactSelection, if the text is behind the image preview */
  }

  .trash {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 2;
    color: var(--white);
  }

  .trash > div {
    padding: 1rem 1.1rem;
    transition: transform 300ms ease-out;
  }

  .trash.-active > div {
    background-color: var(--white);
    color: var(--brand-color);
    transform: scale(2);
    border-radius: 100%;
    box-shadow: 0 0 0.5rem 0 var(--brand-color);
    animation: brand-shadow-pulse 1s infinite;
  }

  .sentence {
    position: absolute;
    left: 0;
    top: 0;
    transition: opacity 300ms ease-out;
    touch-action: none;
    pointer-events: none;
  }

  .text-container.-active > .sentence {
    touch-action: auto;
    pointer-events: auto;
  }

  .sentence.-over-trash {
    opacity: 0.2;
  }

  .sentence,
  :global(.text-editor textarea.-type-overlay) {
    overflow-wrap: anywhere;
    padding: 0.5rem;
    font-size-adjust: 0.5;
  }

  :global(.text-editor textarea.-type-overlay),
  .sentence.-type-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    padding: 0.5rem;
    word-break: break-word;
    font-size: 1.1rem;
  }

  :global(.text-editor textarea.-type-free),
  .sentence.-type-free {
    font-size: 2rem;
    font-weight: bold;
    background-color: transparent;
  }
</style>
