/* Add a "longpress" event listener to an element in a svelte component.
 *
 *    <div use:longpress on:longpress="() => doThing()">
 *
 * Set the minimal press time in milliseconds (default: 600)
 *    use:longpress="1044"
 *
 * One css class is put on the element while pressing, for custom animation.
 *    longpress-active: as soon as the button is pressed
 *                  and until the time limit is reached
 */

export function longpress (element, durationMs) {
  let pressTimer = null

  const initialPosition = { y: 0, x: 0 }
  let distanceToInitialPosition = 0

  const timeLimit = durationMs ?? 600

  const onCancel = () => {
    clearTimeout(pressTimer)
    // toggleUserSelect(false)
    element.classList.remove('longpress-active')
    initialPosition.y = 0
    initialPosition.x = 0
    distanceToInitialPosition = 0
  }

  function onMove (event) {
    distanceToInitialPosition = Math.sqrt(
      Math.pow(initialPosition.x - event.touches[0].clientX, 2) +
      Math.pow(initialPosition.y - event.touches[0].clientY, 2)
    )

    if (hasMovedTooFar()) {
      onCancel()
    }
  }

  function hasMovedTooFar () {
    // In pixel
    return distanceToInitialPosition > 10
  }

  const onStart = (event) => {
    // toggleUserSelect(true)
    element.classList.add('longpress-active')

    initialPosition.y = event.touches[0].clientY
    initialPosition.x = event.touches[0].clientX

    clearTimeout(pressTimer)

    pressTimer = setTimeout(() => {
      element.classList.remove('longpress-active')

      if (hasMovedTooFar()) {
        return
      }

      event.preventDefault()

      element.dispatchEvent(new CustomEvent('longpress'))
    }, timeLimit)
  }

  element.addEventListener('touchstart', onStart)
  element.addEventListener('touchmove', onMove)
  element.addEventListener('touchend', onCancel)
  element.addEventListener('touchcancel', onCancel)

  return {
    destroy () {
      element.removeEventListener('touchstart', onStart)
      element.removeEventListener('touchmove', onMove)
      element.removeEventListener('touchend', onCancel)
      element.removeEventListener('touchcancel', onCancel)
    }
  }
}
