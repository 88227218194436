<script>
  export let options = []

  let selectedIndex = null

  function onChange () {
    options[selectedIndex].handler()

    selectedIndex = null
  }
</script>

<span class="popover-list">
  <select bind:value={selectedIndex} on:change={onChange}>
    <!-- &vellip; or &ctdot; -->
    <option disabled selected value={null} class="display-none">&vellip;</option>

    {#each options as option, index}
      <option value={index}>{option.label}</option>
    {/each}
  </select>
</span>

<style>
  .popover-list {
    position: relative;
    width: 2em;
    display: inline-block;
  }

  select {
    border: none;
    background: none;
    appearance: none;
    text-align: center;
    font-size: 1.2em;
    color: inherit;
    width: 2em;
    line-height: 1.4;
    font-weight: bold;

    position: absolute;
    top: -0.4em;
    left: 0;
  }
</style>
