import { writable } from 'svelte/store'

export function createLoggerStore () {
  const { subscribe, update } = writable([])

  return {
    subscribe,

    error (error, label) {
      console.error(`"${label ?? 'Logger'}"`, error)
      update((logs) => {
        if (label) {
          logs.push({ type: 'E', message: `Label "${label}" regarding following error:` })
        }

        if (error instanceof Response) {
          const response = error
          error = {
            message: `Request failed with ${response.status} and body "${response.parsedText}"`,
            url: response.url
          }
        }

        logs.push(error)
        return logs
      })
    },

    warn (entry) {
      console.warn(entry)
      update((logs) => {
        logs.push({ type: 'W', message: 'Warn: ' + entry })
        return logs
      })
    },

    log (entry) {
      console.log(entry)
      update((logs) => {
        logs.push({ type: 'L', message: entry })
        return logs
      })
    },

    debug (isDebugMode, entry) {
      if (!isDebugMode) {
        return
      }

      console.log(entry)
      update((logs) => {
        logs.push({ type: 'D', message: entry })
        return logs
      })
    },

    clear () {
      update((logs) => {
        logs = []
        return logs
      })
    }
  }
}
