import { get } from 'svelte/store'
import { contacts, settings } from '../stores'
import { parseEncryptedContact } from './contact-exchange-coder.js'
import { inboxApi } from '../api'

export async function saveEncryptedContact (encryptedContact, secretKey) {
  const newContact = await decryptAndMergeContact(encryptedContact, secretKey)

  await contacts.set(newContact)
  await settings.set('contactsChanged', true)

  return newContact
}

export async function decryptAndMergeContact (encryptedContact, secretKey) {
  if (!isContactDecryptable(encryptedContact, secretKey)) {
    throw new Error('The friend invite does not have any contact information!')
  }

  const parsedContact = parseEncryptedContact(encryptedContact, secretKey)

  const existingContact = await contacts.get(parsedContact.id)

  // Let's map this explicitly. We don't want random properties from external sources in the local storage.
  const newContact = {
    displayName: parsedContact.displayName, // the displayName should never be overwritten because this could be missused
    ...(existingContact ?? {}),
    id: parsedContact.id,
    publicKey: parsedContact.publicKey,
    inboxServerHost: parsedContact.inboxServerHost
  }

  if (newContact.inboxServerHost == null) {
    // When the other client created a new profile, assume for them the host from this client
    newContact.inboxServerHost = get(settings).inboxServerHost
  }

  try {
    await inboxApi.metaRequests.getServerInfo(newContact.inboxServerHost)
  } catch {}

  return newContact
}

export function isContactDecryptable (encryptedContact, secretKey) {
  return encryptedContact != null && encryptedContact !== '' && secretKey?.length > 0
}
