<script>
  import { onDestroy, onMount, createEventDispatcher } from 'svelte'
  import { viewStack, unopenedParcels, settings, logger, isCameraInScanMode, sendToContact } from '../stores'
  import { fileToImage } from '../functions/convert-to-imagedata'
  import { processQrCodeImage } from '../functions/contact-exchange-coder.js'
  import { MEDIA_HEIGHT, RELOAD_HANDLING_TYPES, IS_IOS, SCAN_MODE_TYPE } from '../constants.js'
  import ActionBar from './ActionBar'
  import CameraShootButton from './CameraShootButton.svelte'
  import { checkForAppUpdate, updateApp } from '../functions/update-app.js'
  import { createVideoRecorder } from '../functions/create-video-recorder.js'
  import { isInAppWrapper } from '../functions/app-wrapper-communication.js'

  const dispatch = createEventDispatcher()

  let flipScreen = false
  let videoElement
  let isSwitching = true
  let needsManualRestart = false
  const zoomLevel = 1
  let isAppUpdateAvailable = false
  let preferredFacingMode
  let videoTrackUnableToProvideOutput = false
  let trackMuteTimer
  let automaticStartInProgress = false
  let stopVideoRecorderFn

  function shoot () {
    if ($isCameraInScanMode || !videoElement || isSwitching || needsManualRestart) {
      return
    }

    const context = document.createElement('canvas').getContext('2d')

    context.canvas.width = videoElement.videoWidth
    context.canvas.height = videoElement.videoHeight

    if (flipScreen) {
      context.scale(-1, 1)
    }

    const dx = flipScreen ? context.canvas.width * -1 : 0

    context.drawImage(videoElement, dx, 0)

    dispatch('image', { canvas: context.canvas, zoomLevel })
  }

  function endRecording () {
    logger.warn('Camera/stopRecording')

    if (stopVideoRecorderFn) {
      stopVideoRecorderFn()
    }
  }

  async function startRecording () {
    logger.warn('Camera/startRecording')

    const videoRecorder = createVideoRecorder(videoElement)
    stopVideoRecorderFn = videoRecorder.stop

    const blob = await videoRecorder.record()

    dispatch('video', { file: blob })
  }

  async function selectFile (event) {
    const files = event.target.files || event.dataTransfer.files
    const file = files[0]

    if (file.type.includes('video/')) {
      dispatch('video', { file: files[0] })

      return
    }

    const image = await fileToImage(files[0])

    if ($isCameraInScanMode) {
      const code = processQrCodeImage(image, image.width, image.height)

      if (code != null) {
        finishScan(code)
      }

      return
    }

    dispatch('image', { canvas: image })
  }

  async function toggleCamera () {
    const newFacingMode = $settings.facingMode === 'user' ? 'environment' : 'user'
    await settings.set('facingMode', newFacingMode)
    await loadCamera()
  }

  function stopCurrentTrack () {
    if (videoElement?.srcObject) {
      videoElement.srcObject.getVideoTracks()
        .forEach(track => track.stop())
    }
  }

  async function loadCamera () {
    isSwitching = true
    needsManualRestart = false
    videoTrackUnableToProvideOutput = false

    videoElement?.pause()
    stopCurrentTrack()

    const constraints = { audio: false, video: { facingMode: $settings.facingMode, height: MEDIA_HEIGHT } }

    if (preferredFacingMode) {
      constraints.video.facingMode = preferredFacingMode
      preferredFacingMode = null
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints)

      window.clearTimeout(trackMuteTimer)
      videoTrackUnableToProvideOutput = false

      const track = stream.getVideoTracks()
        .filter((track) => track.enabled)
        .pop()

      track.addEventListener('ended', () => { needsManualRestart = true })

      track.addEventListener('mute', () => {
        window.clearTimeout(trackMuteTimer)

        trackMuteTimer = setTimeout(() => {
          window.clearTimeout(trackMuteTimer)
          videoTrackUnableToProvideOutput = track?.muted
          if ($settings.isDebugMode) logger.log('track mute event after timeout, videoTrackUnableToProvideOutput ' + videoTrackUnableToProvideOutput)
        }, 2000)
      })

      track.addEventListener('unmute', () => {
        window.clearTimeout(trackMuteTimer)
        videoTrackUnableToProvideOutput = track?.muted
      })

      flipScreen = /front|user/i.test(track?.label)

      videoElement.srcObject = stream
      if ($settings.isDebugMode) logger.log(`Set video stream to media stream object id ${stream.id} with video track label "${track?.label}"\n\t(${JSON.stringify(track.getSettings())})`)

      videoElement?.play()
    } catch (error) {
      needsManualRestart = true
      logger.error(error, 'Loading camera failed')
    }

    isSwitching = false
  }

  function finishScan (exchangeUrl) {
    logger.log('Finish scan' + (exchangeUrl ? ' exchangeUrl is not null' : ''))

    if ($isCameraInScanMode === SCAN_MODE_TYPE.SIGNUP_INBOX) {
      viewStack.push('ContactExchangeAccept', { changeInboxServerHost: true, exchangeUrl })
    } else if ($isCameraInScanMode === SCAN_MODE_TYPE.SAVE_CONTACT) {
      viewStack.push('ContactExchangeAccept', { exchangeUrl })
    }

    $isCameraInScanMode = null
  }

  function scanning () {
    if (!$isCameraInScanMode) {
      return
    }

    if (videoElement && videoElement.readyState === videoElement.HAVE_ENOUGH_DATA) {
      const code = processQrCodeImage(videoElement, videoElement.videoWidth, videoElement.videoHeight)

      if (code) {
        finishScan(code)
        return
      }
    }

    setTimeout(() => {
      requestAnimationFrame(scanning)
    }, 100)
  }

  function resetSendToContact () {
    $sendToContact = null
  }

  function openInbox () {
    resetSendToContact()
    viewStack.push('Inbox')
  }

  function openProfile () {
    resetSendToContact()
    viewStack.push('Profile')
  }

  async function reactToViewStackChange () {
    if (!videoElement) {
      return
    }

    if ($viewStack.length > 0) {
      videoElement.pause()

      return stopCurrentTrack()
    }

    if (videoElement.paused && !videoElement.srcObject?.active) {
      if ($settings.isDebugMode) logger.log('reactToViewStackChange/automaticCameraStart()')
      automaticCameraStart()
    } else {
      if ($settings.isDebugMode) logger.log(`reactToViewStackChange/ no automaticCameraStart, because paused is '${videoElement.paused}' and videoElement.srcObject.active is '${videoElement.srcObject?.active}'`)
    }
  }

  async function toggleCameraBasedOnVisibility () {
    const visibility = document.visibilityState

    if (visibility === 'hidden') {
      stopCurrentTrack()
    } else if (visibility === 'visible') {
      if (IS_IOS) {
        if ($settings.isDebugMode) logger.log('automaticCameraStart is being delayed for 400ms (only for iOS)')
        await new Promise((resolve) => setTimeout(resolve, 400))
      }

      await automaticCameraStart()
    }
  }

  /**
   * This function first tries to determine if an automatic start is appropriate
   */
  async function automaticCameraStart () {
    if (automaticStartInProgress) {
      return
    }

    const abortStart = () => {
      needsManualRestart = true
      isSwitching = false
    }

    if ($viewStack.length > 0) {
      abortStart()
      return
    }

    automaticStartInProgress = true

    if (await shouldStartCameraAutomatically()) {
      await loadCamera()
    } else {
      abortStart()
    }

    automaticStartInProgress = false
  }

  async function shouldStartCameraAutomatically () {
    if (!$settings.disableAutomaticCameraStart) {
      return true
    }

    if (!('permissions' in navigator)) {
      return false
    }

    try {
      const cameraAllowed = await navigator.permissions.query({ name: 'camera' })

      if ($settings.isDebugMode) logger.log('shouldStartCameraAutomatically camera permission ' + cameraAllowed?.state)

      return cameraAllowed?.state === 'granted'
    } catch (error) {
      if ($settings.isDebugMode) logger.log('shouldStartCameraAutomatically asking permission failed :(')
      if ($settings.isDebugMode) logger.error(error, 'shouldStartCameraAutomatically')
      return false
    }
  }

  onDestroy(() => {
    stopCurrentTrack()
    removeEventListener('visibilitychange', toggleCameraBasedOnVisibility)
  })

  addEventListener('visibilitychange', toggleCameraBasedOnVisibility)

  $: reactToViewStackChange($viewStack.length > 0)
  $: isInboxFilled = $unopenedParcels.length > 0

  onMount(automaticCameraStart)

  $: if ($isCameraInScanMode) {
    resetSendToContact()
    preferredFacingMode = 'environment'
    logger.log('Start scanning')
    setTimeout(() => scanning(), 1000) // Ensure that a failed qr code is not detected again
  }

  checkForAppUpdate().then((isAvailable) => {
    isAppUpdateAvailable = isAvailable
  })
</script>

<div class="media-view-container">
  <!-- svelte-ignore a11y-media-has-caption -->
  <video
    playsinline
    muted
    bind:this={videoElement}
    class="{flipScreen ? '-mirror-horizontally' : ''}"
    style="--zoom-level: {zoomLevel}"
    on:click={() => $settings.tapAnywhereToShoot ? shoot() : null}
  />

  {#if $isCameraInScanMode}
    <div class="scanmode-overlay -at-top">
      <p>Scan friend invite QR code</p>
    </div>

    <div class="scanmode-overlay -at-bottom">
    </div>
  {/if}

  <ActionBar top thin>
    <div slot="right">
      {#if isAppUpdateAvailable}
        <button class="-button-reset small-text-button update-app" on:click={() => updateApp(RELOAD_HANDLING_TYPES.FORCE)}>Update App</button>
      {/if}

      {#if !$isCameraInScanMode}
        <button class="-button-reset" on:click={openProfile}>
          <svg class="feather-icon -with-glow">
            <use href="feather-sprite.svg#user"/>
          </svg>
        </button>
      {/if}
    </div>
  </ActionBar>

  {#if needsManualRestart}
    <div class="middle-content">
      <button class="-button-primary" on:click={loadCamera} disabled={isSwitching}>
        Start Camera
      </button>
    </div>
  {:else if videoTrackUnableToProvideOutput}
    <div class="middle-content">
      <p>
        It was not possible to access your camera.

        {#if IS_IOS && !isInAppWrapper()}
          <button class="-button-link" style="color:inherit" on:click={() => viewStack.push('IosQuirksExplanation')}>Why does this happen?</button>
        {:else}
          Try to restart the app. Make sure your camera is working.
        {/if}
      </p>

      {#if IS_IOS}
        <div class="vertical-empty-space" />

        <label class="-button-primary" for="alternativeCameraButton" disabled={isSwitching}>
          Open Backup Camera
        </label>

        <input
          type="file"
          class="display-none"
          id="alternativeCameraButton"
          accept="image/*{$settings.enableExperimentalVideoSupport ? ',video/*' : ''}"
          on:change={selectFile}
          capture
          disabled={isSwitching}
        />
      {/if}
    </div>
  {/if}

  <ActionBar left>
    <div slot="middle" class="action-bar-item">
      <label class="file-upload" for="fileUploadButton" disabled={isSwitching}>
        <svg class="feather-icon -with-glow">
          <use href="feather-sprite.svg#image"/>
        </svg>

        {#if needsManualRestart || $isCameraInScanMode || videoTrackUnableToProvideOutput}
          <div class="info" class:-make-space-below={$sendToContact}>
            <svg class="feather-icon -normalize"><use href="feather-sprite.svg#{$sendToContact ? 'arrow-down-left' : 'arrow-left'}"/></svg>
            {#if $isCameraInScanMode}
              or select image file with QR code
            {:else if needsManualRestart || videoTrackUnableToProvideOutput}
              or open your media library
            {/if}
          </div>
        {/if}
      </label>

      <input
        type="file"
        class="display-none"
        id="fileUploadButton"
        accept="image/*{$settings.enableExperimentalVideoSupport ? ',video/*' : ''}"
        on:change={selectFile}
        disabled={isSwitching}
      />
    </div>
  </ActionBar>

  <ActionBar>
    <div slot="left" class="action-bar-item">
      <button class="-button-reset" on:click={toggleCamera} disabled={isSwitching}>
        <svg class="feather-icon -with-glow {isSwitching ? '-rotate-animation' : ''}">
          <use href="feather-sprite.svg#{isSwitching ? 'loader' : 'repeat'}"/>
        </svg>
      </button>
    </div>

    <div slot="middle" class="text-align-center action-bar-item">
      {#if !$isCameraInScanMode}
        <CameraShootButton
          disabled={!videoElement || isSwitching || needsManualRestart}
          on:click={shoot}
          on:holdstart={startRecording}
          on:holdend={endRecording}
          disableHoldEvents={!$settings.enableExperimentalVideoSupport}
        />

        {#if $sendToContact != null}
          <button class="-button-reset small-text-button send-to-contact" on:click={resetSendToContact}>
            <span class="-with-glow"><strong>Send to</strong> {$sendToContact.displayName}</span>

            <svg class="feather-icon -with-glow -normalize"><use href="feather-sprite.svg#x"/></svg>
          </button>
        {/if}
      {/if}
    </div>

    <div slot="right" class="text-align-right action-bar-item">
      {#if $isCameraInScanMode}
        <button class="-button-reset" on:click={() => finishScan()}>
          <svg class="feather-icon -with-glow">
            <use href="feather-sprite.svg#x"/>
          </svg>
        </button>
      {:else}
        <button class="-button-reset inbox {isInboxFilled ? '-with-badge' : ''}" on:click={openInbox}>
          <svg class="feather-icon -with-glow">
            <use href="feather-sprite.svg#inbox"/>
          </svg>
        </button>
      {/if}
    </div>
  </ActionBar>
</div>

<style>
  .media-view-container {
    box-shadow: inset 0 0 20px var(--brand-color);
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transform: scale(var(--zoom-level));
  }

  video.-mirror-horizontally {
    transform: scale(calc(-1 * var(--zoom-level)), var(--zoom-level));
  }

  .action-bar-item {
    min-width: 33%;
  }

  button.-with-badge:after {
    top: 0.7rem;
    left: 1.8rem;
  }

  button.small-text-button {
    /* NOTE: this small text button is supposed to be local to the camera / media-view-container */
    border-radius: 1rem;
    padding: 0.3rem 0.6rem;
    font-size: 0.8rem;
    aspect-ratio: auto;
    margin-right: 0.6rem;
    transform: translateY(-0.2rem);
  }

  button.small-text-button.update-app {
    background-color: var(--white);
    text-transform: uppercase;
    color: var(--black);
  }

  button.small-text-button.send-to-contact {
    position: absolute;
    top: -1.5rem;
    left: 50%;
    max-width: min(70vw, 15rem);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transform: translateX(-50%);
  }

  .middle-content {
    position: absolute;
    bottom: 30vh;
    text-align: center;
    width: 100%;
    color: var(--white);
  }

  .scanmode-overlay {
    width: 100%;
    height: 25%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    color: var(--white);
    text-align: center;
    display: flex;
    align-items: end;
  }

  .scanmode-overlay.-at-top {
    top: 0;
  }

  .scanmode-overlay.-at-bottom {
    bottom: 0;
  }

  .scanmode-overlay > p {
    width: 100%;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  .file-upload {
    position: relative;
    padding: 0.6rem 0.8rem;
  }

  .file-upload > .feather-icon {
    position: relative;
    top: 3px;
  }

  .file-upload > .info {
    position: absolute;
    left: 4rem;
    top: 0.8rem;
    width: max-content;
    color: var(--grey);
  }

  .file-upload > .info.-make-space-below {
    top: -1rem;
    left: 3rem;
  }

  .file-upload > .info > .feather-icon {
    vertical-align: middle;
  }
</style>
