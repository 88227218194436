import { logger } from './stores'

export async function registerServiceWorker () {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('./service-worker.js', {
        scope: '/'
      })

      if (registration.installing) {
        logger.log('Service worker installing')
      } else if (registration.waiting) {
        logger.log('Service worker installed')
      } else if (registration.active) {
        logger.log('Service worker active')

        window.addEventListener('SERVICE_WORKER_CHECK_FOR_UPDATE', async () => {
          logger.log('Service worker checking for update (triggered manually)...')

          try {
            logger.log('Service worker updating...')
            await registration.update()
          } catch (error) {
            logger.error(error, 'Service worker manual update')
          }
        })
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`)
    }
  }
}
