import { logger } from '../stores'

export function handleError (error) {
  if (error instanceof Response) {
    if (error.parsedText) {
      return error.parsedText
    } else if (error.status === 400) {
      return 'Sorry, the server returned an unspecifed error.'
    } else if (error.status === 404) {
      return 'Sorry, the resource you requested couldn\'t be found.'
    } else if (error.status === 403) {
      return 'Sorry, you do not have the permissions for this request.'
    }
  }

  logger.error(error)

  return `There was an error when processing: ${error.message}`
}
