import * as pushNotificationBrowserStrategy from './push-notifications-browser.js'
import * as pushNotificationNativeAppleStrategy from './push-notifications-native-apple.js'

function getSupportedStrategy () {
  if (pushNotificationBrowserStrategy.isSupported()) {
    return pushNotificationBrowserStrategy
  }

  if (pushNotificationNativeAppleStrategy.isSupported()) {
    return pushNotificationNativeAppleStrategy
  }

  return null
}

export function isSupported () {
  return getSupportedStrategy() != null
}

export async function createSubscription (inboxServerHost) {
  const strategy = getSupportedStrategy()

  return await strategy.createSubscription(inboxServerHost)
}

export async function revokeSubscription () {
  const strategy = getSupportedStrategy()

  return await strategy.revokeSubscription()
}

export async function checkPermissions () {
  const strategy = getSupportedStrategy()

  if (!isSupported()) {
    // If no strategy is supported, it should still return truthy permission,
    // as this triggers the remote fetch to check if the inbox server has a subscription.
    // TODO: Refine this because of weird logic.
    return true
  }

  return await strategy.checkPermissions()
}
