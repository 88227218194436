<script>
  import { IS_IOS } from '../constants.js'
  import { settings } from '../stores'
  import { checkPermissions, createSubscription, revokeSubscription, isSupported } from '../functions/push-notifications.js'
  import { inboxApi } from '../api'
  import { handleError } from '../functions/handle-error.js'

  const supported = isSupported()

  let isLoading = false
  let errorMessage = ''
  let hasPermissions = false
  let hasPushSubscriptionOnAccount = false

  $: isEnabled = IS_IOS ? hasPushSubscriptionOnAccount : hasPermissions && hasPushSubscriptionOnAccount

  async function enableNotifications () {
    errorMessage = ''
    isLoading = true

    try {
      const subscription = await createSubscription($settings.inboxServerHost)
      const response = await inboxApi.accountRequests.updatePushSubscription(subscription)

      if (response.ok) {
        hasPermissions = true
        hasPushSubscriptionOnAccount = true
      }
    } catch (error) {
      errorMessage = handleError(error)
    }

    isLoading = false
  }

  async function disableNotifications () {
    errorMessage = ''
    isLoading = true

    try {
      hasPermissions = !(await revokeSubscription())
      const response = await inboxApi.accountRequests.updatePushSubscription({})

      if (response.ok) {
        hasPushSubscriptionOnAccount = false
      }
    } catch (error) {
      errorMessage = handleError(error)
    }

    isLoading = false
  }

  async function fetchHasPushSubscription () {
    isLoading = true

    try {
      const response = await inboxApi.accountRequests.get()
      const json = await response.json()

      hasPushSubscriptionOnAccount = json.hasPushSubscription
    } catch {}

    isLoading = false
  }

  async function setup () {
    try {
      hasPermissions = await checkPermissions()

      fetchHasPushSubscription()
    } catch (error) {
      errorMessage = handleError(error)
    }
  }

  setup()
</script>

<div class="content-box">
  <header>
    <span>
      <svg class="feather-icon -normalize"><use href="feather-sprite.svg#bell"/></svg>

      Notifications {#if supported && isEnabled}are enabled{/if}
    </span>
  </header>

  <p>
    {#if supported}
      You will only receive notifications when friends of yours send you a pic.
    {:else}
      Notifications are not supported in your browser or device. Please make sure you have the latest software updates of your operating system and browser.
    {/if}
  </p>

  {#if supported}
    <div class="button-row">
      <button
        class="-button-toggle"
        on:click={isEnabled ? disableNotifications : enableNotifications}
        disabled={isLoading}
      >
        <svg class="feather-icon -toggle">
          {#if isEnabled}
            <use href="feather-sprite.svg#check-square" class="-toggle-on" />
          {:else}
            <use href="feather-sprite.svg#square" />
          {/if}
        </svg>

        Allow notifications

        {#if isLoading}
          <svg class="feather-icon -rotate-animation"><use href="feather-sprite.svg#loader"/></svg>
        {/if}
      </button>
    </div>
  {/if}

  {#if errorMessage}
    <p class="text-color-error">{errorMessage}</p>
  {/if}
</div>
