<script>
  import { viewStack, settings, logger, isCameraInScanMode } from '../../stores'
  import ViewHeader from '../ViewHeader'
  import InboxServerInlineInfo from '../InboxServerInlineInfo.svelte'
  import { processAndAcceptExchangeUrl } from '../../functions/contact-exchange-coder.js'
  import { SCAN_MODE_TYPE } from '../../constants.js'

  export let exchangeUrl = null
  export let changeInboxServerHost = false

  let errorMessage = ''
  let isProcessing
  let userUuid

  const hasProfile = $settings.inboxServerHost != null

  function handleError (error) {
    if (error instanceof Response) {
      errorMessage = error.parsedText ? error.parsedText : `There was an error when processing (Response: ${error.statusText})`
    } else {
      logger.error(error)
      errorMessage = `There was an error when processing. ${error.message}`
    }
  }

  async function pasteFromClipboard () {
    isProcessing = true

    try {
      let text

      if (!navigator.clipboard?.readText) {
        text = window.prompt('Paste the invite link in here:')
      } else {
        text = await navigator.clipboard.readText()
      }

      await processExchangeUrl(text)
    } catch (error) {
      handleError(error)
    }

    isProcessing = false
  }

  async function onExternalExchangeUrl (exchangeUrl) {
    isProcessing = true

    try {
      await processExchangeUrl(exchangeUrl)
    } catch (error) {
      handleError(error)
    }

    isProcessing = false
  }

  async function processExchangeUrl (exchangeUrl) {
    if (!exchangeUrl) {
      errorMessage = 'You first need to copy a friend invite link to paste it.'
      return
    }

    if (!$settings.inboxServerHost && !changeInboxServerHost) {
      errorMessage = 'Sign up with an inbox server first by either creating a profile or moving to an inbox server.'
      return
    }

    if (changeInboxServerHost) {
      setTimeout(() => {
        // NOTE: Without the timeout, it could happen that the accept view appears before signup view.
        viewStack.pop()
        viewStack.push('ContactExchangeSignupInboxServer', { exchangeUrl })
      }, 50)

      return
    }

    const {
      encryptedContact,
      secretKey
    } = await processAndAcceptExchangeUrl(exchangeUrl, $settings.inboxServerHost, $settings.publicKey, $settings.displayName)

    viewStack.pop()
    viewStack.push('ContactExchangeSaveContact', { encryptedContact, secretKey })
  }

  function activateScan () {
    $isCameraInScanMode = changeInboxServerHost ? SCAN_MODE_TYPE.SIGNUP_INBOX : SCAN_MODE_TYPE.SAVE_CONTACT
    viewStack.clear()
  }

  async function resetAndGoBack () {
    await settings.clearAll()
    window.location.reload()
  }

  async function setup () {
    if (!hasProfile) {
      // The profile is not yet signed up to a server.
      viewStack.disableClose()

      userUuid = await settings.getUserUuid()
      logger.log(`profile piqchat identifier: ${userUuid}`)
    }

    if (exchangeUrl) {
      onExternalExchangeUrl(exchangeUrl)
    } else if ($settings.deferredContactExchangeUrl) {
      onExternalExchangeUrl($settings.deferredContactExchangeUrl)
      settings.set('deferredContactExchangeUrl', null)
    }
  }

  setup()
</script>

<ViewHeader disableClose={!hasProfile}>
  {#if changeInboxServerHost && hasProfile}
    Move to a different inbox server
  {:else}
    Accept friend invite
  {/if}

  <span slot="secondary">
    <button class="-button-reset" on:click={() => viewStack.push('ContactExchangeExplanation')}>
      <svg class="feather-icon"><use href="feather-sprite.svg#help-circle"/></svg>
    </button>
  </span>
</ViewHeader>

{#if changeInboxServerHost && !hasProfile}
  <p>
    <button class="-button-reset" style="padding: 0" on:click={() => window.alert(`Your piqchat identifier:\n\n${userUuid}`)}>
      Hey {$settings.displayName}!
    </button>

    Do you have a friend invite?
  </p>

  <p>
    You need a friend invite from another piqchat user to get started.
    Or you can set up your own <button class="-button-link" on:click={() => viewStack.push('InboxServerExplanation')}>inbox server</button>.
  </p>
{:else if changeInboxServerHost}
  <InboxServerInlineInfo />

  <div class="vertical-empty-space-half" />

  <p>piqchat gives you the possibility to be not dependent on one inbox server. You can always move to a different one.</p>

  <p>
    To move you'll need a friend invite from a piqchat user.
  </p>
{/if}

{#if isProcessing}
  <p>
    Processing...
    <svg class="feather-icon -normalize -rotate-animation"><use href="feather-sprite.svg#loader"/></svg>
  </p>

  <div class="vertical-empty-space-half" />
{:else if errorMessage}
  <p class="text-color-error">{errorMessage}</p>

  <div class="vertical-empty-space-half" />
{:else}
  <div class="vertical-empty-space" />
{/if}

<p class="accept-options">
  <button on:click={pasteFromClipboard} class="-button-primary" disabled={isProcessing}>
    <svg class="feather-icon"><use href="feather-sprite.svg#clipboard"/></svg>
    <br />
    Paste invite link
  </button>

  <button on:click={activateScan} class="-button-primary" disabled={isProcessing}>
    <svg class="feather-icon"><use href="feather-sprite.svg#camera"/></svg>
    <br />
    Scan QR code
  </button>
</p>

<div class="vertical-empty-space-2x" />

{#if !hasProfile}
  <p>
    <button on:click={() => resetAndGoBack()} class="-button-link">Go back and reset</button>
  </p>
{/if}

<style>
  .accept-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .accept-options .feather-icon {
    width: 2rem;
    height: 2rem;
    margin-bottom: 1rem;
    margin-top: 0.4rem;
    stroke-width: 1;
  }
</style>
