<script>
  import { settings } from '../stores'
  import { LEVEL_EMOJIS } from '../constants.js'
  import { getLevelProperties, predictPoints, calculateLevelFromPointCount } from '../functions/contact-points.js'

  export let contactPoints

  const level = getLevelProperties(contactPoints)
  const isTheirTurn = contactPoints.isTheirTurn
  const predictedPoints = predictPoints(contactPoints)
  const highestLevel = calculateLevelFromPointCount(contactPoints.highestCount)
</script>

<div class="content-box">
  <header>
    <span>piqlevel (beta)</span>
    <span class="predicted-points">{predictedPoints}</span>
  </header>

  <div class="progress-bar">
    {#if level.previousLabel}
      <div class="label -as-previous">{level.previousLabel}</div>
    {/if}

    {#if level.label}
      <div class="label -as-current">{level.label}</div>
    {/if}

    <div class="container">
      <div
        class="percentage"
        class:-is-decreasing={level.hoursUntilDecrease == null}
        style="width: {level.progressPercentage}%"
      >
      </div>

      {#if level.progressDaysToNextLevel !== 0 && predictedPoints > 0}
        <span class="days">
          ~{level.progressDaysToNextLevel} more day{#if level.progressDaysToNextLevel !== 1}s{/if}
        </span>
      {/if}
    </div>

    <div class="label -as-next">{level.nextLabel}</div>
  </div>

  <p>
    {#if level.hoursUntilDecrease != null && level.hoursUntilDecrease < 6}
      {#if isTheirTurn}They{:else}You{/if} have roughly {level.hoursUntilDecrease} hours to send a pic to keep the steep incline of collecting points.
    {:else if level.hoursUntilDecrease == null && predictedPoints > 0}
      You are currently loosing points each day.
    {/if}

    {#if $settings.isDebugMode}
      {contactPoints.count} > {predictedPoints}
    {/if}
  </p>

  <p class="highest-level">
    {#if highestLevel && highestLevel > level.level}
      Your highest level was <span>{LEVEL_EMOJIS[highestLevel - 1]}</span>
    {/if}
  </p>
</div>

<style>
  .predicted-points {
    font-weight: normal;
    color: var(--dark-grey);
  }

  .highest-level {
    margin-top: 1rem;
  }

  .highest-level > span {
    background-color: var(--white);
    padding: 0.3rem;
    font-size: 0.9rem;
    border-radius: 3px;
  }

  .progress-bar {
    display: flex;
    margin-top: 0.8rem;
  }

  .progress-bar > .label {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: var(--white);
    text-align: center;
    flex-shrink: 0;
    flex-grow: 0;
    line-height: 2.1;
    border: 3px solid var(--brand-color);
  }

  .progress-bar > .label.-as-previous {
    margin-right: 1rem;
  }

  .progress-bar > .label.-as-current {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .progress-bar > .label.-as-next {
    border-color: var(--white);
    border-bottom-left-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
  }

  .progress-bar > .container {
    position: relative;
    height: 2.5rem;
    width: 100%;
    overflow: hidden;
    background-color: var(--white);
    margin-right: 0.3rem;
    text-align: center;
    border-bottom-right-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }

  .progress-bar > .container > .percentage.-is-decreasing:after {
    content: ' ';
    position: absolute;
    top: 0.8rem;
    right: 0;
    width: 0;
    height: 0;
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    border-right: 0.5rem solid var(--white);
  }

  .progress-bar > .container > .percentage {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: var(--brand-color);
  }

  .progress-bar > .container > .days {
    background-color: rgba(255, 255, 255, 0.3);
    padding: 0.3rem 0.5rem;
    border-radius: 0.3rem;
    position: relative;
    font-size: 0.7rem;
    margin-top: 0.6rem;
    line-height: 1;
    display: inline-block;
  }
</style>
