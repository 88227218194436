import App from './components/App'
import { registerServiceWorker } from './register-service-worker'
import 'context-filter-polyfill'

registerServiceWorker()

const app = new App({
  target: document.body
})

export default app
