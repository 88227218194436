<script>
  import { viewStack, settings, contacts, isUnauthorized as isUnauthorizedStore } from '../../stores'
  import { inboxApi } from '../../api'
  import ViewHeader from '../ViewHeader'
  import InboxServerStatistics from '../InboxServerStatistics.svelte'
  import { dumeToNow, parseIsoString } from '../../functions/dume.js'
  import { contactsSort } from '../../functions/contacts-sort.js'

  export let inboxServerHost

  $: info = $settings.allInboxServerMap[inboxServerHost]

  let statistics

  async function setup () {
    const infoResponse = await inboxApi.metaRequests.getServerInfo(inboxServerHost)
    statistics = infoResponse.statistics
  }

  setup()

  $: isOwnHost = $settings.inboxServerHost === inboxServerHost
  $: isUnauthorized = isOwnHost && $isUnauthorizedStore
  $: isDown = info?.isDown !== false

  const contactNamesSignedUp = $contacts
    .filter((contact) => contact.inboxServerHost === inboxServerHost)
    .map((contact) => contact.displayName)
</script>

<ViewHeader showOfflineInfo>
  {info?.adminDisplayName}'s inbox server

  <h3><code>{inboxServerHost}</code></h3>

  <span slot="secondary">
    <button class="-button-reset" on:click={() => viewStack.push('InboxServerExplanation')}>
      <svg class="feather-icon"><use href="feather-sprite.svg#help-circle"/></svg>
    </button>
  </span>
</ViewHeader>

<div class="vertical-empty-space" />

{#if isDown || isUnauthorized}
  <div class="content-box -has-error">

    {#if isDown}
      <p>
        <strong class="text-color-error">
          <svg class="feather-icon -normalize"><use href="feather-sprite.svg#alert-triangle"/></svg>
          Attention! Connection to this inbox server couldn't be established.
        </strong>

        {#if isOwnHost}<strong>You won't receive any pics.</strong>{/if}
        You {#if isOwnHost}also{/if} can't send pics to friends who are signed up to this inbox server as long as it is offline.
      </p>

      <p>
        {#if info?.lastConnectedAt}
          The last successfull connection to this inbox server was {dumeToNow(parseIsoString(info.lastConnectedAt))} ago.
        {/if}
      </p>

      {#if isOwnHost}
        <p>If the server remains offline, you can move to a another inbox server with a friend invite.</p>
      {/if}
    {:else if isUnauthorized}
      <p>
        <strong class="text-color-error">
          <svg class="feather-icon -normalize"><use href="feather-sprite.svg#alert-triangle"/></svg>
          Attention! You are not authorized. It seems that you are not signed up.
        </strong>

        This is the inbox server were you are supposed to receive pics from your friends.
        Contact the server admin to find out more or get a new friend invite and sign up again.
      </p>
    {/if}

    {#if isOwnHost}
      <p>
        <button class="-button-secondary full-width" on:click={() => viewStack.push('ContactExchangeAccept', { changeInboxServerHost: true })}>
          Sign up to an inbox server...
        </button>
      </p>
    {/if}
  </div>
{/if}

<p>
  This inbox server is provided by "{info?.adminDisplayName}".

  {#if isOwnHost}It's where <em>you</em> signed up!{/if}
  {#if contactNamesSignedUp.length > 0}
    <button class="-button-link" on:click={() => window.alert(contactNamesSignedUp.sort(contactsSort).join(', '))}>
      {contactNamesSignedUp.length} friend{#if contactNamesSignedUp.length !== 1}s{/if}
    </button>
    {#if isOwnHost}also {/if}signed up here.
  {/if}
</p>

<div class="vertical-empty-space" />

<div class="content-box">
  <header>Message from {info?.adminDisplayName}</header>

  {#if info?.adminMessage}
    <p class="white-space-pre-line">{info?.adminMessage}</p>
  {:else}
    <p>No message provided.</p>
  {/if}
</div>

{#if statistics}
  <h5>Server Statistics</h5>

  <InboxServerStatistics statistics={statistics} />
{/if}

<div class="vertical-empty-space" />

<p>
  <a
    class="-button-secondary -button-small -link-button text-align-center full-width"
    href="{info?.privacyPolicyUrl}"
    target="_blank"
    class:-button-disabled={!info?.privacyPolicyUrl}
  >
    Open privacy policy for inbox server
    <svg class="feather-icon -normalize"><use href="feather-sprite.svg#external-link"/></svg>
  </a>
</p>

<div class="vertical-empty-space-half" />

<p>
  <small>
    <svg class="feather-icon third-party-warning"><use href="feather-sprite.svg#alert-triangle"/></svg>
    Inbox servers are third-parties and are not related with the piqchat app.
  </small>
</p>

<style>
  .third-party-warning {
    width: 0.7rem;
    height: 0.7rem;
  }

  .content-box.-has-error .feather-icon {
    position: relative;
    top: 2px;
  }
</style>
