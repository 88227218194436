import { get } from 'svelte/store'
import { settings as settingStore, logger } from '../stores'

export async function toggleRecoveryKeyLock () {
  const settings = get(settingStore)

  if (settings.isRecoveryKeyLocked) {
    await authenticate(await settingStore.getUserUuid())
  } else {
    await register()
  }

  await settingStore.set('isRecoveryKeyLocked', !settings.isRecoveryKeyLocked)
}

export async function optionalAuthBeforeCopy () {
  const settings = get(settingStore)

  if (!settings.isRecoveryKeyLocked) {
    return true
  }

  try {
    await authenticate(await settingStore.getUserUuid())

    return true
  } catch (error) {
    logger.error(error, 'authCopyRecoveryKey')
  }

  return false
}

export async function register () {
  const userUuid = await settingStore.getUserUuid()
  const settings = get(settingStore)

  const user = {
    id: Uint8Array.from(userUuid, c => c.charCodeAt(0)),
    name: `${settings.displayName} at ${settings.inboxServerHost}`,
    displayName: settings.displayName
  }

  return await navigator.credentials.create({
    publicKey: {
      challenge: new Uint8Array(),
      userVerification: 'required',
      rp: { name: 'piqchat' },
      user,
      pubKeyCredParams: [{ alg: -7, type: 'public-key' }]
    }
  })
}

export async function authenticate (userUuid) {
  const credentials = await navigator.credentials.get({
    publicKey: {
      mediation: 'required',
      userVerification: 'required',
      challenge: new Uint8Array()
    }
  })

  const credentialsUuid = String.fromCharCode.apply(null, new Uint8Array(credentials.response.userHandle))

  if (credentialsUuid !== userUuid) {
    throw new Error('Credentials are from different user')
  }

  return credentials
}
