<script>
  import { onMount, createEventDispatcher } from 'svelte'
  import { MEDIA_TEXT_TYPES, IS_IOS } from '../constants.js'
  import EditorColorPicker from './EditorColorPicker.svelte'

  export let sentence
  export let containerElement

  const maxValueLength = 250
  const keyboardToolbarHeight = 50
  let textareaElement
  let alternateKeyboard = 'NATIVE' // NATIVE, COLOR or FONT

  const dispatch = createEventDispatcher()

  let availableFonts = [
    { label: 'Sans-serif', stack: null },
    { label: 'Serif', stack: 'serif' },
    { label: 'Monospace', stack: 'monospace' },
    { label: 'Cursive', stack: 'cursive' },
    { label: 'Fantasy', stack: 'fantasy' }
  ]

  if (IS_IOS) {
    availableFonts = [
      { label: 'Default', stack: null },
      { label: 'Arial Hebrew', stack: '"Arial Hebrew", sans-serif' },
      { label: 'Academy Engraved LET', stack: '"Academy Engraved LET", gothic, sans-serif' },
      { label: 'American Typewriter', stack: '"American Typewriter", sans-serif' },
      { label: 'Avenir', stack: 'Avenir, sans-serif' },
      { label: 'Bodoni 72', stack: '"Bodoni 72", serif' },
      { label: 'Chalkboard SE', stack: '"Chalkboard SE", cursive' },
      { label: 'Chalkduster', stack: 'Chalkduster, cursive' },
      { label: 'Courier New', stack: '"Courier New", monospace' },
      { label: 'DIN Alternate', stack: '"DIN Alternate", sans-serif' },
      { label: 'Futura', stack: 'Futura, sans-serif' },
      { label: 'Gill Sans', stack: '"Gill Sans", sans-serif' },
      { label: 'Impact', stack: 'Impact, sans-serif' },
      { label: 'Kohinoor Gujarati', stack: '"Kohinoor Gujarati", sans-serif' },
      { label: 'Kefa', stack: 'Kefa, serif' },
      { label: 'Menlo', stack: 'Menlo, monospace' },
      { label: 'Noteworthy', stack: 'Noteworthy, cursive' },
      { label: 'Party LET', stack: '"Party Let", fantasy' },
      { label: 'Rockwell', stack: 'Rockwell, serif' },
      { label: 'Snell Roundhand', stack: '"Snell Roundhand", cursive' },
      { label: 'Times New Roman', stack: '"Times New Roman", serif' },
      { label: 'Zapfino', stack: 'Zapfino, cursive' }
    ]
  }

  let availableViewportSpaceWhenEditing
  $: availableViewportSpaceWhenEditingCss = availableViewportSpaceWhenEditing == null
    ? '150px' // arbitrary default height that should not be to small, to ensure that the focus in the textarea causes no scrolling
    : `${availableViewportSpaceWhenEditing}px`

  function closeInput (force) {
    if (force !== true && alternateKeyboard !== 'NATIVE') {
      return
    }

    availableViewportSpaceWhenEditing = null

    dispatch('close')
  }

  function calculateAvailableViewportSpace () {
    const windowOffset = containerElement.getBoundingClientRect().top
    availableViewportSpaceWhenEditing = window.visualViewport.height - windowOffset - keyboardToolbarHeight
  }

  function fitTextareaHeightToContent () {
    textareaElement.style.height = null
    textareaElement.style.height = `${textareaElement.scrollHeight}px`
  }

  function updateValue (event) {
    if (textareaElement.value.length > maxValueLength) {
      const previousSelectionPosition = event.target.selectionStart - 1

      event.target.value = sentence.value
      event.target.selectionStart = previousSelectionPosition
      event.target.selectionEnd = previousSelectionPosition

      return
    }

    sentence.value = event.target.value
  }

  function toggleTextType () {
    sentence.type = (sentence.type === MEDIA_TEXT_TYPES.OVERLAY) ? MEDIA_TEXT_TYPES.FREE : MEDIA_TEXT_TYPES.OVERLAY

    sentence.rotation = 0
    sentence.scale = 1
    sentence.position.y = 100
    sentence.position.x = null

    // resize needed when text types are switched out, because they have different font-sizes
    setTimeout(() => fitTextareaHeightToContent(), 50)
  }

  function toggleTextAlignment () {
    let newAlignment

    if (sentence.alignment === 'left') {
      newAlignment = 'right'
    } else if (sentence.alignment === 'center') {
      newAlignment = 'left'
    } else {
      newAlignment = 'center'
    }

    sentence.alignment = newAlignment
  }

  function toggleAlternateKeyboard (type) {
    if (alternateKeyboard === type) {
      alternateKeyboard = 'NATIVE'

      if (type !== 'NATIVE') {
        textareaElement.focus()
      }
    } else {
      alternateKeyboard = type

      if (type !== 'NATIVE') {
        textareaElement.blur()
      }
    }
  }

  function selectFont (font) {
    sentence.font = font.stack

    setTimeout(() => fitTextareaHeightToContent(), 50)
  }

  function clickOnInputArea () {
    if (alternateKeyboard !== 'NATIVE') {
      textareaElement.focus()
    } else {
      textareaElement.blur()
    }
  }

  onMount(() => {
    textareaElement.focus()

    fitTextareaHeightToContent()

    window.visualViewport.addEventListener('resize', calculateAvailableViewportSpace, { once: true })
  })
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="text-editor"
  class:-no-native-keyboard={availableViewportSpaceWhenEditing == null || alternateKeyboard !== 'NATIVE'}
  class:-show-backdrop={alternateKeyboard !== 'COLOR'}
  on:click|self={clickOnInputArea}
>
  <div class="input-area" style:min-height={availableViewportSpaceWhenEditingCss} on:click|self={clickOnInputArea}>
    <textarea
      bind:this={textareaElement}
      row="1"
      tabindex="-1"
      value={sentence.value}
      on:input={fitTextareaHeightToContent}
      on:input={updateValue}
      on:blur={closeInput}
      on:focus={() => toggleAlternateKeyboard('NATIVE')}
      class:-type-overlay={sentence.type === MEDIA_TEXT_TYPES.OVERLAY}
      class:-type-free={sentence.type === MEDIA_TEXT_TYPES.FREE}
      style:text-align={sentence.alignment}
      style:color={sentence.color}
      style:font-family={sentence.font}
    />
  </div>

  <div class="toolbar" style:height={`${keyboardToolbarHeight}px`}>
    <button class="-button-reset" on:click={toggleTextType} on:mousedown|preventDefault>
      <svg class="feather-icon -with-glow"><use href="feather-sprite.svg#type"/></svg>
    </button>

    <button class="-button-reset" on:click={toggleTextAlignment} on:mousedown|preventDefault>
      <svg class="feather-icon -with-glow">
        <use href="feather-sprite.svg#align-{sentence.alignment}"/>
      </svg>
    </button>

    <button class="-button-reset" on:click={() => toggleAlternateKeyboard('COLOR')} on:mousedown|preventDefault>
      <div class="color-preview -with-glow" style:background-color={sentence.color}>
        {#if alternateKeyboard === 'COLOR'}
          <svg class="feather-icon"><use href="feather-sprite.svg#check"/></svg>
        {/if}
      </div>
    </button>

    <button class="-button-reset -with-glow select-font" on:click={() => toggleAlternateKeyboard('FONT')} on:mousedown|preventDefault>
      Font

      <svg class="feather-icon -normalize">
        <use href="feather-sprite.svg#{alternateKeyboard === 'FONT' ? 'check' : 'chevron-down'}"/>
      </svg>
    </button>
  </div>

  <div class="alternate-keyboard">
    {#if alternateKeyboard === 'COLOR'}
      <EditorColorPicker bind:color={sentence.color} isVisible isStatic showColorPreview={false} on:close={() => closeInput(true)} />
    {:else if alternateKeyboard === 'FONT'}
      <div class="font-options">
        {#each availableFonts as font}
          <button
            class="-button-reset font"
            class:-selected={sentence.font === font.stack}
            style:font-family={font.stack}
            on:click={() => selectFont(font)}
          >
            {font.label} <svg class="feather-icon -normalize"><use href="feather-sprite.svg#check"/></svg>
          </button>
        {/each}
      </div>
    {:else}
      <div class="vertical-empty-space-2x" />
    {/if}
  </div>
</div>

<style>
  .text-editor {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  .text-editor > .input-area {
    display: flex;
    align-items: center;

    -webkit-user-select: text;
    user-select: text;
  }

  .text-editor.-no-native-keyboard > .input-area {
    margin-bottom: auto;
  }

  .text-editor.-show-backdrop {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .text-editor > .toolbar {
    color: var(--white);
    background-color: var(--black);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-editor > .toolbar .color-preview {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: white;
    outline: 2px solid var(--white);
    border: 2px solid var(--black);
  }

  .text-editor > .toolbar .color-preview > .feather-icon {
    width: 1rem;
    color: var(--white);
    filter: drop-shadow(0px 2px 0px var(--black));
    height: 1.3rem;
    stroke-width: 3px;
  }

  .text-editor > .toolbar button.select-font {
    border: 2px solid var(--white);
    border-radius: 1rem;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    margin-right: 0.8em;
    padding-right: 0.3em;
  }

  .text-editor > .toolbar button.select-font > .feather-icon {
    position: relative;
    top: -1px;
  }

  .text-editor > .alternate-keyboard {
    color: var(--white);
    background-color: var(--black);
    height: 100%;
    flex-grow: 1;
  }

  .text-editor > .alternate-keyboard .font-options {
    padding: 1rem;
    text-align: center;
  }

  .text-editor.-no-native-keyboard > .alternate-keyboard {
    flex-grow: 0;
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior-y: contain;
  }

  .text-editor .font-options button.font {
    padding: 0.3em 0.4em;
    margin: 0.3em 0.4em;
    font-size-adjust: 0.5;
  }

  .text-editor .font-options button.font.-selected {
    background-color: var(--white);
    color: var(--black);
    border-radius: 1rem;
  }

  .text-editor .font-options button.font > .feather-icon {
    opacity: 0;
    vertical-align: middle;
  }

  .text-editor .font-options button.font.-selected > .feather-icon {
    opacity: 1;
  }

  .text-editor > .input-area > textarea {
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    margin: 0;
    -webkit-user-select: text;
    user-select: text;
    resize: none;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior-y: contain;

    width: 100%;
    height: 2rem; /* height is set in javascript, this is the initial default. 1rem + 2*0.5rem for padding */
    max-height: 70%;

    font-family: inherit;
  }

  .text-editor > .input-area > textarea:focus {
    outline: none;
  }
</style>
