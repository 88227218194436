import { openDB } from 'idb'
import { BROWSER_STORAGE_PREFIX } from '../constants.js'

export let database

export async function openAndMigrateDatabase () {
  database = await openDB(BROWSER_STORAGE_PREFIX, 5, {
    upgrade (database, oldVersion, _newVersion, transaction) {
      // TODO: migrations with a switch (and no `break`s)
      switch (oldVersion) {
        case 0: {
          database.createObjectStore('settings', { keyPath: 'key' })
          database.createObjectStore('contacts', { keyPath: 'id' })
          database.createObjectStore('request_queue', { keyPath: 'id' })
          database.createObjectStore('media', { keyPath: 'id' })
            .createIndex('size', 'size')
        }
        // disable-eslint-next-line no-fallthrough
        case 1: {
          database.deleteObjectStore('request_queue')
          database.deleteObjectStore('media')
          database.createObjectStore('upload_parcels_queue', { keyPath: 'id' })
          database.createObjectStore('received_parcels', { keyPath: 'id' })
            .createIndex('size', 'size')
        }
        // disable-eslint-next-line no-fallthrough
        case 4: {
          const store = transaction.objectStore('received_parcels')
          store.deleteIndex('size')
          store.createIndex('uploadedBy', 'uploadedBy') // Needed for: When deleting contact while parcel still exist
        }
      }
    }
  })

  window.database = database
}
