import { writable } from 'svelte/store'
import { database } from './indexeddb.js'

const STORE_NAME_SETTINGS = 'settings'

export function createContactExchangeActiveStore () {
  const { subscribe, set } = writable()

  async function load (value) {
    if (!value) {
      value = (await database.get(STORE_NAME_SETTINGS, 'contactExchangeActiveOffer'))?.value
    }

    set(value)
    return value
  }

  return {
    subscribe,
    load,

    async set (oneTimeToken, acceptUrl, secretKey, timeToLive) {
      const value = { oneTimeToken, acceptUrl, secretKey, timeToLive }

      await database.put(STORE_NAME_SETTINGS, { key: 'contactExchangeActiveOffer', value })
      await load(value)
    },

    async clear () {
      const value = null
      await database.put(STORE_NAME_SETTINGS, { key: 'contactExchangeActiveOffer', value })
      await load(value)
    }
  }
}
