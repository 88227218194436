<script>
  import { onDestroy } from 'svelte'
  import { fade } from 'svelte/transition'
  import { uploadingProgress } from '../stores'

  /**
   * For slow internet connections the progress of the upload should be made visible.
   * Otherwise the infinite spinner could frustrate the user and close/kill the app.
   *
   * The goals of this progress bar:
   * - Show if the upload is not finished after 3s
   * - Avoid confusion if multiple uploads are in succession and the bar starts from the beginning
   *   Solved by the "isSecondaryBarVisible" and how it fades away after completion
   */

  let startTimer = null
  let finishTimer = null
  let barPercentage = 0
  let skipStartTimer = false
  let isSecondaryBarVisible = false

  const subscription = uploadingProgress.subscribe((progress) => {
    if (progress != null && startTimer == null && barPercentage === 0) {
      startTimer = setTimeout(() => {
        barPercentage = progress.percentage
        startTimer = null
      }, skipStartTimer ? 0 : 3000)
    }

    if (progress == null && startTimer != null) {
      // The progress finished before the startTimer was able to complete its delay.
      clearTimeout(startTimer)
      startTimer = null
    }

    if (barPercentage > 0) {
      if (progress != null) {
        if (progress.percentage > barPercentage) {
          barPercentage = progress.percentage
        }
      } else {
        barPercentage = 0
        isSecondaryBarVisible = true
        skipStartTimer = true

        clearTimeout(finishTimer)

        finishTimer = setTimeout(() => {
          isSecondaryBarVisible = false
        }, 1000)
      }
    }
  })

  onDestroy(subscription)
</script>

<div class="upload-progress-bar">
  {#if isSecondaryBarVisible}
    <div
      class="bar -is-fullfilled"
      style:width="100%"
    />
  {/if}

  {#if barPercentage > 0}
    <div
      class="bar"
      style:width={`${barPercentage}%`}
      in:fade={{ duration: 300 }}
    />
  {/if}
</div>

<style>
  .upload-progress-bar {
    height: 0.2rem;
    margin: 0 1rem;
    position: relative;
  }

  .upload-progress-bar > .bar {
    height: 100%;
    background-color: var(--brand-color);
    transition: width 1000ms linear;
    overflow: hidden;
    border-radius: 2px;
    opacity: 1;
    width: 0;
    position: absolute;
  }

  @keyframes opacity-keyframes {
      from {
          opacity: 1;
      }
      to {
          opacity: 0;
      }
  }

  .upload-progress-bar > .bar.-is-fullfilled {
    animation: 1000ms linear opacity-keyframes;
    animation-fill-mode: forwards;
  }
</style>
