<script>
  import { onMount, onDestroy, createEventDispatcher } from 'svelte'
  import { logger } from '../stores'
  import { dumeToNow } from '../functions/dume'
  import ActionBar from '../components/ActionBar'

  const dispatch = createEventDispatcher()

  export let content
  export let contactName
  export let openedAt

  const type = content.meta?.type ?? 'image/jpeg'
  let imageElement
  let videoElement

  let interval
  let secondsLeft = calculateSecondsLeft(content.meta.duration, openedAt)

  const originalSecondsLeft = secondsLeft
  const percentageOfOriginalDuration = parseInt(secondsLeft / content.meta.duration * 100)

  const createdAtRelative = dumeToNow(new Date(content.meta.createdAt))

  initiateTimer()

  function calculateSecondsLeft (duration, openedAt) {
    return duration - ((Date.now() - openedAt) / 1000)
  }

  function initiateTimer () {
    clearInterval(interval)

    if (content.meta?.duration == null) {
      return
    }

    interval = setInterval(() => {
      secondsLeft = calculateSecondsLeft(content.meta.duration, openedAt)

      if (secondsLeft < 0) {
        dispatch('finish')
      }
    }, 100)
  }

  function onVideoEnded () {
    dispatch('finish')
  }

  onDestroy(() => {
    if (type.includes('video')) {
      URL.revokeObjectURL(videoElement.src)
    }

    clearInterval(interval)
  })

  onMount(async () => {
    const blob = new Blob([content.mediaBytes], { type })

    if (type.includes('image')) {
      imageElement.src = URL.createObjectURL(blob)
      imageElement.onload = () => URL.revokeObjectURL(imageElement.src)
    } else if (type.includes('video')) {
      videoElement.currentTime = content.meta.duration - secondsLeft
      videoElement.src = URL.createObjectURL(blob)

      const canPlayType = videoElement.canPlayType(type)
      logger.log(`MediaLookupWithTimer/onMount canPlayType '${type}': '${canPlayType}'`)
    }
  })
</script>

<div class="image-wrapper">
  <div class="meta-info">
    <span class="name">{contactName}</span>
    <span class="created-at">{createdAtRelative} ago</span>
  </div>

  {#if type.includes('image')}
    <img bind:this={imageElement} alt="" />
  {:else}
    <!-- svelte-ignore a11y-media-has-caption -->
    <video bind:this={videoElement} autoplay playsinline on:ended={onVideoEnded} />
  {/if}

  <ActionBar thin>
    <div slot="left">
      <button class="-button-reset close" on:click={() => dispatch('close')}>
        <svg class="feather-icon -with-glow">
          <use href="feather-sprite.svg#x"/>
        </svg>
      </button>
    </div>

    <div slot="right">
      <button class="-button-reset" on:click={() => dispatch('reply')}>
        <svg class="feather-icon -with-glow">
          <use href="feather-sprite.svg#camera" />
        </svg>
      </button>
    </div>
  </ActionBar>

  <div class="progress-bar" style="width: {percentageOfOriginalDuration}%">
    <div class="bar -with-glow" style="animation-duration: {originalSecondsLeft}s" />
  </div>
</div>

<style>
  .image-wrapper {
    position: relative;
    margin: 0 auto;
    max-width: var(--max-width);
    top: 50%;
    transform: translateY(-50%);
    max-height: var(--viewport-height);
  }

  .image-wrapper > img,
  .image-wrapper > video {
    border-radius: 1rem;
    aspect-ratio: 9 / 16;
    width: 100%;
    overflow: hidden;
    display: block;
    pointer-events: none;
  }

  .meta-info {
    position: absolute;
    top: -1.5rem;
    padding: 0 1rem;
    color: var(--grey);
    width: 100%;
    display: flex;
    justify-content: space-between;
    line-height: 1.2;
    white-space: nowrap;
  }

  .meta-info > .name {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (min-aspect-ratio: 9/17) {
    .meta-info {
      top: 0;
      font-size: 0.7rem;
      color: var(--light-grey);
      justify-content: end;
      padding: 0;
    }

    .meta-info > span {
      background-color: rgba(0, 0, 0, 0.4);

      padding: 0.3rem;
      padding-bottom: 0.4rem;
    }

    .meta-info > .name {
      border-bottom-left-radius: 4px;
    }

    .meta-info > .created-at {
      padding-right: 1rem;
    }
  }

  .progress-bar {
    position: absolute;
    bottom: 0.3rem;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 100%;
    padding: 0 1rem;
  }

  .progress-bar > .bar {
    background-color: var(--white);
    opacity: 0.8;
    height: 0.2rem;
    border-radius: 1rem;
    animation: progressbar 0s linear;
    animation-fill-mode: forwards; /* prevents reset after finished animation */
    margin: 0 auto;
    transform: translate3d(0, 0, 0);
  }

  @keyframes progressbar {
    from {
      width: 100%;
    }
    to {
      width: 0%
    }
  }

  button.close {
    padding: 2rem;
    margin-left: -1.5rem;
    border-radius: 0;
  }

  button {
    background-color: unset;
  }
</style>
