<script>
  import { contacts, settings, loading, isUnauthorized, isOnline } from '../../stores'
  import { backgroundSync } from '../../functions/background-sync.js'
  import { checkForChangedOpenedStatus } from '../../functions/check-opened-status.js'
  import ViewHeader from '../ViewHeader'
  import ContactExchangeMenu from '../ContactExchangeMenu.svelte'
  import ContactListItem from '../ContactListItem.svelte'
  import InboxServerInlineInfo from '../InboxServerInlineInfo.svelte'
  import UploadProgressBar from '../UploadProgressBar.svelte'
  import { contactsSort } from '../../functions/contacts-sort.js'
  import { closeNotifications } from '../../functions/close-notifications.js'

  let isRefreshing = false
  let refreshKey = 0

  $: sortedContacts = $contacts.sort(contactsSort)
  $: isGlobalLoadingOrRefreshing = isRefreshing || $loading != null
  $: isConnectionError = $isOnline && ($isUnauthorized || $settings?.allInboxServerMap[$settings.inboxServerHost]?.isDown !== false)

  $: {
    if (!isGlobalLoadingOrRefreshing) {
      refreshKey++
    }
  }

  async function refresh () {
    if ($loading != null) {
      return
    }

    isRefreshing = true

    await backgroundSync($settings)

    isRefreshing = false

    checkForChangedOpenedStatus($contacts)
  }

  function onVisibilityChange () {
    if (document.visibilityState === 'visible') {
      closeNotifications()
    }
  }

  refresh()
  closeNotifications()
</script>

<svelte:document on:visibilitychange={onVisibilityChange} />

<ViewHeader showOfflineInfo>
  Inbox

  <span slot="secondary">
    {#if $loading}<span class="loading-label">{$loading}...</span>{/if}

    <button class="-button-reset refresh" on:click={refresh} disabled={isGlobalLoadingOrRefreshing}>
      <svg class="feather-icon {isGlobalLoadingOrRefreshing ? '-rotate-animation' : ''}">
        <use href="feather-sprite.svg#{isGlobalLoadingOrRefreshing ? 'loader' : 'rotate-cw'}"/>
      </svg>
    </button>
  </span>
</ViewHeader>

<ContactExchangeMenu on:change={refresh} />

{#if isConnectionError}
  <InboxServerInlineInfo />
{/if}

<UploadProgressBar />

{#each sortedContacts as contact}
  <ContactListItem {contact} {refreshKey} />
{/each}

{#if sortedContacts.length > 0}
  <p class="tip-contact-details">Tap and hold a friend to view details.</p>
{:else}
  <div class="vertical-empty-space" />

  <p>Nothing here yet. Add friends by accepting or creating a friend invite.</p>
{/if}

{#if sortedContacts.length < 2}
  <div class="vertical-empty-space" />
{/if}

<style>
  .loading-label {
    color: var(--dark-grey);
    font-size: 0.8rem;
    position: relative;
    top: -2px;
    right: -8px;
  }

  button.refresh > .feather-icon {
    width: 1.2rem;
  }

  .tip-contact-details {
    color: var(--dark-grey);
    margin-top: 1.5rem;
    font-size: 0.7rem;
  }
</style>
