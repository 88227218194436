<script>
  import { createEventDispatcher, onMount } from 'svelte'
  import { fly } from 'svelte/transition'

  export let color
  export let isVisible
  export let isStatic = false
  export let showColorPreview = true

  const dispatch = createEventDispatcher()

  let hue
  let lightness
  let isMovingSlider = false

  $: {
    if (lightness === 0) {
      const rgb = Math.round(hue * (255 / 360))
      color = `rgb(${rgb}, ${rgb}, ${rgb})`
    } else if (lightness != null) {
      color = `hsl(${hue}, 90%, ${lightness}%)`
    }
  }

  function close () {
    isVisible = false
    dispatch('close')
  }

  function toggleLightness () {
    if (lightness === 60) {
      lightness = 80
    } else if (lightness === 80) {
      lightness = 40
    } else if (lightness === 40) {
      lightness = 0
    } else if (lightness === 0) {
      lightness = 60
    }
  }

  onMount(() => {
    const matchGroups = /(hsl|rgb)+\((\d+)[,| ]*(\d+)[%|,| ]*(\d+)%*\)/.exec(color)

    if (matchGroups.length === 5) {
      if (matchGroups[1] === 'rgb') {
        lightness = 0
        hue = parseInt(matchGroups[2] * (360 / 255))
      } else if (matchGroups[1] === 'hsl') {
        lightness = parseInt(matchGroups[4])
        hue = parseInt(matchGroups[2])
      }
    }
  })
</script>

{#if isVisible}
  <div class="color-picker" class:-is-static={isStatic} transition:fly={{ y: 25, duration: 200 }}>
    <div class="vertical-empty-space-half" />

    <div class="input-row">
      <button class="-button-reset lightness-toggle" on:click={toggleLightness}>
        <svg class="feather-icon" style:transform={`rotate(${lightness * (360 / 100)}deg)`}>
          <circle r="7" cy="16" cx="16" fill="{lightness === 0 ? '#7d7d7d' : `hsl(0, 90%, ${lightness}%)`}"></circle>
          <circle r="7" cy="16" cx="8" fill="{lightness === 0 ? '#dcdcdc' : `hsl(110, 90%, ${lightness}%)`}"></circle>
          <circle r="7" cy="8" cx="12" fill="{lightness === 0 ? '#333' : `hsl(240, 90%, ${lightness}%)`}"></circle>
        </svg>
      </button>

      <div class="position-relative full-width">
        <input
          class="color-slider"
          class:-is-black-white={lightness === 0}
          style={`--lightness: ${lightness}%; --selected-color: ${color}`}
          type="range"
          min="0"
          max="360"
          on:pointermove={() => { isMovingSlider = true }}
          on:pointerleave={() => { isMovingSlider = false }}
          bind:value={hue}
        >

        {#if isMovingSlider && showColorPreview}
          <div class="color-slider-preview" style:left={`${hue * (100 / 360)}%`} style:color={color} />
        {/if}
      </div>
    </div>

    <div class="vertical-empty-space-half" />

    <button class="-button-reset" on:click={close} style:color={'var(--grey)'}>
      <svg class="feather-icon"><use href="feather-sprite.svg#chevron-down"/></svg>
    </button>
  </div>
{/if}

<style>
  .color-picker {
    color: var(--white);
    background-color: var(--black);
    padding: 1rem;
    padding-bottom: 0;
    text-align: center;
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }

  .color-picker.-is-static {
    position: static;
  }

  .input-row {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-right: 1rem;
  }

  .color-slider {
    -webkit-appearance: none;
    width: 100%;
    margin: 0;
    background: linear-gradient(
      to right,
      hsl(0, 90%, var(--lightness)),
      hsl(30, 90%, var(--lightness)),
      hsl(60, 90%, var(--lightness)),
      hsl(90, 90%, var(--lightness)),
      hsl(120, 90%, var(--lightness)),
      hsl(150, 90%, var(--lightness)),
      hsl(180, 90%, var(--lightness)),
      hsl(210, 90%, var(--lightness)),
      hsl(240, 90%, var(--lightness)),
      hsl(270, 90%, var(--lightness)),
      hsl(300, 90%, var(--lightness)),
      hsl(330, 90%, var(--lightness)),
      hsl(360, 90%, var(--lightness))
    );
    border-radius: 1rem;
    height: 1rem;
    border: 2px solid #fff;
  }

  .color-slider.-is-black-white {
    background: linear-gradient(to left, #fff, #000);
  }

  .color-slider:focus {
    outline: none;
    box-shadow: none;
  }

  .color-slider::-webkit-slider-runnable-track {
    -webkit-appearance: none;
  }

  .color-slider::-webkit-slider-thumb {
    /* NOTE: This definition block needs be separated from the -moz-range-thumb block, because otherwise the styles won't be applied on iOS Safari */
    -webkit-appearance: none;
    border: none;
    height: 1.7rem;
    width: 1.7rem;
    border-radius: 5px;
    background-color: var(--selected-color);
    border: 2px solid var(--white);
  }

  .color-slider::-moz-range-thumb {
    border: none;
    height: 1.7rem;
    width: 1.7rem;
    border-radius: 5px;
    background-color: var(--selected-color);
    border: 2px solid var(--white);
  }

  .color-slider-preview {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: -4rem;
    border-radius: 50%;
    background-color: currentColor;
    transform: translateX(-50%);
  }

  .color-slider-preview:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1rem solid currentColor;
    position: absolute;
    bottom: -0.6rem;
    left: 0.5rem;
  }

  .lightness-toggle > svg {
    transition: transform 200ms ease;
  }
</style>
