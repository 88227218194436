import { isOnline } from '../stores'

export function setupOnlineOfflineEventListener () {
  window.addEventListener('online', function () {
    isOnline.set(true)
  })

  window.addEventListener('offline', function () {
    isOnline.set(false)
  })
}
