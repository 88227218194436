<script>
  import { createEventDispatcher } from 'svelte'
  import { contacts, viewStack, sendToContact } from '../../stores'
  import ViewHeader from '../ViewHeader'
  import { contactsSort } from '../../functions/contacts-sort.js'
  import ContactSelectionItem from '../ContactSelectionItem.svelte'

  export let disabled

  const dispatch = createEventDispatcher()
  const allContacts = []
  let errorMessage = null

  $: selection = allContacts.filter((contact) => contact.selected)

  function setErrorMessage (event) {
    errorMessage = event.detail
  }

  async function confirm () {
    const selectedContacts = selection.map((contact) => contact.contact)

    dispatch('confirm', { selectedContacts })
  }

  function setup () {
    const sortedContacts = $contacts.sort(contactsSort)

    for (const contact of sortedContacts) {
      let selected = false

      if ($sendToContact != null && $sendToContact.id === contact.id) {
        selected = true
      }

      allContacts.push({ selected, contact })
    }
  }

  setup()
</script>

<ViewHeader popWhenClose={false} on:close={() => dispatch('cancel')}>
  Send to...
</ViewHeader>

{#if allContacts.length === 0}
  <p>
    There is no one to send your pic to.
    Go to the <button class="-button-link" on:click={() => viewStack.push('Inbox')}>inbox</button> and invite friends.
  </p>
{/if}

{#if errorMessage}
  <p>{errorMessage}</p>
{/if}

<div class="list">
  {#each allContacts as contact}
    <ContactSelectionItem
      contact={contact.contact}
      bind:selected={contact.selected}
      on:errorMessage={setErrorMessage}
    />
  {/each}
</div>

<footer>
  <slot name="footer"></slot>

  <button class="send -button-primary" disabled={disabled || selection.length === 0} on:click={confirm}>
    {#if selection.length === 0}
      Select at least one friend
    {:else}
      Send to {selection.length} friend{selection.length === 1 ? '' : 's'}

      <svg class="feather-icon">
        <use href="feather-sprite.svg#send" />
      </svg>
    {/if}
  </button>
</footer>

<style>
  .list {
    margin: 0.8rem 1rem 1.5rem 1rem;
  }

  footer {
    text-align: right;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: end;
    position: sticky;
    bottom: 0;
    background-color: var(--white);
  }

  button.send > .feather-icon {
    margin-left: 0.3em;
    transform: rotate(45deg);
  }
</style>
