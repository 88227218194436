<script>
  import { toCanvas } from 'html-to-image'
  import { createEventDispatcher } from 'svelte'
  import ActionBar from './ActionBar'
  import EditorTextLayer from './EditorTextLayer.svelte'
  import EditorDrawingLayer from './EditorDrawingLayer.svelte'
  import EditorBottomActionBar from './EditorBottomActionBar.svelte'

  const dispatch = createEventDispatcher()

  export let hideMenu = false
  export let toolMode = 'MOVE_TEXT' // MOVE_TEXT or MOVE_BASE or DRAW
  export let isDurationSelectVisible = false
  export let showMoveBaseTool = false

  let textContainerElement
  let drawingCanvasElement
  let createTextFn
  let isRatherFresh = true

  function addText () {
    if (toolMode === 'MOVE_BASE' || toolMode === 'DRAW') {
      toolMode = 'MOVE_TEXT'

      dispatch('toolMode', toolMode)
    }

    if (createTextFn) {
      createTextFn()
    }
  }

  function toggleMode (targetMode) {
    if (toolMode === targetMode) {
      toolMode = 'MOVE_TEXT'
    } else {
      toolMode = targetMode
    }

    dispatch('toolMode', toolMode)
  }

  function cancel () {
    if (toolMode !== 'MOVE_TEXT') {
      toolMode = 'MOVE_TEXT'
      dispatch('toolMode', toolMode)
      return
    }

    if (isRatherFresh || window.confirm('Discard everything?')) {
      dispatch('cancel')
    }
  }

  async function submit (event) {
    toolMode = 'MOVE_TEXT'
    dispatch('toolMode', toolMode)

    const canvasWithText = await toCanvas(
      textContainerElement,

      // NOTE: This needs to be set hardcoded for iOS Safari.
      // Because in iOS Safari the "white-space" property is missing in the keys of getComputedStyle.
      // So html-to-image can't properly clone the DOM to the svg foreign-object.
      { style: { whiteSpace: 'pre-wrap' } }
    )

    dispatch('submit', {
      ...event.detail,
      canvasWithText,
      canvasWithDrawing: drawingCanvasElement
    })
  }

  setTimeout(() => {
    isRatherFresh = false
  }, 5 * 1000)
</script>

<div class="media-view-container" class:hide-menu={hideMenu}>
  <slot name="base" {toolMode} />

  <EditorDrawingLayer
    active={toolMode === 'DRAW'}
    bind:canvasElement={drawingCanvasElement}
    on:dragChange={(event) => { hideMenu = event.detail.isDragging }}
    hide={toolMode === 'MOVE_BASE'}
  />

  <EditorTextLayer
    active={toolMode !== 'MOVE_BASE' && toolMode !== 'DRAW'}
    bind:containerElement={textContainerElement}
    on:dragChange={(event) => { hideMenu = event.detail.isDragging }}
    hide={toolMode === 'MOVE_BASE'}
    bind:createTextFn
  />

  <ActionBar top thin>
    <div slot="right" class="editor-action-bar" class:-hide-inactive-tools={toolMode !== 'MOVE_TEXT'}>
      <div class="action-label">
        {#if toolMode === 'MOVE_TEXT'}Text{/if}
        {#if toolMode === 'DRAW'}Draw{/if}
        {#if toolMode === 'MOVE_BASE'}Move &amp; Resize{/if}
      </div>

      {#if showMoveBaseTool}
        <button
          class="-button-reset"
          class:-as-active={toolMode === 'MOVE_BASE'}
          style:transform={'translateX(-8rem)'}
          on:click={() => toggleMode('MOVE_BASE')}
        >
          <svg class="feather-icon -with-glow"><use href="feather-sprite.svg#move"/></svg>
        </button>
      {/if}

      <button
        class="-button-reset"
        class:-as-active={toolMode === 'DRAW'}
        style:transform={'translateX(-4rem)'}
        on:click={() => toggleMode('DRAW')}
      >
        <svg class="feather-icon -with-glow"><use href="feather-sprite.svg#edit-3"/></svg>
      </button>

      <button class="-button-reset position-relative" on:click={addText}>
        <svg class="feather-icon -with-glow">
          <use href="feather-sprite.svg#type"/>
          <line y1="14" y2="14" x1="17" x2="23"></line>
          <line y1="11" y2="17" x1="20" x2="20"></line>
        </svg>
      </button>
    </div>
  </ActionBar>

  <EditorBottomActionBar {isDurationSelectVisible} on:submit={submit} on:cancel={cancel} />
</div>

<style>
  .hide-menu > :global(.action-bar) {
    opacity: 0;
  }

  .editor-action-bar {
    z-index: 1;
    position: relative;
  }

  .editor-action-bar > .action-label {
    position: absolute;
    right: 0;
    top: -0.2rem;
    width: max-content;
    font-size: 0.7rem;
    text-transform: uppercase;
    color: var(--brand-color);
    background-color: var(--white);
    border-radius: 5px;
    padding: 0.3rem 0.5rem;
    opacity: 0;
    transform: translateX(-2rem);
    transition: opacity 60ms ease-out 40ms, transform 60ms ease-out 40ms;
  }

  .editor-action-bar.-hide-inactive-tools > .action-label {
    opacity: 1;
    transform: translateX(-4rem);
  }

  .editor-action-bar > button {
    position: absolute;
    right: 0;
    top: -1rem;
    transition: opacity 120ms ease-out, transform 100ms ease-out;
  }

  .editor-action-bar > button.-as-active {
    background-color: var(--white);
    color: var(--brand-color);
    transform: translateX(0) !important;
  }

  .editor-action-bar.-hide-inactive-tools > button:not(.-as-active) {
    pointer-events: none;
    opacity: 0;
  }
</style>
