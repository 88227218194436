<script>
  import ViewHeader from '../ViewHeader'
</script>

<ViewHeader>What is an inbox server?</ViewHeader>

<p>Every piqchat user is first invited to one inbox server, where they sign up.</p>

<p>Everyone who sends you a pic they send it to your inbox server first, where the app downloads it from.</p>

<p>
  An inbox server is like an extension of your piqchat app in the internet.
  It never goes offline and enables your friends to reach you and send you pics.
</p>

<p>Inbox servers for piqchat can be provided by anyone. They are third-parties and are not related with the piqchat app.</p>

<p>
  Just keep in mind: <strong>You are not dependent on an inbox server.
  If you wish (and you have an invite), you can move to another inbox server while keeping your profile and friends.</strong>
</p>

<p>You can share pics with any friend, regardless of which inbox server you or your friend are on.</p>

<div class="vertical-empty-space-half" />

<details class="content-box">
  <summary>Why all that? I want to know more!</summary>

  <p>
    The inbox servers are processing sensitive metadata of piqchat users.
    Like who sends who pics and how often.
    The inbox servers also cost money.
    That can lead to businesses that don't have your best interests in mind.
  </p>

  <p>
    Also inbox servers can just be turned off by the owner,
    in which case you can still continue to use piqchat if you find another inbox server.
  </p>

  <p>
    Typically apps do not let your choose who you are dependent for that kind of technical infrastructure.
    <strong>piqchat gives you that possibility.</strong>
  </p>

  <p>
    You can set up your own inbox server and make it available to other people. As long as you
    are not scared by a few technicalities. This is also very independent, which - lets be honest - is pretty cool...
  </p>

  <p>
    You can help make piqchat a truly personal, independent, fun and social experience!
    Get started or learn more: <a target="_blank" href="https://github.com/carlreinecken/piqchat-inbox-server">piqchat-inbox-server on github.com</a>.
  </p>
</details>
