<script>
  import { contacts, settings, viewStack, logger } from '../../stores'
  import ViewHeader from '../ViewHeader'
  import PopoverList from '../PopoverList.svelte'
  import InboxServerInlineInfo from '../InboxServerInlineInfo.svelte'
  import { decryptAndMergeContact } from '../../functions/save-encrypted-contact.js'
  import { contactsDeferredUpdate } from '../../functions/contacts-deferred-update.js'

  export let message = null
  export let encryptedContact
  export let secretKey
  export let decidedHandlerFn = () => {} // Only called after confirm or decline, not after a simple close

  let newContact
  let errorMessage
  let existingContact

  async function save () {
    await contacts.set(newContact)

    if (!$settings.inboxServerHost) {
      // TODO: can be removed as soon every pre-beta client has updated
      await settings.set('inboxServerHost', $settings.inboxServer.address)
      await settings.set('allInboxServerMap', { [$settings.inboxServer.address]: { publicKey: $settings.inboxServer.publicKey } })
    }

    decidedHandlerFn()

    if (!existingContact) {
      viewStack.pop()
    } // else: the save function was called from the setup function and should not close the view

    await settings.set('contactsChanged', true)
    await contactsDeferredUpdate()
  }

  function cleanupAndClose () {
    viewStack.pop()
    decidedHandlerFn()
  }

  async function setup () {
    try {
      newContact = await decryptAndMergeContact(encryptedContact, secretKey)

      existingContact = await contacts.get(newContact.id)

      if (existingContact && existingContact.inboxServerHost !== newContact.inboxServerHost) {
        // NOTE: When the contact already exists, a changed inbox server host should always be saved
        save()
      }
    } catch (error) {
      errorMessage = error
      logger.error(error)
    }
  }

  setup()
</script>

<ViewHeader>
  Save {#if !existingContact}new {/if}friend

  <span slot="secondary">
    <PopoverList options={[
      { label: 'Show Identifier', handler: () => window.alert(newContact.id) }
    ]} />
  </span>
</ViewHeader>

<div class="form">
  {#if errorMessage}
    <p class="text-color-error">{errorMessage}</p>

    <div class="vertical-empty-space" />
  {/if}

  {#if newContact && !existingContact}
    {#if message}
      <p>{message}</p>

      <div class="vertical-empty-space" />
    {/if}

    <label class="label" for="displayName">Nickname</label>
    <input class="input" id="displayName" bind:value={newContact.displayName} placeholder="Nickname" />

    <InboxServerInlineInfo contact={newContact} />

    <div class="vertical-empty-space" />
  {/if}

  {#if !existingContact}
    <p class="display-flex justify-content-space-between">
      <button on:click={cleanupAndClose} class="-button-secondary">Decline</button>
      <span style="margin-left: 30px" />
      <button on:click={save} class="save -button-primary" disabled={!newContact?.displayName}>Save</button>
    </p>
  {:else}
    <p>You already have "{existingContact.displayName}" saved as friend.</p>
    {#if existingContact.inboxServerHost !== newContact.inboxServerHost}
      <p>Their inbox server was updated from "<code>{existingContact.inboxServerHost}</code>" to "<code>{newContact.inboxServerHost}</code>".</p>
    {/if}
    <div class="vertical-empty-space-2x" />
    <p><button on:click={cleanupAndClose} class="-button-secondary full-width">Close</button></p>
  {/if}
</div>
