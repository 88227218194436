import { logger } from '../stores'
import { checkForNewParcels, deleteExpiredMediaParcelsOnInboxServer } from './receive-parcels.js'
import { updateProfileBackup } from './profile-backup.js'
import { parseIsoString } from './dume.js'
import { contactsDeferredUpdate } from './contacts-deferred-update.js'

export async function backgroundSync (settings) {
  if (!settings.inboxServerHost || settings.displayName == null) {
    return
  }

  try {
    console.log('Background sync...')

    await deleteExpiredMediaParcelsOnInboxServer()
    await checkForNewParcels()

    if (settings.contactsChanged === true) {
      logger.log('deferred contacts update...')
      contactsDeferredUpdate()
    }

    const oneHour = 60 * 60 * 1000
    if (parseIsoString(settings.lastProfileBackup) < (Date.now() - oneHour)) {
      updateProfileBackup(settings) // no await wanted
    }
  } catch (error) {
    logger.error(error)
  }
}
