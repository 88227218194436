<script>
  import { createEventDispatcher } from 'svelte'
  import { checkIsContactAccepting } from '../functions/check-is-contact-accepting.js'

  const dispatch = createEventDispatcher()

  export let selected
  export let contact

  let isRefreshing = false

  let isAccepting = contact.exchangeCompletedAt != null

  async function onClick () {
    if (!isAccepting) {
      isRefreshing = true
      isAccepting = await checkIsContactAccepting(contact)
      isRefreshing = false

      if (!isAccepting) {
        dispatch('errorMessage', `${contact.displayName} first needs to confirm the friend invite.`)
        return
      }
    }

    selected = !selected
    dispatch('errorMessage', null)
  }
</script>

<button class="-button-reset" on:click={onClick} class:-as-selected={selected}>
  <span>{contact.displayName}</span>

  <svg class="feather-icon">
    {#if isRefreshing}
      <use href="feather-sprite.svg#loader"/>
    {:else if !isAccepting}
      <use href="feather-sprite.svg#clock"/>
    {:else if selected}
      <use href="feather-sprite.svg#check-square"/>
    {:else}
      <use href="feather-sprite.svg#square"/>
    {/if}
  </svg>
</button>

<style>
  button {
    display: inline-block;
    padding: 0.5rem;
    border: 2px solid var(--grey);
    border-radius: var(--content-box-border-radius);
    margin-right: 0.3rem;
    margin-bottom: 0.6rem;
  }

  button > span {
    overflow: hidden;
    white-space: nowrap;
    max-width: 7rem;
    text-overflow: ellipsis;
    line-height: 1.2;
    vertical-align: middle;
    display: inline-block;
  }

  button > .feather-icon {
    margin-left: 0.2rem;
    width: 1rem;
    height: 1rem;
    stroke-width: 3px;
    vertical-align: middle;
    display: inline-block;
    color: var(--grey);
  }

  button.-as-selected {
    border: 2px solid var(--brand-color);
  }

  button.-as-selected > .feather-icon {
    color: var(--brand-color);
  }
</style>
