import { createInboxApiUrl } from './create-inbox-api-url.js'
import { createAuthorizationHeader } from './create-authorization-header.js'
import { trackInboxServerReachability, trackInboxServerUnreachability } from './track-inbox-server-reachability.js'
import { trackUnauthorizedResponse } from './track-unauthorized-response.js'
import { normalizeErrorHandling } from './normalize-error-handling.js'

export function contactExchangeRequests (getApiStateFn) {
  return {
    async create (encryptedContact) {
      const apiState = getApiStateFn()
      const headers = createAuthorizationHeader(apiState)
      headers['Content-Type'] = 'application/json'

      return fetch(createInboxApiUrl(apiState, '/contact-exchange'), {
        method: 'POST',
        headers,
        body: JSON.stringify({ encryptedContact })
      })
        .then(trackUnauthorizedResponse)
        .then(trackInboxServerReachability(apiState))
        .catch(trackInboxServerUnreachability(apiState))
        .then(normalizeErrorHandling)
    },

    async get (oneTimeToken) {
      const apiState = getApiStateFn()
      const headers = createAuthorizationHeader(apiState)

      return fetch(createInboxApiUrl(apiState, `/contact-exchange/${oneTimeToken}`), { headers })
        .then(trackInboxServerReachability(apiState))
        .catch(trackInboxServerUnreachability(apiState))
        .then(normalizeErrorHandling)
    },

    async revoke (oneTimeToken) {
      const apiState = getApiStateFn()

      return fetch(createInboxApiUrl(apiState, `/contact-exchange/${oneTimeToken}`), {
        method: 'DELETE',
        headers: createAuthorizationHeader(apiState)
      })
        .then(trackUnauthorizedResponse)
        .then(trackInboxServerReachability(apiState))
        .catch(trackInboxServerUnreachability(apiState))
        .then(normalizeErrorHandling)
    },

    async accept (url, encryptedContact, userUuid) {
      return fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ encryptedContact, userUuid })
      })
        .then(normalizeErrorHandling)
    }
  }
}
