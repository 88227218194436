<script>
  export let withWarning = false
</script>

<p class="step display-flex">
  <slot name="icon">
    <svg class="feather-icon" class:background-color-warning={withWarning} class:background-color-success={!withWarning}>
      <use href="feather-sprite.svg#{withWarning ? 'alert-circle' : 'check-circle'}" />
    </svg>
  </slot>

  <span><slot /></span>
</p>

<style>
  .step :global(.feather-icon) {
    border-radius: 100%;
    margin-right: 0.8rem;
    flex-shrink: 0;
    padding: 2px;
  }
</style>
