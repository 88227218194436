export async function installWithDeferredPrompt (deferredPrompt) {
  deferredPrompt.prompt()

  const { outcome } = await deferredPrompt.userChoice

  return outcome === 'accepted' // alternative value: 'dismissed'
}

export async function deferInstallPrompt () {
  return new Promise((resolve) => {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault()
      resolve(event)
    })
  })
}
