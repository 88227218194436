import { settings, unopenedParcels, contacts } from '../stores'
import { clearQueue } from './upload-queue.js'
import { deleteServiceWorkerKeyValueStore } from './service-worker-key-value-store.js'
import { inboxApi } from '../api'

export async function deleteLocalData () {
  await inboxApi.parcelRequests.clearAllFromCache()
  await unopenedParcels.clearAll()
  await contacts.clearAll()
  await clearQueue()
  await settings.clearAll()
  deleteServiceWorkerKeyValueStore()
}
