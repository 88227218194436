import { writable } from 'svelte/store'

export function createLookupStore () {
  const { subscribe, set } = writable()

  return {
    subscribe,

    async view (contactId, displayName, publicKey) {
      set({ id: contactId, displayName, publicKey })
    },

    clear () {
      set()
    }
  }
}
