<script>
  export let statistics

  const formatter = Intl.NumberFormat('en', { notation: 'compact' })

  const items = [
    { header: 'pics received last 7 days', key: 'mediaParcelsReceivedLast7Days' },
    { header: 'users active last 7 days', key: 'userActiveLast7Days' },
    { header: 'users active last 30 days', key: 'userActiveLast30Days' },
    { header: 'users total', key: 'userTotal' },
    { header: 'unopened pics total', key: 'mediaParcelsWaiting' }
  ]
</script>

<div class="statistics">
  {#each items as item}
    {#if statistics[item.key] != null}
      <div class="item">
        <span>{formatter.format(statistics[item.key])}</span>
        <header>{item.header}</header>
      </div>
    {/if}
  {/each}
</div>

<style>
  .statistics {
    display: flex;
    margin: 0.3rem 0 1rem 0;
    padding: 0 1rem;
    gap: 0.7rem;
    overflow: scroll;
  }

  .statistics > .item {
    padding: 0.8rem 0.8rem 0.2rem;
    background-color: var(--light-grey);
    border-radius: var(--content-box-border-radius);
    flex-shrink: 0;
    flex-basis: 7rem;
  }

  .statistics > .item > header {
    font-weight: bold;
    font-size: 0.7em;
    text-transform: uppercase;
    margin: 0.4rem 0;
  }
</style>
