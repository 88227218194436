import { writable } from 'svelte/store'
import { database } from './indexeddb'
import { encodeBase64 } from 'tweetnacl-util'

export function createSettingsStore () {
  const { subscribe, update } = writable({})

  async function load () {
    const persistedSettings = await database.getAll('settings')

    update((mapInStore) => {
      for (const setting of persistedSettings) {
        mapInStore[setting.key] = setting.value
      }

      return mapInStore
    })
  }

  return {
    subscribe,
    load,

    async set (key, value) {
      await database.put('settings', { key, value })
      await load()
    },

    async getUserUuid () {
      const keyvalue = await database.get('settings', 'publicKey')

      if (keyvalue.value) {
        return encodeBase64(keyvalue.value)
      }
    },

    async hasProfile () {
      const expectedKeys = ['privateKey', 'publicKey', 'displayName']
      const settings = await database.getAll('settings')

      for (const key of expectedKeys) {
        if (settings.find((entry) => entry.key === key) == null) {
          return false
        }
      }

      return true
    },

    clearAll () {
      // NOTE: After this a page reload is required!
      return database.clear('settings')
    }
  }
}
