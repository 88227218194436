<script>
  import { box, randomBytes } from 'tweetnacl'
  import { encodeBase64 } from 'tweetnacl-util'
  import { settings, contacts, viewStack } from '../../stores'
  import { inboxApi } from '../../api'
  import ViewHeader from '../ViewHeader'
  import { PARCEL_TYPES } from '../../constants.js'
  import { packUint8Array } from '../../functions/pack-uint8array.js'
  import { createEncryptedContact } from '../../functions/contact-exchange-coder.js'

  let selectedContactA
  let selectedContactB
  let isLoading = false

  const privateKey = $settings.privateKey

  async function send () {
    isLoading = true

    const parcelA = contactToParcel(selectedContactA, selectedContactB)
    const parcelB = contactToParcel(selectedContactB, selectedContactA)

    try {
      await inboxApi.parcelRequests.upload(parcelA, selectedContactB.inboxServerHost)
      await inboxApi.parcelRequests.upload(parcelB, selectedContactA.inboxServerHost)

      viewStack.pop()
    } catch {
      alert('An error occurred while trying to send the suggestions.')
    }

    isLoading = false
  }

  function contactToParcel (contact, recipient) {
    // NOTE: The contact is encrypted with its own secret key (instead of the contacts publicKey)
    // because I wanted to reuse the createEncryptedContact function.
    const { encryptedContact, secretKey } = createEncryptedContact(
      contact.publicKey,
      contact.displayName,
      contact.inboxServerHost
    )

    const nonce = randomBytes(box.nonceLength)
    const encryptedSecretKey = box(secretKey, nonce, recipient.publicKey, privateKey)
    const encryptedSecretKeyAndNonce = packUint8Array(encryptedSecretKey, [nonce])

    const content = { secretKey: encodeBase64(encryptedSecretKeyAndNonce), contact: encryptedContact }

    return {
      recipientContents: [{ recipientId: recipient.id, content: JSON.stringify(content) }],
      type: PARCEL_TYPES.CONTACT
    }
  }
</script>

<ViewHeader>Bring friends together</ViewHeader>

<p>
  You have two friends who should be friends with each other?
  Here, you can suggest them each other's contact details.
</p>

<div class="vertical-empty-space" />

<p class="display-flex justify-content-space-between">
  <select bind:value={selectedContactA}>
    <option disabled selected value={null}>Select friend A</option>

    {#each $contacts as contact}
      {#if contact.exchangeCompletedAt != null}
        <option value={contact} disabled={selectedContactB?.id === contact.id}>{contact.displayName}</option>
      {/if}
    {/each}
  </select>

  <select bind:value={selectedContactB}>
    <option disabled selected value={null}>Select friend B</option>

    {#each $contacts as contact}
      {#if contact.exchangeCompletedAt != null}
        <option value={contact} disabled={selectedContactA?.id === contact.id}>{contact.displayName}</option>
      {/if}
    {/each}
  </select>
</p>

<div class="vertical-empty-space" />

<small>
  <p>You are about to share personal data from your friends, please use this feature responsibly.</p>
</small>

<p>
  <button
    class="-button-primary full-width"
    disabled={isLoading || !selectedContactA || !selectedContactB}
    on:click={send}
  >
    {#if isLoading}Sending...{:else}Match ✨{/if}
  </button>
</p>

<style>
</style>
