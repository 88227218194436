<script>
  import { contacts, settings, viewStack, sendToContact, loading } from '../../stores'
  import ViewHeader from '../ViewHeader'
  import PopoverList from '../PopoverList.svelte'
  import ContactPointProgress from '../ContactPointProgress'
  import InboxServerInlineInfo from '../InboxServerInlineInfo.svelte'
  import { removeItemsByRecipient, flushQueue, getQueueItemIdsByRecipient } from '../../functions/upload-queue.js'
  import { dumeToNow, parseIsoString } from '../../functions/dume'
  import { inboxApi } from '../../api'
  import { LAST_SEEN_LABELS } from '../../constants.js'
  import { checkIsContactAccepting } from '../../functions/check-is-contact-accepting.js'

  export let contact

  let newDisplayName
  let displayName
  let lastSeenLabel = '...'
  let countWaitingMediaParcels
  let isAccepting = false
  let isDisplayNameInputVisible = false
  let displayNameInputElement
  let queueItemIds = []

  $: isDisplayNameSaveable = newDisplayName?.trim() !== '' && newDisplayName !== displayName

  const hasOpenedLastSent = parseIsoString(contact.lastSuccessfullySentAt) < parseIsoString(contact.lastOpenedAt)

  async function saveDisplayName () {
    await contacts.set({ ...contact, displayName: newDisplayName })
    await settings.set('contactsChanged', true)
    displayName = newDisplayName
    isDisplayNameInputVisible = false
  }

  async function remove () {
    let message = `Do you really want to delete "${displayName}"? Both of you will no longer be able to send each other stuff.`

    if (queueItemIds.length > 0) {
      message += `\n\nAll unsent pics (${queueItemIds.length}) get also deleted.`
    }

    const decision = window.confirm(message)

    if (decision) {
      await contacts.delete(contact.id)
      removeItemsByRecipient(contact.id)
      viewStack.pop()
      await settings.set('contactsChanged', true)
    }
  }

  function relativeTimeOf (timestamp) {
    if (!timestamp) {
      return '...'
    }

    return dumeToNow(new Date(timestamp))
  }

  async function loadRealtimeInfo () {
    const response = await inboxApi.parcelRequests.getContactStatus(contact.id, contact.inboxServerHost)
    const json = await response.json()
    countWaitingMediaParcels = json.count
    lastSeenLabel = LAST_SEEN_LABELS[json.lastSeen]
  }

  function openCamera () {
    $sendToContact = contact
    viewStack.clear()
  }

  function showEditNameInput () {
    isDisplayNameInputVisible = true
    setTimeout(() => displayNameInputElement.focus(), 0)
  }

  async function deleteParcelsInQueue () {
    await removeItemsByRecipient(contact.id)

    contact.error = null
    await contacts.set({ ...contact })

    countItemsInQueue()
  }

  async function tryFlushQueue () {
    viewStack.pop()
    flushQueue(queueItemIds)
  }

  async function setup () {
    newDisplayName = contact.displayName
    displayName = contact.displayName

    loadRealtimeInfo()
    isAccepting = await checkIsContactAccepting(contact)

    countItemsInQueue()
  }

  async function countItemsInQueue () {
    const idsByRecipient = await getQueueItemIdsByRecipient()
    queueItemIds = idsByRecipient.get(contact.id) ?? []
  }

  setup()
</script>

<ViewHeader>
  {displayName}

  <h3>Last seen {lastSeenLabel}</h3>

  <span slot="secondary">
    <PopoverList options={[
      { label: 'Show Identifier', handler: () => window.alert(contact.id) },
      { label: 'Debug Info', handler: () => viewStack.push('Debug', { json: contact }) }
    ]} />
  </span>
</ViewHeader>

<div class="vertical-empty-space" />

<div class="form display-none" class:-oneline={isDisplayNameInputVisible}>
  <label for="displayName" class="label">Edit Nickname</label>
  <input id="displayName" bind:this={displayNameInputElement} class="input" bind:value={newDisplayName} />

  <div class="input flex-grow-0">
    <button class="-button-secondary -button-small -button-only-icon" on:click={() => { isDisplayNameInputVisible = false }}>
      <svg class="feather-icon"><use href="feather-sprite.svg#x" /></svg>
    </button>
    {#if isDisplayNameSaveable}
      <button class="-button-primary -button-small" on:click={saveDisplayName}>Save</button>
    {/if}
  </div>
</div>

<div class="vertical-empty-space display-none" class:display-block={isDisplayNameInputVisible} />

{#if queueItemIds.length > 0}
  {@const hasError = contact.error}

  <div
    class="content-box"
    class:-has-error={hasError}
    class:-has-warning={!hasError}
  >
    {#if contact.error}
      <p>
        <strong class="text-color-error">There was a problem the last time you sent something to {displayName}.</strong>

        {#if contact.error.type === 'FORBIDDEN'}
          Make sure that they have added you as friend and that they are signed up to the inbox server <code>{contact.inboxServerHost}</code>.
        {:else if contact.error.type === 'NETWORK_ERROR'}
          Their inbox server couldn't be reached.
        {:else}
          Unknown error.
        {/if}
      </p>
    {/if}

    <p>You have {queueItemIds.length} unsent pic{#if queueItemIds.length !== 1}s{/if} for {displayName}.</p>

    <div class="vertical-empty-space-half" />

    <p>
      <button class="-button-secondary -button-small full-width" on:click={deleteParcelsInQueue}>
        Delete unsent pics
        <svg class="feather-icon"><use href="feather-sprite.svg#trash-2" /></svg>
      </button>
    </p>

    <p>
      <button class="-button-secondary -button-small full-width" on:click={tryFlushQueue} disabled={$loading != null}>
        Try to send again
        <svg class="feather-icon"><use href="feather-sprite.svg#rotate-cw" /></svg>
      </button>
    </p>
  </div>
{/if}

{#if !isAccepting}
  <div class="content-box -has-warning">
    <p>
      <strong>Not ready yet.</strong>
      Waiting for them to confirm the friend invitation...
    </p>
  </div>
{/if}

{#if contact.points}
  <ContactPointProgress contactPoints={contact.points} />
{/if}

{#if isAccepting}
  <p>
    <button class="-button-primary full-width" on:click={openCamera}>
      Open camera
      <svg class="feather-icon"><use href="feather-sprite.svg#camera"/></svg>
    </button>
  </p>
{/if}

<div class="vertical-empty-space-half" />

<p>Last received {relativeTimeOf(contact.lastReceivedAt)} ago</p>
<p>
  Last sent {relativeTimeOf(contact.lastSuccessfullySentAt)} ago
  {#if hasOpenedLastSent}(Opened){/if}
  {#if countWaitingMediaParcels > 0}({countWaitingMediaParcels} unopened){/if}
</p>
<p>
  {#if contact.exchangeCompletedAt}
    Added {parseIsoString(contact.exchangeCompletedAt).toLocaleDateString()}
  {/if}
</p>

<div class="vertical-empty-space-half" />

<InboxServerInlineInfo {contact} />

<p class="display-flex justify-content-space-between" style="column-gap: 0.5rem;">
  <button class="-button-secondary -button-small" on:click={showEditNameInput}>
    Edit nickname

    <svg class="feather-icon"><use href="feather-sprite.svg#edit"/></svg>
  </button>

  <button class="-button-secondary -button-small -button-only-icon" on:click={remove}>
    <svg class="feather-icon"><use href="feather-sprite.svg#trash-2"/></svg>
  </button>
</p>
