<script>
  export let content

  let showAsCopied = false
  let timeout = null

  async function onClick () {
    try {
      await navigator.clipboard.writeText(content)

      clearTimeout(timeout)

      showAsCopied = true

      timeout = setTimeout(() => {
        showAsCopied = false
      }, 2000)
    } catch {
      window.alert(`Please copy manually:\n\n${content}`)
    }
  }

  $: disabled = $$props.disabled || !navigator.clipboard.writeText
</script>

<button {...$$props} on:click={onClick} disabled={disabled}>
  {#if showAsCopied}
    Copied
  {:else}
    <slot>Copy</slot>
  {/if}

  <svg class="feather-icon">
    <use href="feather-sprite.svg#{showAsCopied ? 'check' : 'copy' }" />
  </svg>
</button>

<style>
</style>
