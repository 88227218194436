<script>
  import { MEDIA_DURATION_OPTIONS, MEDIA_DURATION_OPTIONS_LABELS } from '../constants.js'
  import { fly, fade } from 'svelte/transition'

  export let selectedDuration
  export let isVisible

  function selectDuration (option) {
    selectedDuration = option
    isVisible = false
  }
</script>

{#if isVisible}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    class="duration-options-background"
    on:click={() => { isVisible = false }}
  />

  <div class="duration-options">
    {#each MEDIA_DURATION_OPTIONS as option, index}
      <button
        class="-button-reset duration-option"
        class:-is-selected={selectedDuration === option}
        on:click={() => selectDuration(option)}
        in:fly={{ y: 25, duration: 200, delay: index * 30 }}
        out:fade={{ duration: 100 }}
      >
        <span class="-with-glow">
          {#if option === 1}
            {MEDIA_DURATION_OPTIONS_LABELS[0]}
            <svg class="feather-icon"><use href="feather-sprite.svg#eye"/></svg>
          {:else if option === 4}
            {MEDIA_DURATION_OPTIONS_LABELS[1]}
            <svg class="feather-icon"><use href="feather-sprite.svg#wind"/></svg>
          {:else if option === 9}
            {MEDIA_DURATION_OPTIONS_LABELS[2]}
            <small>9s</small>
          {:else if option === null}
            {MEDIA_DURATION_OPTIONS_LABELS[3]}
            ∞
          {/if}
        </span>
      </button>
    {/each}
  </div>
{/if}

<style>
  .duration-options {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-flow: column;
    gap: 0.5rem;

    /* Width, right, bottom and padding-right are together adjusted, so the options align with button where the options are opened */
    width: 13rem;
    right: 0;
    bottom: 5rem;
    padding-right: 5rem;
  }

  .duration-options-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  .duration-option {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 1rem;
    color: var(--white);
    font-weight: bold;
    padding-left: 1rem;
    text-align: left;
  }

  .duration-option small {
    font-weight: normal;
  }

  .duration-option .feather-icon {
    width: 0.9rem;
    height: 0.9rem;
    position: relative;
    top: 2px;
  }

  .duration-option.-is-selected {
    background-color: var(--white);
    color: var(--black);
  }

  .duration-option.-is-selected > span {
    filter: none;
  }
</style>
