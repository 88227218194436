/**
 * Can join one flexible sized uint8array with multiple fixed sized ones
 *
 * @param {Uint8Array} flexibleArray - is put at the end of the result
 * @param {Uint8Array[]} fixedArrays
 */
export function packUint8Array (flexibleArray, fixedArrays) {
  const sizesOfFixedArrays = []

  for (const fixedArray of fixedArrays) {
    sizesOfFixedArrays.push(fixedArray.length)
  }

  const totalSizeOfFixedArrays = sizesOfFixedArrays.reduce((r, x) => r + x, 0)
  const result = new Uint8Array(totalSizeOfFixedArrays + flexibleArray.length)

  let sizeCursor = 0

  for (let i = 0; i < fixedArrays.length; i++) {
    result.set(fixedArrays[i], sizeCursor)
    sizeCursor += sizesOfFixedArrays[i]
  }

  result.set(flexibleArray, totalSizeOfFixedArrays)

  return result
}

/**
 * Can split multiple fixed sized uint8arrays from one flexible sized one
 *
 * @param {number[]} sizesOfFixedArrays
 */
export function unpackUint8Array (joinedArray, sizesOfFixedArrays) {
  const fixedArrays = []
  let sizeCursor = 0

  for (let i = 0; i < sizesOfFixedArrays.length; i++) {
    fixedArrays.push(joinedArray.slice(sizeCursor, sizeCursor + sizesOfFixedArrays[i]))
    sizeCursor += sizesOfFixedArrays[i]
  }

  const flexibleArray = joinedArray.slice(sizeCursor, joinedArray.length)

  return {
    flexibleArray,
    fixedArrays
  }
}
