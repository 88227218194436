import { box, randomBytes } from 'tweetnacl'
import { encodeBase64, decodeBase64 } from 'tweetnacl-util'
import { get } from 'svelte/store'
import { contacts as contactsStore, settings as settingsStore } from '../stores'
import { database } from '../stores/indexeddb'
import { inboxApi } from '../api'
import { packUint8Array, unpackUint8Array } from './pack-uint8array.js'

const RECOVERY_STRING_START = 'SECRET'
const RECOVERY_STRING_VERSION_BYTE_LENGTH = 1 // Don't change, as this will break clients to recover with older recovery strings
const RECOVERY_STRING_VERSION = 0

export async function updateProfileBackup (settings) {
  const contacts = get(contactsStore)

  const backup = {
    displayName: settings.displayName,
    contacts: contacts.map(mapContact)
  }

  const jsonBytes = new TextEncoder('utf-8').encode(JSON.stringify(backup))
  const nonce = randomBytes(box.nonceLength)

  const encrypted = box(
    jsonBytes,
    nonce,
    settings.publicKey,
    settings.privateKey
  )

  const content = encodeBase64(packUint8Array(encrypted, [nonce]))

  await inboxApi.accountRequests.updateProfileBackup(content)

  await settingsStore.set('lastProfileBackup', (new Date()).toISOString())
}

export async function applyProfileBackup (recoveryString) {
  const { publicKey, privateKey, inboxServerHost } = parseRecoveryString(recoveryString)
  let encryptedBase64

  try {
    const { publicKey: apiPublicKey } = await inboxApi.metaRequests.getServerInfo(inboxServerHost)
    const inboxServerPublicKey = decodeBase64(apiPublicKey)

    const response = await inboxApi.accountRequests.getProfileBackup(publicKey, privateKey, inboxServerHost, inboxServerPublicKey)
    encryptedBase64 = (await response.json()).backup
  } catch (error) {
    throw new Error('REQUEST_INBOX_SERVER_FAILED', { cause: error })
  }

  const encryptedBytes = decodeBase64(encryptedBase64)
  const { flexibleArray: encrypted, fixedArrays } = unpackUint8Array(encryptedBytes, [box.nonceLength])

  const backupBytes = box.open(
    encrypted,
    fixedArrays[0],
    publicKey,
    privateKey
  )

  const backup = JSON.parse(new TextDecoder().decode(backupBytes))

  const tx = database.transaction(['contacts', 'settings'], 'readwrite')

  const settingsStore = tx.objectStore('settings')

  await settingsStore.put({ key: 'publicKey', value: publicKey })
  await settingsStore.put({ key: 'privateKey', value: privateKey })
  await settingsStore.put({ key: 'displayName', value: backup.displayName })
  await settingsStore.put({ key: 'inboxServerHost', value: inboxServerHost })
  await settingsStore.put({ key: 'contactsChanged', value: true })

  for (const contact of backup.contacts) {
    await tx.objectStore('contacts').put(mapContact(contact))
  }

  await tx.done

  const hosts = new Set(backup.contacts.map((contact) => contact.inboxServerHost))
  hosts.delete(inboxServerHost) // the public key was already set before fetching the backup
  await Promise.allSettled([...hosts].map((host) => inboxApi.metaRequests.getServerInfo(host)))
}

export function createRecoveryString (settings) {
  const version = new Uint8Array(RECOVERY_STRING_VERSION_BYTE_LENGTH)
  version[0] = RECOVERY_STRING_VERSION

  const inboxServerHost = new TextEncoder('utf-8').encode(JSON.stringify(settings.inboxServerHost))

  const packed = packUint8Array(inboxServerHost, [
    version,
    settings.publicKey,
    settings.privateKey
  ])

  return RECOVERY_STRING_START + encodeBase64(packed)
}

export function parseRecoveryString (recoveryString) {
  if (recoveryString.slice(0, 6) !== RECOVERY_STRING_START) {
    throw new Error('Recovery key has invalid format')
  }

  recoveryString = recoveryString.substr(RECOVERY_STRING_START.length)

  try {
    const { flexibleArray: inboxServerHostBytes, fixedArrays } = unpackUint8Array(
      decodeBase64(recoveryString),
      [RECOVERY_STRING_VERSION_BYTE_LENGTH, box.publicKeyLength, box.secretKeyLength]
    )

    const version = fixedArrays[0][0]

    if (version !== RECOVERY_STRING_VERSION) {
      throw new Error('UNKNOWN_VERSION', { cause: version })
    }

    const inboxServerHost = JSON.parse(new TextDecoder().decode(inboxServerHostBytes))

    return {
      publicKey: fixedArrays[1],
      privateKey: fixedArrays[2],
      inboxServerHost
    }
  } catch (error) {
    if (error.message === 'UNKNOWN_VERSION') {
      throw new Error(`This app can't process the recovery key with the version ${error.cause}. Try to update the app.`)
    }

    console.error(error)
    throw new Error('Recovery key has invalid format')
  }
}

function mapContact (contact) {
  const toggleUint8Array = (value) => value instanceof Uint8Array ? encodeBase64(value) : decodeBase64(value)

  return {
    id: contact.id,
    displayName: contact.displayName,
    publicKey: toggleUint8Array(contact.publicKey),
    inboxServerHost: contact.inboxServerHost,
    lastReceivedAt: contact.lastReceivedAt,
    lastOpenedAt: contact.lastOpenedAt,
    lastSentAt: contact.lastSentAt,
    lastSuccessfullySentAt: contact.lastSuccessfullySentAt,
    exchangeCompletedAt: contact.exchangeCompletedAt,
    points: {
      count: parseInt(contact.points?.count ?? 0),
      highestCount: parseInt(contact.points?.highestCount ?? 0),
      updatedAt: contact.points?.updatedAt ?? null,
      isTheirTurn: contact.points ? !!contact.points.isTheirTurn : null
    }
  }
}
