import { parseIsoString } from './dume.js'
import { logger } from '../stores'
import { LEVEL_EMOJIS } from '../constants.js'

const MAX_POINTS_PER_UPDATE = 24

export function createPointsOfContact () {
  return {
    isTheirTurn: null,
    updatedAt: null,
    count: 0,
    highestCount: 0
  }
}

export function updateLevelPointsCausedByContact (contactPointsFromStorage, pointsSendFromContact) {
  if (!pointsSendFromContact || contactPointsFromStorage.isTheirTurn === false) {
    return null
  }

  if (parseIsoString(contactPointsFromStorage.updatedAt) > parseIsoString(pointsSendFromContact.updatedAt)) {
    logger.log(`Ignored contact points update by them because local updatedAt is sooner. Local updatedAt: ${contactPointsFromStorage.updatedAt} and their updatedAt: ${pointsSendFromContact.updatedAt}.`)
    return null
  }

  return {
    count: pointsSendFromContact.count,
    highestCount: Math.max(contactPointsFromStorage.highestCount ?? 0, pointsSendFromContact.count),
    updatedAt: pointsSendFromContact.updatedAt,
    isTheirTurn: false
  }
}

export function updateLevelPointsCausedByMe (points) {
  if (points.isTheirTurn === true) {
    return points
  }

  const datetimeNow = new Date()

  if (datetimeNow <= parseIsoString(points.updatedAt)) {
    return points
  }

  const newCount = calculate(points.count, points.updatedAt, datetimeNow)

  return {
    count: newCount,
    highestCount: Math.max(points.highestCount ?? 0, newCount),
    updatedAt: datetimeNow.toISOString(),
    isTheirTurn: true
  }
}

export function calculateLevelFromPointCount (pointsCount) {
  return Math.floor(Math.sqrt(pointsCount / MAX_POINTS_PER_UPDATE) * 0.8)
}

export function getLevelProperties (points) {
  const predictedPoints = predictPoints(points)
  const level = calculateLevelFromPointCount(predictedPoints)
  const isVisible = level > 0

  const {
    progressPercentage,
    progressDaysToNextLevel
  } = calculateLevelProgress(level, predictedPoints)

  const hoursSinceLastUpdate = hoursBetween(new Date(), points.updatedAt)
  const hoursUntilDecrease = hoursSinceLastUpdate < MAX_POINTS_PER_UPDATE
    ? Math.floor(MAX_POINTS_PER_UPDATE - hoursSinceLastUpdate)
    : null

  return {
    level,
    label: isVisible ? LEVEL_EMOJIS[level - 1] : null,
    previousLabel: level > 1 ? LEVEL_EMOJIS[level - 2] : null,
    nextLabel: LEVEL_EMOJIS[level],
    isVisible,
    progressPercentage,
    hoursUntilDecrease,
    progressDaysToNextLevel
  }
}

export function predictPoints (points) {
  const hoursSinceLastUpdate = Math.floor(hoursBetween(new Date(), points.updatedAt))
  const subtractPoints = calculateSubtraction(hoursSinceLastUpdate)

  return Math.max(0, points.count - subtractPoints)
}

function calculate (count, updatedAt, date) {
  const hoursSinceLastUpdate = updatedAt == null ? 0 : Math.floor(hoursBetween(date, updatedAt))
  const addedPoints = Math.min(MAX_POINTS_PER_UPDATE, hoursSinceLastUpdate)

  let subtractPoints = 0

  if (count > 0) {
    subtractPoints = calculateSubtraction(hoursSinceLastUpdate)
  }

  return Math.max(0, count + addedPoints - subtractPoints)
}

function calculateSubtraction (hoursSinceLastUpdate) {
  const daysSinceLastUpdate = Math.max(0, Math.floor((hoursSinceLastUpdate) / 24))
  const SUBTRACT_FACTOR = 12
  return ((daysSinceLastUpdate * (daysSinceLastUpdate + 1)) / 2) * SUBTRACT_FACTOR
}

function calculateLevelProgress (level, predictedPoints) {
  const pointsRequiredForCurrentLevel = MAX_POINTS_PER_UPDATE * Math.pow(level / 0.8, 2)
  const pointsRequiredForNextLevel = MAX_POINTS_PER_UPDATE * Math.pow((level + 1) / 0.8, 2)
  const levelPointRange = pointsRequiredForNextLevel - pointsRequiredForCurrentLevel
  const progressPercentage = ((predictedPoints - pointsRequiredForCurrentLevel) / levelPointRange * 100).toFixed(2)
  const progressDaysToNextLevel = Math.round((pointsRequiredForNextLevel - predictedPoints) / 24)

  return {
    progressPercentage,
    progressDaysToNextLevel
  }
}

function hoursBetween (dateA, dateB) {
  return Math.abs(parseIsoString(dateA) - parseIsoString(dateB)) / 1000 / 60 / 60
}
