import { settings } from '../stores'

export function trackInboxServerReachability (apiState) {
  return function (response) {
    try {
      if (response.status >= 200 || response.status < 500) {
        const inboxServerHost = apiState.allInboxServerMap[apiState.inboxServerHost]

        if (inboxServerHost) {
          // TODO: When a lot of request at the same time, that call this, does this cause problems?
          inboxServerHost.lastConnectedAt = (new Date()).toISOString()
          inboxServerHost.isDown = false

          settings.set('allInboxServerMap', apiState.allInboxServerMap)
        }
      }
    } catch (error) {
      console.error('trackInboxServerReachability', error.message)
    }

    return response
  }
}

export function trackInboxServerUnreachability (apiState) {
  return function (error) {
    try {
      const inboxServerHost = apiState.allInboxServerMap[apiState.inboxServerHost]

      if (inboxServerHost && inboxServerHost.isDown !== true && window.navigator.onLine) {
        // NOTE: Only if the device is "offline" it should mark an inbox as unreachable.
        apiState.allInboxServerMap[apiState.inboxServerHost].isDown = true

        settings.set('allInboxServerMap', apiState.allInboxServerMap)

        error.cause = 'INBOX_SERVER_UNREACHABLE'
      }
    } catch (error) {
      console.error('trackInboxServerUnreachability', error.message)
    }

    return Promise.reject(error)
  }
}
