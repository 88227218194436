import { box, secretbox, randomBytes } from 'tweetnacl'
import { encodeBase64, decodeBase64 } from 'tweetnacl-util'
import { addMetaObjectToUint8Array, splitMetaObjectFromUint8Array } from './meta-object-with-uint8array'
import { packUint8Array, unpackUint8Array } from '../functions/pack-uint8array.js'
import { database } from '../stores/indexeddb'

export async function encryptAttachment (blob, meta, recipients) {
  const mediaArrayBuffer = await blob.arrayBuffer()
  const privateKey = (await database.get('settings', 'privateKey')).value
  const attachmentSecretKey = randomBytes(secretbox.keyLength)

  const secretKeys = {}

  for (const recipient of recipients) {
    const nonce = randomBytes(box.nonceLength)

    const encryptedSecretKey = box(
      attachmentSecretKey,
      nonce,
      recipient.publicKey,
      privateKey
    )

    secretKeys[recipient.id] = encodeBase64(packUint8Array(encryptedSecretKey, [nonce]))
  }

  const nonce = randomBytes(secretbox.nonceLength)
  const secretContent = addMetaObjectToUint8Array(meta, new Uint8Array(mediaArrayBuffer))
  const encryptedMedia = secretbox(secretContent, nonce, attachmentSecretKey)

  const attachment = packUint8Array(encryptedMedia, [nonce])

  return { secretKeys, attachment }
}

export async function decryptAttachment (attachmentAndNonce, secretKeyAndNonce, senderPublicKey) {
  const privateKey = (await database.get('settings', 'privateKey')).value

  const unpackedSecretKeyAndNonce = unpackUint8Array(decodeBase64(secretKeyAndNonce), [box.nonceLength])

  const secretKey = box.open(
    unpackedSecretKeyAndNonce.flexibleArray,
    unpackedSecretKeyAndNonce.fixedArrays[0],
    senderPublicKey,
    privateKey
  )

  const unpackedAttachmentAndNonce = unpackUint8Array(attachmentAndNonce, [secretbox.nonceLength])

  const mediaWithMetaObject = secretbox.open(
    unpackedAttachmentAndNonce.flexibleArray,
    unpackedAttachmentAndNonce.fixedArrays[0],
    secretKey
  )

  const { object, uint8array } = splitMetaObjectFromUint8Array(mediaWithMetaObject)

  return {
    meta: object,
    mediaBytes: uint8array
  }
}
