import { APP_WRAPPER_MESSAGE_TYPES } from '../constants.js'
import { isInAppWrapper, sendMessageToAppWrapper } from './app-wrapper-communication.js'

export async function closeNotifications () {
  if (isInAppWrapper()) {
    sendMessageToAppWrapper({ type: APP_WRAPPER_MESSAGE_TYPES.OUTGOING.NOTIFICATION_CLOSE_ALL })
  }

  if ('Notification' in window) {
    const registration = await navigator.serviceWorker.ready
    const visibleNotifications = await registration.getNotifications()

    for (const notification of visibleNotifications) {
      notification.close()
    }

    return visibleNotifications.length
  }
}
