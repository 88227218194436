<script>
  import { logger, settings, viewStack } from '../../stores'
  import ViewHeader from '../ViewHeader'
  import PopoverList from '../PopoverList.svelte'
  import { updateApp } from '../../functions/update-app.js'
  import { closeNotifications } from '../../functions/close-notifications.js'
  import { getIosVersion } from '../../functions/get-ios-version.js'

  export let json

  $: jsonString = JSON.stringify(json, (_, value) => {
    return (value instanceof Uint8Array) ? `Uint8Array.byteLength: ${value.byteLength}` : value
  }, 2)

  const STANDALONE_CHECK_KEY = 'piqchat-ignore-is-standalone-check'

  function toggleStandaloneCheck () {
    if (localStorage.getItem(STANDALONE_CHECK_KEY)) {
      localStorage.removeItem(STANDALONE_CHECK_KEY)
    } else {
      localStorage.setItem(STANDALONE_CHECK_KEY, 1)
    }

    window.location.reload()
  }

  async function checkPermission (name) {
    if (!name) {
      return
    }

    try {
      const hasNotification = await navigator.permissions.query({ name })

      logger.log(`checkPermission of '${name}': ${hasNotification.state}`)
    } catch (error) {
      logger.log(`checkPermission failed for '${name}' with error: ${error}`)
    }
  }

  function geolocationTest () {
    function success (pos) {
      const crd = pos.coords

      logger.log(`geolocationTest/ latitude: ${crd.latitude}, longitude: ${crd.longitude}`)
      logger.log(`geolocationTest/ coordinate Accuracy: ${crd.accuracy} m, speed ${crd.speed} m/s)`)
    }

    function error (error) {
      logger.log(`geolocation error ${error.code}: ${error.message}`)
    }

    navigator.geolocation.getCurrentPosition(success, error)
  }

  const { version, date, commit } = ROLLUP.BUILD_INFO
</script>

<ViewHeader>
  <span>
    Debug

    <button class="-button-reset -button-only-icon" on:click={() => window.location.reload()}>
      <svg class="feather-icon -normalize"><use href="feather-sprite.svg#rotate-cw"/></svg>
    </button>
  </span>

  <span slot="secondary">
    <PopoverList options={[
      { label: 'updateServiceWorker', handler: () => { window.dispatchEvent(new Event('SERVICE_WORKER_CHECK_FOR_UPDATE')) } },
      { label: 'closeAllNotifications', handler: () => { closeNotifications().then((n) => logger.log(`closeAllNotifications, length: ${n}`)) } },
      { label: 'checkPermission', handler: () => checkPermission(window.prompt('checkPermission, name:')) },
      { label: 'viewStack.push', handler: () => { viewStack.pop(); viewStack.push(window.prompt('viewStack.push, name:')) } },
      { label: 'set inboxServerHost', handler: () => settings.set('inboxServerHost', window.prompt('InboxServerHost:', $settings.inboxServerHost || 'localhost') ?? $settings.inboxServerHost) },
      { label: 'test geolocation', handler: geolocationTest },
      { label: 'print iOS version', handler: () => logger.log(`Detected iOS Version ${getIosVersion()?.join('.')}`) }
    ]} />
  </span>
</ViewHeader>

{#if json}
  <p><strong>JSON Prop</strong></p>
  <code class="log" style="height: auto">{jsonString}</code>
{/if}

<p class="display-flex justify-content-space-between">
  <strong>Log</strong>
  <button class="-button-reset -button-only-icon" on:click={logger.clear} style:padding="0">
    <svg class="feather-icon -normalize"><use href="feather-sprite.svg#trash-2"/></svg>
  </button>
</p>

<code class="log">
  {#each $logger as entry}
    {entry?.type ?? 'E'}
    {entry.message}
    {#if entry.url || entry.linenumber}
      ({entry.url} {entry.linenumber})
    {/if}<br>
  {/each}
</code>

<div class="form -oneline-checkbox">
  <input
    id="isDebugMode"
    class="input"
    type="checkbox"
    checked={$settings.isDebugMode}
    readonly
    on:click={async () => await settings.set('isDebugMode', !$settings.isDebugMode)}
  />
  <label for="isDebugMode" class="label">Enable debug mode</label>
</div>

<div class="form -oneline-checkbox">
  <input
    id="standaloneCheck"
    class="input"
    type="checkbox"
    checked={localStorage.getItem(STANDALONE_CHECK_KEY)}
    readonly
    on:click={toggleStandaloneCheck}
  />
  <label for="standaloneCheck" class="label">Ignore standalone check</label>
  <span class="info standalone-check-info"></span>
</div>

<p style="color: var(--dark-grey)">
  <small>Build: {version} ({commit})<br />{date}</small>
  <small><button class="-button-link" style="font-size: inherit;color: inherit;" on:click={() => updateApp('NONE')}>redownload app</button></small>
</p>

<style>
  .log {
    display: block;
    width: 100%;
    height: 300px;
    white-space: pre;
    font-size: 11px;
    padding: 1rem;
    margin-bottom: 1rem;
    overflow: scroll;
    background-color: #fff;
    user-select: text;
    -webkit-user-select: text;
  }

  @media all and (display-mode: browser) {
    .standalone-check-info:after {
      content: '(display-mode: browser)';
    }
  }

  @media all and (display-mode: minimal-ui) {
    .standalone-check-info:after {
      content: '(display-mode: minimal-ui)';
    }
  }

  @media all and (display-mode: standalone) {
    .standalone-check-info:after {
      content: '(display-mode: standalone)';
    }
  }
</style>
