import { parseIsoString } from './dume.js'

export function contactsSort (contactA, contactB) {
  function getMostRecentOf (contact) {
    const lastReceivedAt = parseIsoString(contact.lastReceivedAt)
    const lastSentAt = parseIsoString(contact.lastSentAt)
    const exchangeCompletedAt = parseIsoString(contact.exchangeCompletedAt)

    return Math.max(lastReceivedAt, lastSentAt, exchangeCompletedAt)
  }

  return getMostRecentOf(contactB) - getMostRecentOf(contactA)
}
