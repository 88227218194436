import jsQR from 'jsqr'
import qrcodeGenerator from 'qrcode-generator'
import { box, secretbox, randomBytes } from 'tweetnacl'
import { encodeBase64, decodeBase64 } from 'tweetnacl-util'
import { settings, logger } from '../stores'
import { packUint8Array, unpackUint8Array } from './pack-uint8array.js'
import { addMetaObjectToUint8Array, splitMetaObjectFromUint8Array } from './meta-object-with-uint8array'
import { inboxApi } from '../api'
import { imageToImageData } from '../functions/convert-to-imagedata'
import { CONTACT_EXCHANGE_HOST_PLACEHOLDER } from '../constants.js'

export function createEncryptedContact (publicKey, displayName, inboxServerHost, secretKey) {
  if (!secretKey) {
    secretKey = randomBytes(secretbox.keyLength)
  }

  const nonce = randomBytes(secretbox.nonceLength)
  const version = 1
  const content = addMetaObjectToUint8Array({ version, displayName, inboxServerHost }, publicKey)

  const box = secretbox(content, nonce, secretKey)
  const encryptedContact = packUint8Array(box, [nonce])

  return {
    encryptedContact: encodeBase64(encryptedContact),
    secretKey
  }
}

export function parseEncryptedContact (encryptedContactBase64, secretKey) {
  const { flexibleArray: encrypted, fixedArrays } = unpackUint8Array(decodeBase64(encryptedContactBase64), [secretbox.nonceLength])

  const bytes = secretbox.open(encrypted, fixedArrays[0], secretKey)

  const { object, uint8array: publicKey } = splitMetaObjectFromUint8Array(bytes)

  if (object.version !== 1) {
    throw new Error(`This app can't process the contact information with the version ${object.version}. Try to update the app.`)
  }

  if (!object.displayName || publicKey.length !== box.publicKeyLength) {
    throw new Error('Invalid encrypted contact')
  }

  return {
    displayName: object.displayName,
    publicKey,
    id: encodeBase64(publicKey),
    inboxServerHost: object.inboxServerHost
  }
}

export function createExchangeUrl (acceptUrl, secretKey) {
  const url = new URL(window.location.href)
  const searchParams = new URLSearchParams()
  searchParams.set('exchange-url', acceptUrl)
  searchParams.set('exchange-key', encodeBase64(secretKey))
  url.hash = '?' + searchParams.toString()

  return url.toString()
}

export function createExchangeQrCode (exchangeUrl) {
  const qrcode = qrcodeGenerator(0, 'M')
  qrcode.addData(exchangeUrl)
  qrcode.make()

  return qrcode.createDataURL(20)
}

export function replaceHostPlaceholderInExchangeUrl (exchangeUrl, inboxServerHost) {
  const url = new URL(exchangeUrl)

  const params = new URLSearchParams(url.hash.substring(1))

  const acceptUrl = params.get('exchange-url').replace(CONTACT_EXCHANGE_HOST_PLACEHOLDER, inboxServerHost)

  return createExchangeUrl(acceptUrl, '')
}

export function parseExchangeUrl (exchangeUrl) {
  const url = new URL(exchangeUrl)

  const params = new URLSearchParams(url.hash.substring(1))

  if (!params.has('exchange-url') || !params.has('exchange-key')) {
    throw new Error('The link is not a valid friend invite link')
  }

  const acceptUrl = new URL(params.get('exchange-url'))

  // NOTE: Browsers force same-site secure level anyway, so the protocol can just be overwritten.
  if (acceptUrl.url !== window.location.protocol) logger.log(`Overwrite accept url protocol with client protocol ${window.location.protocol}`)
  acceptUrl.protocol = window.location.protocol

  return {
    acceptUrl: acceptUrl.href,
    acceptUrlInboxServerHost: acceptUrl.host,
    secretKey: decodeBase64(params.get('exchange-key'))
  }
}

export async function processAndAcceptExchangeUrl (exchangeUrl, inboxServerHost, publicKey, displayName) {
  const { acceptUrl, secretKey, acceptUrlInboxServerHost } = parseExchangeUrl(exchangeUrl)

  let userUuid = null

  if (!inboxServerHost) {
    // If the client has no inbox server, it needs to send the user uuid to signal the server
    // it wants to sign up and create the user entry, so it can save this as its inbox server
    inboxServerHost = null
    userUuid = await settings.getUserUuid()
  } else if (inboxServerHost && acceptUrlInboxServerHost === CONTACT_EXCHANGE_HOST_PLACEHOLDER) {
    throw new Error('The friend invite is for sign up only and does not contain any contact information.')
  }

  let ownEncryptedContact

  if (secretKey.length > 0) {
    // NOTE: If no secret key was proposed in the exchange url, there is no need to bother to encrypt the contact info of this client.
    // However this shouldn't run in an error, because inbox server can generate urls only for signup purposes.
    ownEncryptedContact = createEncryptedContact(publicKey, displayName, inboxServerHost, secretKey).encryptedContact
  }

  const response = await inboxApi.contactExchangeRequests.accept(acceptUrl, ownEncryptedContact, userUuid)
  const data = await response.json()

  return {
    encryptedContact: data.encryptedContact,
    secretKey
  }
}

export function processQrCodeImage (image, sourceWidth, sourceHeight) {
  const imageData = imageToImageData(image, sourceWidth, sourceHeight)

  const code = jsQR(imageData.data, imageData.width, imageData.height, {
    inversionAttempts: 'dontInvert'
  })

  if (code?.data == null) {
    return null
  }

  return code.data
}
