<script>
  import { createEventDispatcher, onMount } from 'svelte'
  import { fade } from 'svelte/transition'
  import { drawImageOnCanvas, calculateCoordinatesWithFit } from '../functions/draw-image-on-canvas.js'
  import { settings } from '../stores'
  import ActionBar from './ActionBar'
  import { MEDIA_WIDTH, MEDIA_HEIGHT, MEDIA_FILTERS } from '../constants.js'

  export let active
  export let canvasElement
  export let originalSource

  const dispatch = createEventDispatcher()

  let isSelectingFilter = false
  let filterPaginatorElement
  let filterBlendIndex
  let currentScale
  let currentPosition
  let filterPaginatorTimer
  /* let touchPointsCentrum */
  /* const isLocal = window.location.hostname === 'localhost' */

  function dragStart (event) {
    if (!active) {
      return
    }

    dispatch('dragChange', { isDragging: true })

    const canvasScale = canvasElement.width / canvasElement.clientWidth
    const initialPosition = {
      x: currentPosition.x,
      y: currentPosition.y
    }
    const initialTouchPoint = {
      y: event.touches[0].clientY * canvasScale,
      x: event.touches[0].clientX * canvasScale
    }
    let lastAbsoluteDistance = null

    document.addEventListener('touchmove', dragMove)
    document.addEventListener('touchend', dragEnd)

    function dragMove (event) {
      const touchPoint1 = {
        y: event.touches[0].clientY * canvasScale,
        x: event.touches[0].clientX * canvasScale
      }

      const hasTwoTouchPoints = event.touches.length >= 2

      if (!hasTwoTouchPoints) {
        // NOTE: There is nothing against moving the image while pinching,
        // however for now it's disabled when pinching to figure out more easily what
        // the right pinching algorithm is...
        const newTop = (touchPoint1.y - initialTouchPoint.y) + initialPosition.y
        const newLeft = (touchPoint1.x - initialTouchPoint.x) + initialPosition.x

        currentPosition.y = newTop
        currentPosition.x = newLeft
      }

      if (hasTwoTouchPoints) {
        const touchPoint2 = {
          y: event.touches[1].clientY * canvasScale,
          x: event.touches[1].clientX * canvasScale
        }

        const touchPointsDistance = Math.sqrt((touchPoint1.x - touchPoint2.x) ** 2 + (touchPoint1.y - touchPoint2.y) ** 2)

        /* touchPointsCentrum = { */
        /*   x: touchPoint1.x + ((touchPoint2.x - touchPoint1.x) / 2), */
        /*   y: touchPoint1.y + ((touchPoint2.y - touchPoint1.y) / 2) */
        /* } */

        if (lastAbsoluteDistance == null) {
          lastAbsoluteDistance = touchPointsDistance
        } else if (lastAbsoluteDistance !== touchPointsDistance) {
          const scaleRate = 500
          const change = (lastAbsoluteDistance - touchPointsDistance) / scaleRate
          const newScale = currentScale - change

          const newWidth = originalSource.width * newScale
          const newHeight = originalSource.height * newScale

          if (newWidth > 100) {
            const oldWidth = currentPosition.width
            const oldHeight = currentPosition.height

            currentPosition.width = newWidth
            currentPosition.height = newHeight
            currentScale = newScale

            currentPosition.x = currentPosition.x + (oldWidth - currentPosition.width) / 2
            currentPosition.y = currentPosition.y + (oldHeight - currentPosition.height) / 2
            /*
            if (!$settings.isDebugMode) {
            } else {
              // This version zooms more in the direction where the fingers are, but not all the time.
              // It works better the higher the scale already ist...
              const factorX = (Math.abs(currentPosition.x) + (touchPointsCentrum.x * currentScale)) / currentPosition.width
              const factorY = (Math.abs(currentPosition.y) + (touchPointsCentrum.y * currentScale)) / currentPosition.height

              currentPosition.x = currentPosition.x + (oldWidth - currentPosition.width) * factorX
              currentPosition.y = currentPosition.y + (oldHeight - currentPosition.height) * factorY
            }
            */
          }

          lastAbsoluteDistance = touchPointsDistance
        }
      }

      draw()
    }

    function dragEnd (event) {
      if (event.touches.length === 1) {
        lastAbsoluteDistance = null
        initialTouchPoint.x = event.touches[0].clientX * canvasScale
        initialTouchPoint.y = event.touches[0].clientY * canvasScale
        initialPosition.x = currentPosition.x
        initialPosition.y = currentPosition.y
      }

      if (event.touches.length === 0) {
        dispatch('dragChange', { isDragging: false })

        document.removeEventListener('touchmove', dragMove)
        document.removeEventListener('touchend', dragEnd)

        draw(true)
      }
    }
  }

  function draw (includeFilter) {
    if (!canvasElement) {
      return
    }

    drawImageOnCanvas(originalSource, canvasElement, currentPosition, includeFilter ? filterBlendIndex : null)
  }

  onMount(() => {
    if (hasValidSource) {
      drawCentered(false)
    }

    if (filterPaginatorElement && filterPaginatorElement.scrollLeft > 0) {
      filterPaginatorElement.scrollTo(0, 0)
    }
  })

  function drawCentered (fit) {
    currentPosition = calculateCoordinatesWithFit(originalSource.width, originalSource.height, fit)

    currentScale = currentPosition.width / originalSource.width

    draw(true)
  }

  function alignCenter (axis) {
    if (axis === 'y') {
      currentPosition.y = (MEDIA_HEIGHT - currentPosition.height) / 2
    } else if (axis === 'x') {
      currentPosition.x = (MEDIA_WIDTH - currentPosition.width) / 2
    }

    draw(true)
  }

  function toggleTouchStartListener (isActive) {
    if (!canvasElement) {
      return
    }

    if (isActive) {
      canvasElement.addEventListener('touchstart', dragStart)
    } else {
      /* This is the fix to enable iOS long press of image preview in ContactSelection */
      canvasElement.removeEventListener('touchstart', dragStart)
    }
  }

  async function selectFilter (event) {
    isSelectingFilter = true
    dispatch('dragChange', { isDragging: true })
    clearTimeout(filterPaginatorTimer)

    const scrollPositionX = event.target.scrollLeft * window.devicePixelRatio
    const filterPageWidth = event.target.offsetWidth * window.devicePixelRatio
    const pageNumber = scrollPositionX / filterPageWidth

    if (pageNumber % 1 > 0.05 && pageNumber % 1 < 0.95) {
      return
    }

    const index = Math.round(pageNumber)

    if (MEDIA_FILTERS[index] != null) {
      await new Promise((resolve) => {
        // NOTE: Together with the clearTimeout will this only resolve if the user didn't scroll for the specified milliseconds.
        // This is good for perfomance, as the draw is perceptible as small lag when scrolling.
        // And the filterPage is a little longer visible to the user and doesn't flash (a CSS solution was not satisfactory)
        filterPaginatorTimer = setTimeout(resolve, 150)
      })

      if (filterBlendIndex !== index) {
        filterBlendIndex = index

        draw(true)
      }
    }

    dispatch('dragChange', { isDragging: false })
    isSelectingFilter = false
  }

  $: toggleTouchStartListener(active)

  // Safari throws an 'InvalidStateError' if the canvas is 0 effective area
  $: hasValidSource = originalSource.width * originalSource.height > 0
</script>

<!--
{#if $settings.isDebugMode}
  <div id="debug">
    x: {currentPosition?.x?.toFixed(2)}<br>
    y: {currentPosition?.y?.toFixed(2)}<br>
    currentScale: {currentScale?.toFixed(2)}<br>
    width: {currentPosition?.width?.toFixed(2)}<br>
    height: {currentPosition?.height?.toFixed(2)}<br>
    touchPointsCentrum: {touchPointsCentrum?.x?.toFixed(2)}/{touchPointsCentrum?.y?.toFixed(2)}
  </div>

  {#if touchPointsCentrum}
    <div class="touch-points-centrum" style="top: {touchPointsCentrum.y}px; left: {touchPointsCentrum.x}px"></div>
  {/if}

  {#if isLocal}
    <div class="touch-points-centrum" style="top: 400px; left: 300px; background-color: green"></div>
  {/if}
{/if}
-->

{#if 'filter' in window.CanvasRenderingContext2D.prototype || $settings.isDebugMode}
  <div
    class="filter-paginator"
    class:-is-visible={isSelectingFilter}
    on:scroll={selectFilter}
    bind:this={filterPaginatorElement}
    style:pointer-events={active ? 'none' : 'auto'}
  >
    {#each MEDIA_FILTERS as filter}
      <div class="filter-item">
        <div class="filter-cover" style:background-color={filter.blendColor} />
        <span class:-with-shadow={!filter.blendColor}>{filter.label}</span>
      </div>
    {/each}
  </div>
{/if}

<canvas bind:this={canvasElement} />

{#if active}
  <ActionBar right top>
    <div slot="middle" transition:fade={{ duration: 100 }}>
      <button class="-button-reset" on:click={() => drawCentered('cover')}>
        <svg class="feather-icon -with-glow"><use href="feather-sprite.svg#maximize-2"/></svg>
      </button>

      <button class="-button-reset" on:click={() => drawCentered('contain')}>
        <svg class="feather-icon -with-glow"><use href="feather-sprite.svg#minimize-2"/></svg>
      </button>

      <button class="-button-reset" on:click={() => alignCenter('x')}>
        <svg class="feather-icon -with-glow">
          <rect height="6" width="6" x="9" y="9" fill="currentColor" rx="1" />
          <line x1="12" x2="12" y1="1" y2="5" />
          <line x1="12" x2="12" y2="23" y1="19" />
        </svg>
      </button>

      <button class="-button-reset" on:click={() => alignCenter('y')}>
        <svg class="feather-icon -with-glow">
          <rect height="6" width="6" x="9" y="9" fill="currentColor" rx="1" />
          <line y1="12" y2="12" x1="1" x2="5" />
          <line y1="12" y2="12" x2="23" x1="19" />
        </svg>
      </button>
    </div>
  </ActionBar>
{/if}

<style>
  canvas {
    display: block;
    width: 100%;
  }

  /* .touch-points-centrum { */
  /*   position: absolute; */
  /*   width: 10px; */
  /*   height: 10px; */
  /*   background-color: red; */
  /* } */

  button {
    margin-bottom: 1rem;
  }

  .filter-paginator {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }

  .filter-paginator.-is-visible {
    opacity: 1;
  }

  .filter-paginator > .filter-item {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .filter-paginator > .filter-item > .filter-cover {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.6;
  }

  .filter-paginator > .filter-item > span {
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.6rem;
    font-family: serif;
    font-size: 2rem;
    color: var(--white);
  }

  .filter-paginator > .filter-item > span.-with-shadow {
    filter: drop-shadow(1px 1px 0px var(--dark-grey));
  }
</style>
