<script>
  import { viewStack } from '../../stores'
  import ViewHeader from '../ViewHeader'
  import { APP_NAME } from '../../constants.js'
</script>

<ViewHeader>Privacy Policy</ViewHeader>

<p>Last updated on 2023-12-26</p>

<p>
  {APP_NAME} does not only take privacy, but also data minimization very seriously.
</p>

<p>
  This privacy policy describes how {APP_NAME} collects, protects
  and uses the personally identifiable information you may provide through the {APP_NAME} web app.
</p>

<h3>General</h3>

<p>
  We or our web hosting provider may create <strong>web server logs</strong> of data that your browser sends to our servers for {APP_NAME}.
  These may include the date and time, the URL path accessed, the referrer URL (the previously visited page),
  the browser, the operating system and the kind of device you are using.
  Your IP address is anonymized by IP masking so that we do not collect any personal data.
</p>

<p>
  Unless clearly stated otherwise, all other information that you provide on our web app
  is stored locally in your browser and is not sent to {APP_NAME}.
</p>

<h3>Information you provide and what is shared with whom</h3>

<p>
  When you create a <strong>piqchat profile</strong> or update your profile later,
  you may enter personal data. However, you don't have to, as this is not required.
  This information may be shared end-to-end encrypted with your friends.
</p>

<p>
  "Friends" are other piqchat users with whom you have exchanged contact information.
  You do this by creating and sharing a friend invite or accepting a friend invite.
</p>

<p>
  When you create a profile, a <strong>key pair for end-to-end encryption</strong> is generated for you.
  The key pair consists of a public and a secret key. The public key is used as a unique identifier ("piqchat identifier").
  Both keys are necessary to enable the end-to-end encryption.
  The piqchat identifier may be shared with your friends and inbox servers you connect to.
  You are responsible for protecting your secret key. <em>Never</em> share your secret key or the "recovery key" with another person.
</p>

<p>
  The <strong>inbox server</strong> you sign up to is personal information because it's necessary to know how to reach you.
  This information may be shared with your friends and other inbox servers you connect to.
</p>

<details class="content-box">
  <summary>More about inbox servers...</summary>

  <p>
    To use this web app, you may connect to various piqchat inbox server,
    which are all provided third-parties and are not related to {APP_NAME}.
    Each inbox server must provide its own privacy policy.
  </p>

  <p>
    <button class="-button-link" on:click={() => viewStack.push('InboxServerExplanation')}>General explanation of what an inbox server is.</button>
  </p>

  <p>
    The data that {APP_NAME} shares with inbox servers is as minimal as possible.
    A distinction is made between the inbox server that you are signed up to
    and the inbox server that a friend of yours uses.
    Any inbox server you connect to may process your piqchat identifier
    and other metadata (e.g. date and time, who you have sent something to, your IP address, the browser you are using, etc.).
  </p>

  <p>
    Always review the privacy policies of the inbox servers.
  </p>
</details>

<p>
  <strong>Images</strong> (or messages in general) are always end-to-end encrypted when you send them
  to friends via an inbox server.
  This means that no one can view the content, but only the recipients you select can decrypt and view the content.
  Please note that inbox servers have the ability to collect metadata, e.g. about who is sending who messages to whom.
  Always review the inbox servers privacy policies.
</p>

<p>
  The piqchat identifiers of your <strong>friends</strong> may be shared with inbox servers you connect to.
</p>

<h3>Tracking</h3>

<p>
  We do not use any cookies on this websites.
  We do not use any external tracking or analysis tools.
</p>

<h3>Links and foreign content</h3>

<p>
  We are not responsible for how other websites use your personal data or what content is displayed to you when you open links of other websites in our web app.
</p>

<p>
  We are not responsible for third-party content from inbox servers or from your friends.
  You cannot block friends, but if you delete a friend, they will no longer be able to reach you.
</p>

<h3>Changes to this policy</h3>

<p>
  We will update this privacy policy as needed so that it is current, accurate, and as clear as
  possible. Your continued use of {APP_NAME} confirms your acceptance of our updated privacy policy.
</p>

<h3>Contact</h3>

<p>
  You can find contact information in the
  <button class="-button-link" on:click={() => viewStack.push('CreditsAndThanks')}>Credits</button>.
</p>

<style>
  h3 {
    margin-top: 2rem;
  }

  p {
    margin-bottom: 1rem;
  }
</style>
