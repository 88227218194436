<script>
  import ViewHeader from '../ViewHeader'
  import VersionFooter from '../VersionFooter.svelte'
</script>

<ViewHeader>Credits</ViewHeader>

<p>
  Made by <a href="https://carlreinecken.de" target="_blank">Carl Reinecken</a>.
  But not without support from awesome people! Huge thanks go out to
  <a href="https://jfkimmes.eu/" target="_blank">Florian Kimmes</a> for helping with server federation and encryption,
  <a href="https://tabeafieker.de/" target="_blank">Tabea Fieker</a> for helping with UX,
  and thanks to <a href="https://instagram.com/elfscribbles">@elfscribbles (instagram)</a> for the logo and the illustrations.
</p>

<h3>Contact / Impressum</h3>

<p>
  Carl Reinecken<br>
  <span class="adr">
    62 eßartsneseirF<br>
    grubmaH 79002
  </span>
</p>

<p>E-Mail: <span class="adr">ten.tahcqip@knio</span></p>

<h3>Libraries</h3>

<p>Used npm libraries: svelte, idb, html-to-image, context-filter-polyfill, jsqr, qrcode-generator and tweetnacl.</p>

<div class="vertical-empty-space-2x" />

<VersionFooter />

<style>
  h3 {
    margin-top: 1.4rem;
  }

  span.adr {
    unicode-bidi: bidi-override;
    direction: rtl;
  }
</style>
