<script>
  import { viewStack } from '../stores'
</script>

<div class="content-box -has-warning">
  <header>
    <span>
      <svg class="feather-icon -normalize"><use href="feather-sprite.svg#alert-triangle"/></svg>
      Caution
    </span>
  </header>

  <div class="button-row">
    <button class="-button-secondary -button-small full-width" on:click={() => viewStack.push('ContactExchangeAccept', { changeInboxServerHost: true })}>
      Move to a different inbox server...
    </button>
  </div>

  <div class="button-row">
    <button class="-button-secondary -button-small full-width" on:click={() => viewStack.push('DeleteProfile')}>
      Delete Profile...

      <svg class="feather-icon"><use href="feather-sprite.svg#trash-2" /></svg>
    </button>
  </div>
</div>
