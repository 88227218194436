import { createAuthorizationHeader } from './create-authorization-header.js'
import { createInboxApiUrl } from './create-inbox-api-url.js'
import { trackInboxServerReachability, trackInboxServerUnreachability } from './track-inbox-server-reachability.js'
import { trackUnauthorizedResponse } from './track-unauthorized-response.js'
import { normalizeErrorHandling } from './normalize-error-handling.js'

export function accountRequests (getApiStateFn) {
  return {
    async get () {
      const apiState = getApiStateFn()
      const headers = createAuthorizationHeader(apiState)

      return fetch(createInboxApiUrl(apiState, '/account'), { headers })
        .then(trackUnauthorizedResponse)
        .then(trackInboxServerReachability(apiState))
        .catch(trackInboxServerUnreachability(apiState))
        .then(normalizeErrorHandling)
    },

    async updateContacts (contactIds) {
      const apiState = getApiStateFn()
      const headers = createAuthorizationHeader(apiState)
      headers['Content-Type'] = 'application/json'

      return fetch(createInboxApiUrl(apiState, '/account/contacts'), {
        method: 'POST',
        headers,
        body: JSON.stringify({ contacts: contactIds })
      })
        .then(trackUnauthorizedResponse)
        .then(trackInboxServerReachability(apiState))
        .catch(trackInboxServerUnreachability(apiState))
        .then(normalizeErrorHandling)
    },

    async updatePushSubscription (subscription) {
      const apiState = getApiStateFn()
      const headers = createAuthorizationHeader(apiState)
      headers['Content-Type'] = 'application/json'

      return fetch(createInboxApiUrl(apiState, '/account/push-subscriptions'), {
        method: 'POST',
        headers,
        body: JSON.stringify(subscription)
      })
        .then(trackUnauthorizedResponse)
        .then(trackInboxServerReachability(apiState))
        .catch(trackInboxServerUnreachability(apiState))
        .then(normalizeErrorHandling)
    },

    async updateProfileBackup (backup) {
      const apiState = getApiStateFn()
      const headers = createAuthorizationHeader(apiState)
      headers['Content-Type'] = 'application/json'

      return fetch(createInboxApiUrl(apiState, '/account/backup'), {
        method: 'POST',
        headers,
        body: JSON.stringify({ backup })
      })
        .then(trackUnauthorizedResponse)
        .then(trackInboxServerReachability(apiState))
        .catch(trackInboxServerUnreachability(apiState))
        .then(normalizeErrorHandling)
    },

    async getProfileBackup (publicKey, privateKey, inboxServerHost, inboxServerPublicKey) {
      const headers = createAuthorizationHeader({ publicKey, privateKey, inboxServerPublicKey })

      return fetch(createInboxApiUrl({ inboxServerHost }, '/account/backup'), { headers })
        .then(normalizeErrorHandling)
    },

    async getInvitedUsers () {
      const apiState = getApiStateFn()
      return await fetch(createInboxApiUrl(apiState, '/account/invited-users'), {
        headers: createAuthorizationHeader(apiState)
      })
        .then(trackUnauthorizedResponse)
        .then(trackInboxServerReachability(apiState))
        .catch(trackInboxServerUnreachability(apiState))
        .then(normalizeErrorHandling)
    },

    async deleteProfile () {
      const apiState = getApiStateFn()
      const headers = createAuthorizationHeader(apiState)
      headers['Content-Type'] = 'application/json'

      return fetch(createInboxApiUrl(apiState, '/account'), { method: 'DELETE', headers })
        .then(trackUnauthorizedResponse)
        .then(trackInboxServerReachability(apiState))
        .catch(trackInboxServerUnreachability(apiState))
        .then(normalizeErrorHandling)
    }
  }
}
