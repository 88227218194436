<script>
  import { unopenedParcels, viewStack, logger, settings } from '../../stores'
  import { deleteLocalData } from '../../functions/delete-local-data.js'
  import ViewHeader from '../ViewHeader'
  import { inboxApi } from '../../api'
  import { longpress } from '../../actions/longpress.js'

  let isLoading = false
  let errorMessage

  async function deleteProfile () {
    isLoading = true
    viewStack.disableClose()

    try {
      await inboxApi.accountRequests.deleteProfile()
      await deleteLocalData()
      window.location.reload()
    } catch (error) {
      if (error instanceof Response) {
        errorMessage = error.parsedText ? error.parsedText : `There was an error when processing (Response: ${error.statusText})`
      } else {
        errorMessage = `There was an error when processing. ${error.message}`
      }

      logger.error(error, 'DeleteProfile')

      isLoading = false
    }
  }
</script>

<ViewHeader disableClose={isLoading}>Delete profile 🥲</ViewHeader>

{#if errorMessage}
  <p class="text-color-error">The deletion of your profile failed. {errorMessage}</p>
  <div class="vertical-empty-space-half" />
{/if}

<p>Are you sure that you want to leave piqchat and delete your profile?</p>

{#if $unopenedParcels.length > 0}
  <p>You have {$unopenedParcels.length} unseen pic{#if $unopenedParcels.length > 1}s{/if}.</p>
{/if}

<p>You will loose all your contacts and achievements and will not be able to send or recieve fun pics anymore.</p>

<div class="vertical-empty-space" />

<p class="text-align-center">
  <button class="-button-secondary delete-btn" use:longpress={1500} on:longpress={deleteProfile} disabled={isLoading || errorMessage}>
    Press and hold to delete your profile

    {#if isLoading}
      <svg class="feather-icon -rotate-animation"><use href="feather-sprite.svg#loader"/></svg>
    {/if}
  </button>
</p>

<p class="text-align-center">
  <small>A request will be sent to your inbox server "<code>{$settings.inboxServerHost}</code>" to delete all your data there.</small>
</p>

<div class="vertical-empty-space-2x" />

<p class="text-align-center">
  <button class="-button-link" on:click={() => viewStack.pop()} disabled={isLoading}>Cancel</button>
</p>

<style>
  .delete-btn {
    transform: scale(1);
    transition: transform 1500ms ease-out;
    border-color: var(--error-color);
    color: var(--error-color);
  }

  .delete-btn:global(.longpress-active) {
    transform: scale(1.1);
  }
</style>
