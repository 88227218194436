<script>
  import { createEventDispatcher } from 'svelte'
  import { logger } from '../stores'
  import EditorImageMoveBase from './EditorImageMoveBase.svelte'
  import Editor from './Editor.svelte'
  import { MEDIA_WIDTH, MEDIA_HEIGHT } from '../constants.js'

  const dispatch = createEventDispatcher()

  export let originalSource

  let hideMenu
  let canvasElement
  let toolMode

  async function exportImage (event) {
    try {
      const canvas = document.createElement('canvas')
      canvas.width = MEDIA_WIDTH
      canvas.height = MEDIA_HEIGHT

      canvas.getContext('2d').drawImage(canvasElement, 0, 0, MEDIA_WIDTH, MEDIA_HEIGHT)
      canvas.getContext('2d').drawImage(event.detail.canvasWithDrawing, 0, 0, MEDIA_WIDTH, MEDIA_HEIGHT)
      canvas.getContext('2d').drawImage(event.detail.canvasWithText, 0, 0, MEDIA_WIDTH, MEDIA_HEIGHT)

      canvas.toBlob((blob) => {
        dispatch('submit', { duration: event.detail.selectedDuration, blob })
      }, 'image/jpeg', 0.9)
    } catch (error) {
      logger.error(error, 'Image export failed')
    }
  }
</script>

<Editor
  bind:hideMenu
  isDurationSelectVisible
  on:submit={exportImage}
  on:cancel={() => dispatch('retake')}
  on:toolMode={(event) => { toolMode = event.detail }}
  showMoveBaseTool
>
  <EditorImageMoveBase
    slot="base"
    active={toolMode === 'MOVE_BASE'}
    bind:canvasElement={canvasElement}
    {originalSource}
    on:dragChange={(event) => { hideMenu = event.detail.isDragging }}
  />
</Editor>
