<script>
  import { settings, viewStack } from '../../stores'
  import ViewHeader from '../ViewHeader'
  import PushNotificationSetting from '../PushNotificationSetting.svelte'
  import ProfileRecoverySetting from '../ProfileRecoverySetting.svelte'
  import DangerZoneSetting from '../DangerZoneSetting.svelte'
  import InboxServerInlineInfo from '../InboxServerInlineInfo.svelte'
  import PopoverList from '../PopoverList.svelte'
  import { updateApp, checkForAppUpdate } from '../../functions/update-app.js'
  import { RELOAD_HANDLING_TYPES, IS_IOS } from '../../constants.js'
  import VersionFooter from '../VersionFooter.svelte'
  import { isInAppWrapper } from '../../functions/app-wrapper-communication.js'

  let newDisplayName = ''
  let userUuid = ''
  let isAppUpdateAvailable = false

  $: isDisplayNameSaveable = newDisplayName?.trim() !== '' && $settings.displayName !== newDisplayName

  const { version, commit } = ROLLUP.BUILD_INFO
  const emailBodyDraft = encodeURIComponent(`\n---\nFor debug purposes:\nInbox Server: ${$settings.inboxServerHost}\nApp Version: ${version} ${commit}\nBrowser Info: ${window.navigator.userAgent}`)

  async function saveDisplayName () {
    await settings.set('displayName', newDisplayName)
  }

  function showIdentifier () {
    return window.alert(`What makes you you? In piqchat your identity is this:\n\n${userUuid}`)
  }

  async function setup () {
    newDisplayName = $settings.displayName
    userUuid = await settings.getUserUuid()

    isAppUpdateAvailable = await checkForAppUpdate($settings.isDebugMode)
  }

  setup()
</script>

<ViewHeader showOfflineInfo>
  Profile

  <span slot="secondary">
    <PopoverList options={[
      { label: 'Show Identifier', handler: showIdentifier },
      { label: 'Logout', handler: () => viewStack.push('LogoutProfile') }
    ]} />
  </span>
</ViewHeader>

<div class="form -oneline">
  <label for="displayName" class="label">Your Nickname</label>
  <input id="displayName" class="input" bind:value={newDisplayName} />

  <div class="input flex-grow-0">
    {#if isDisplayNameSaveable}
      <button class="-button-primary -button-small" on:click={saveDisplayName}>Save</button>
    {/if}
  </div>
</div>

{#if isDisplayNameSaveable}
  <p>
    <small>Your nickname is only shared when you explicitly create or accept a friend invite.</small>
  </p>
  <br />
{/if}

<InboxServerInlineInfo />

{#if isAppUpdateAvailable}
  <div class="vertical-empty-space-half" />

  <p>
    <button class="-button-primary full-width" on:click={() => updateApp(RELOAD_HANDLING_TYPES.FORCE)}>
      Update available! Click to update
    </button>
  </p>
{/if}

<div class="vertical-empty-space-half" />

<p>
  <strong>Please note that the app is in development.</strong>
  I look forward to your feedback:
  <a href="mailto:oink@piqchat.net?subject=Feedback&body={emailBodyDraft}">oink@piqchat.net</a>
</p>

<hr />

<h3>Settings</h3>

<PushNotificationSetting />

<ProfileRecoverySetting />

<DangerZoneSetting />

<div class="vertical-empty-space-half" />

<p class="display-flex justify-content-space-between">
  <button class="-button-secondary -button-small" on:click={() => viewStack.push('Settings')}>Advanced Settings</button>

  <button class="-button-secondary -button-small" on:click={() => viewStack.push('LogoutProfile')}>
    Logout

    <svg class="feather-icon"><use href="feather-sprite.svg#log-out" /></svg>
  </button>
</p>

<div class="vertical-empty-space-2x" />

<footer>
  <VersionFooter />

  <div class="vertical-empty-space-half" />

  <p class="text-align-center">
    <button class="-button-link" on:click={() => viewStack.push('CreditsAndThanks')}>Credits</button>
    |
    <button class="-button-link" on:click={() => viewStack.push('ClientPrivacyPolicy')}>Privacy Policy</button>
    {#if IS_IOS && !isInAppWrapper()}
      |
      <button class="-button-link" on:click={() => viewStack.push('IosQuirksExplanation')}>Apple iOS FAQ</button>
    {/if}

  </p>
</footer>

<style>
  footer,
  footer > p > button {
    font-size: 0.8rem;
  }

  h3 {
    margin-bottom: 1rem;
  }
</style>
