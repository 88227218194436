import { get } from 'svelte/store'
import { inboxApi } from '../api'
import { settings, contacts } from '../stores'
import { createServiceWorkerKeyValueStore } from './service-worker-key-value-store.js'
import { sendMessageToAppWrapper, isInAppWrapper } from './app-wrapper-communication.js'
import { APP_WRAPPER_MESSAGE_TYPES } from '../constants.js'

export async function contactsDeferredUpdate () {
  const allContacts = get(contacts)

  const contactNamesMap = new Map(allContacts.map((contact) => [contact.id, contact.displayName]))

  const keyvalueDatabase = await createServiceWorkerKeyValueStore()
  keyvalueDatabase.set('contacts', contactNamesMap)

  if (isInAppWrapper()) {
    const contacts = {}
    allContacts.forEach((contact) => { contacts[contact.id] = contact.displayName })
    sendMessageToAppWrapper({ type: APP_WRAPPER_MESSAGE_TYPES.OUTGOING.NOTIFICATION_SET_CONTACTS, value: contacts })
  }

  const contactIds = allContacts.map((contact) => contact.id)
  await inboxApi.accountRequests.updateContacts(contactIds)

  await settings.set('contactsChanged', false)
}
