<script>
  const storage = window.navigator.storage
  let estimate
  let isPersisted = false

  function readableBytes (bytes) {
    if (bytes == null) {
      return 'undefined'
    }

    const gigaBytes = bytes / 1000 / 1000 / 1000

    return `${(gigaBytes).toFixed(2)} GB`
  }

  async function requestPersistance () {
    isPersisted = await storage.persist()
  }

  async function setup () {
    if ('estimate' in storage) {
      estimate = await storage.estimate()
    }

    if ('persisted' in storage) {
      isPersisted = await storage.persisted()
    }
  }

  setup()
</script>

<div class="content-box">
  <header>Storage Usage</header>

  {#if estimate && 'usage' in estimate}
    <p>Estimated Usage: {readableBytes(estimate.usage)}</p>
  {/if}

  {#if estimate && 'quota' in estimate}
    <p>Available Storage: {readableBytes(estimate.quota)}</p>
  {/if}

  {#if isPersisted}
    <p>Storage will not be cleared except by explicit user action.</p>
  {:else if 'persist' in storage}
    <p>
      <button class="-button-small full-width" on:click={requestPersistance}>Request Storage Persistance</button>
    </p>
  {/if}
</div>
