<script>
  import { mapNameToComponent } from './layered-views'
  import { viewStack } from '../stores'
  import LayeredView from './LayeredView'

  export let view

  $: isVisible = $viewStack.findIndex((item) => item.id === view.id) !== -1

  function close () {
    if (!view.disableClose) {
      viewStack.close(view.id)
    }
  }
</script>

<LayeredView
  visible={isVisible}
  viewId={view.id}
  cssClass={view.cssClass}
  on:close={close}
>
  <svelte:component
    this={mapNameToComponent(view)}
    {...view.props}
  />
</LayeredView>
