import { createInboxApiUrl } from './create-inbox-api-url.js'
import { createAuthorizationHeader } from './create-authorization-header.js'
import { normalizeErrorHandling } from './normalize-error-handling.js'
import { trackInboxServerReachability, trackInboxServerUnreachability } from './track-inbox-server-reachability.js'
import { BROWSER_STORAGE_PREFIX } from '../constants.js'
import { uploadRequestWithProgress } from '../functions/upload-request-with-progress.js'

export function parcelRequests (getApiStateFn) {
  const CACHE_STORAGE_NAME = `${BROWSER_STORAGE_PREFIX}-unopened-parcels`

  return {
    async upload (parcel, otherInboxServerHost, onProgressFn) {
      const apiState = getApiStateFn()

      apiState.inboxServerHost = otherInboxServerHost
      apiState.inboxServerPublicKey = apiState.allInboxServerMap[otherInboxServerHost]?.publicKey

      const formData = new FormData()
      formData.append('type', parcel.type)

      for (const recipientContent of parcel.recipientContents) {
        formData.append('recipient', recipientContent.recipientId + ' ' + recipientContent.content)
      }

      if (parcel.attachment) {
        formData.append('attachment', new Blob([parcel.attachment]))
      }

      return uploadRequestWithProgress(createInboxApiUrl(apiState, '/parcels'), {
        method: 'POST',
        headers: createAuthorizationHeader(apiState),
        body: formData,
        onProgressFn
      })
        .then(trackInboxServerReachability(apiState))
        .catch(trackInboxServerUnreachability(apiState))
        .then(normalizeErrorHandling)
    },

    async getAll () {
      const apiState = getApiStateFn()
      const headers = createAuthorizationHeader(apiState)
      const { version, commit } = ROLLUP.BUILD_INFO
      headers['X-Client-Version'] = `${version}+${commit}`

      return fetch(createInboxApiUrl(apiState, '/parcels'), { headers })
        .then(trackInboxServerReachability(apiState))
        .catch(trackInboxServerUnreachability(apiState))
        .then(normalizeErrorHandling)
    },

    async cacheById (parcelId) {
      const url = createInboxApiUrl(getApiStateFn(), `/parcels/${parcelId}/attachment`)

      const cacheStorage = await caches.open(CACHE_STORAGE_NAME)
      const cachedResponse = await cacheStorage.match(url)

      if (cachedResponse && cachedResponse.ok) {
        return Promise.resolve(cachedResponse)
      }

      const response = await fetch(url, { headers: createAuthorizationHeader(getApiStateFn()) })

      if (response && response.ok) {
        await cacheStorage.put(url, response.clone())

        return Promise.resolve(response)
      } else {
        return Promise.reject(response)
      }
    },

    async deleteFromCacheById (parcelId) {
      const url = createInboxApiUrl(getApiStateFn(), `/parcels/${parcelId}/attachment`)

      const cacheStorage = await caches.open(CACHE_STORAGE_NAME)

      await cacheStorage.delete(url)
    },

    async clearAllFromCache () {
      await caches.delete(CACHE_STORAGE_NAME)
    },

    async delete (parcelId) {
      const apiState = getApiStateFn()

      return await fetch(createInboxApiUrl(apiState, `/parcels/${parcelId}`), {
        method: 'DELETE',
        headers: createAuthorizationHeader(apiState)
      })
        .catch(trackInboxServerUnreachability(apiState))
    },

    async getContactStatus (contactId, otherInboxServerHost) {
      const apiState = getApiStateFn()

      apiState.inboxServerHost = otherInboxServerHost
      apiState.inboxServerPublicKey = apiState.allInboxServerMap[otherInboxServerHost]?.publicKey

      contactId = encodeURIComponent(contactId)

      return fetch(createInboxApiUrl(apiState, `/parcels/${contactId}/status`), {
        headers: createAuthorizationHeader(apiState)
      })
        .catch(trackInboxServerUnreachability(apiState))
        .then(normalizeErrorHandling)
    },

    async isContactAccepting (contactId, otherInboxServerHost) {
      const apiState = getApiStateFn()

      apiState.inboxServerHost = otherInboxServerHost
      apiState.inboxServerPublicKey = apiState.allInboxServerMap[otherInboxServerHost]?.publicKey

      contactId = encodeURIComponent(contactId)

      return fetch(createInboxApiUrl(apiState, `/parcels/${contactId}/accepts`), {
        headers: createAuthorizationHeader(apiState)
      })
        .catch(trackInboxServerUnreachability(apiState))
        .then(normalizeErrorHandling)
    }

  }
}
