<script>
  import { contacts, settings } from '../../stores'
  import { inboxApi } from '../../api'
  import ViewHeader from '../ViewHeader'
  import InviteTreeItem from '../InviteTreeItem.svelte'
  import InboxServerInlineInfo from '../InboxServerInlineInfo.svelte'

  let users = []
  let isLoading = true
  let initialCreatedBy

  async function fetchUsers () {
    isLoading = true

    const response = await inboxApi.accountRequests.getInvitedUsers()
    users = await response.json()

    const contactMap = new Map($contacts.map((contact) => {
      return [contact.id, contact.displayName]
    }))

    const userId = await settings.getUserUuid()

    contactMap.set(userId, $settings.displayName)
    initialCreatedBy = users.find((user) => user.id === userId)?.createdBy

    for (const user of users) {
      if (contactMap.has(user.id)) {
        user.displayName = contactMap.get(user.id)
      }

      if (user.createdBy == null) {
        // NOTE: If at least one user has no creator, that means that all users are returned.
        // To ensure all users are shown and not only those who descended from the current user,
        // the initial createdBy is set to null.
        initialCreatedBy = null
      }
    }

    isLoading = false
  }

  fetchUsers()
</script>

<ViewHeader>Invite Tree</ViewHeader>

<InboxServerInlineInfo />

<p>Here are shown all users which you have invited to your inbox server.</p>

<div class="tree">
  <div class="display-flex justify-content-space-between font-weight-bold">
    <span>Nickname</span>
    <span>Last seen</span>
  </div>

  {#if isLoading}
    <p>Loading...</p>
  {:else}
    <InviteTreeItem {users} createdBy={initialCreatedBy} />
  {/if}
</div>

<p>Users you have not saved as friends have no nickname and instead show their piqchat identifier.</p>

<style>
  .tree {
    margin: 1rem;
  }
</style>
