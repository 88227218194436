<script>
  import ViewHeader from '../ViewHeader'
  import { IS_IOS } from '../../constants.js'
  import CopyButton from '../CopyButton.svelte'

  export let deferredInvite = null
  export let isPromptChoiceAccepted
</script>

<ViewHeader>
  {#if isPromptChoiceAccepted != null}
    Install app
  {:else}
    How to get the app
  {/if}
</ViewHeader>

{#if IS_IOS}
  {#if deferredInvite}
    <p><strong>1. Copy your invite link for later</strong></p>

    <p>
      <CopyButton class="-button-primary-alt full-width" content={deferredInvite} />
    </p>

    <div class="vertical-empty-space" />
  {/if}

  <p>
    <strong>{#if deferredInvite}2.{:else}1.{/if} Open the website share menu</strong>
    <br/>
    (the <svg class="feather-icon share" alt="share icon"><use href="feather-sprite.svg#share"/></svg> at the bottom of your device)
  </p>

  <div class="vertical-empty-space-half" />

  <p>
    {#if deferredInvite}3.{:else}2.{/if} Click on
    <span class="add-hs">Add to Home Screen <svg class="feather-icon"><use href="feather-sprite.svg#plus-square"/></svg></span>
  </p>

  <div class="vertical-empty-space-half" />

  <p>{#if deferredInvite}4. {:else}3. {/if} Done</p>
{:else}
  {#if isPromptChoiceAccepted == null}
    <p><strong>If you already installed the web app, open it to use it.</strong></p>

    <p>
      It can not be determined if your browser and device supports web app installation.
      Try another browser or device.
    </p>
  {:else if isPromptChoiceAccepted === true}
    <p><strong>The web app was installed successfully! Go to your home screen to open it.</strong></p>
  {:else if isPromptChoiceAccepted === false}
    <p>
      You have canceled the installation.
      If you change your mind, you can <button class="-button-link" on:click={() => window.location.reload()}>reload the website</button> and try again.
    </p>
  {/if}
{/if}

<div class="vertical-empty-space-2x" />

<style>
  .feather-icon.share {
    vertical-align: middle;
    height: 1.2rem;
    background-color: var(--second-brand-color);
    padding: 2px 4px;
    border-radius: 3px;
  }

  .add-hs {
    background-color: var(--second-brand-color);
    padding: 2px 4px;
    border-radius: 3px;
  }

  .add-hs > .feather-icon {
    vertical-align: top;
    width: 1.2rem;
    height: 1.2rem;
  }
</style>
