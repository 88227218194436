<script>
  import { viewStack, settings, logger } from '../../stores'
  import { processAndAcceptExchangeUrl } from '../../functions/contact-exchange-coder.js'
  import { updateApp } from '../../functions/update-app.js'
  import { RELOAD_HANDLING_TYPES, IS_IOS, APP_NAME } from '../../constants.js'
  import { deferInstallPrompt, installWithDeferredPrompt } from '../../functions/install-with-deferred-prompt.js'

  let deferredInvite = null
  let deferredPrompt

  async function checkForInviteInCurrentUrl () {
    if (window.location.hash && ['#privacy-policy'].includes(window.location.hash)) {
      viewStack.push('ClientPrivacyPolicy')
      return
    }

    try {
      if (IS_IOS || !$settings.inboxServerHost) {
        // NOTE: If it is iOS or the client has no registered profile (checked by existence of inboxServerHost),
        // it SHOULD NOT accept the exchange url automatically.
        if (window.location.hash) {
          deferredInvite = window.location.href

          settings.set('deferredContactExchangeUrl', deferredInvite)
        }

        return
      }

      const {
        encryptedContact,
        secretKey
      } = await processAndAcceptExchangeUrl(window.location.href, $settings.inboxServerHost, $settings.publicKey, $settings.displayName)

      viewStack.push('ContactExchangeSaveContact', { encryptedContact, secretKey })
    } catch (error) {
      if (!error.toString().includes('Incomplete contact exchange url')) {
        logger.error(error)
      }
    }
  }

  async function clickGetApp () {
    let isPromptChoiceAccepted = null

    if (deferredPrompt) {
      isPromptChoiceAccepted = await installWithDeferredPrompt(deferredPrompt)
    }

    viewStack.push('InstallAppResponse', { deferredInvite, isPromptChoiceAccepted }, { cssClass: 'alternate-colors' })
  }

  viewStack.updateOptionsOfCurrent({ disableClose: true, cssClass: 'alternate-colors' })

  checkForInviteInCurrentUrl()

  deferInstallPrompt().then((event) => {
    deferredPrompt = event
  })

  updateApp(RELOAD_HANDLING_TYPES.NONE)
</script>

<div class="logo-and-title">
  <div class="display-flex align-items-center">
    <div class="image" />

    <h1>{APP_NAME}</h1>
  </div>

  <button class="-button-primary-alt -button-small" on:click={clickGetApp}>
    Get app
  </button>
</div>

<h2>Share fun & creative <span class="display-inline-block">self-destructive</span> images with your friends</h2>

<h3>simple, personal, secure</h3>

<div class="vertical-empty-space-2x" />

<div class="screenshot-slider">
  <div class="screenshot-camera"></div>
  <div class="screenshot-editor"></div>
  <div class="screenshot-inbox"></div>
</div>

<div class="vertical-empty-space-2x" />

<p>
  <button class="-button-primary-alt full-width" on:click={clickGetApp}>
    Get the app 🚀
  </button>
</p>

<div class="vertical-empty-space-2x" />

<section class="uniqueness">
  <p>
    <strong>Personal.</strong> Only you and your friends.
    No pressure to appeal to the largest crowd of strangers.
    For every image, you first select who you want to share it with.
  </p>

  <p>
    <strong>Focused.</strong>
    A camera, an editor, and your friends are all what you need to have fun.
    No endlessly scrolling content to waste your time with.
  </p>

  <p>
    <strong>Secure.</strong>
    End-to-end encrypted by default.
    Only the recipients of an image can see the image.
    Not even the server owner can peek.
  </p>

  <p>
    <strong>Decentralized.</strong>
    Free from the reliance on a company that exploits your dependency and monetizes your data.
    You choose who provides your server,
    <a class="-button-link" target="_blank" href="https://github.com/carlreinecken/piqchat-inbox-server">you can even host one yourself.</a>
    Yet you can still get in touch with anyone.
  </p>
</section>

<div class="dancing-pig">
  <img src="images/dancing-pig.png" alt="A drawing of a dancing pig" />
</div>

<div class="vertical-empty-space-2x" />

<h2>Set up your own inbox server!</h2>

<p>
  Every piqchat user needs to sign up to an inbox server in order to receive images.
  Help make piqchat a truly personal, social, fun and independent experience.
</p>

<p>
  Give it a try! You can get started or learn more at github.com:
</p>

<div class="vertical-empty-space-half" />

<p class="text-align-center">
  <a class="-button-primary -link-button full-width" target="_blank" href="https://github.com/carlreinecken/piqchat-inbox-server">
    piqchat-inbox-server on github.com
  </a>
</p>

<div class="vertical-empty-space-half" />

<p>
  What the heck is an inbox server?
  <button class="-button-link" on:click={() => viewStack.push('InboxServerExplanation')}>
    Click here for a short and simple explanation.
  </button>
</p>

<footer>
  <p>
    This project is a celebration of what is possible today, using only web technologies!
    For both iOS and Android: new, fun and secure product experience can be created without reliance on an app store.
  </p>

  <div class="vertical-empty-space" />

  <p class="display-flex justify-content-space-between">
    <span>
      © {new Date().getFullYear()} {APP_NAME}
    </span>

    <span>
      <button class="-button-link" on:click={() => viewStack.push('CreditsAndThanks')}>Credits</button>
      |
      <button class="-button-link" on:click={() => viewStack.push('ClientPrivacyPolicy')}>Privacy Policy</button>
    </span>
  </p>
</footer>

<style>
  .-button-primary-alt {
    color: var(--second-brand-dark-color);
    font-weight: bold;
  }

  .logo-and-title {
    display: flex;
    padding: 1.5rem 1rem 2rem;
    align-items: center;
    justify-content: space-between;
  }

  .logo-and-title .image {
    background: url("../icons/android-chrome-512x512.png") var(--brand-color);
    background-size: 100%;
    background-blend-mode: lighten;
    width: 3rem;
    height: 3rem;
    border-radius: 0.8rem;
  }

  .logo-and-title h1 {
    margin: 0;
    margin-left: 0.8rem;
    display: inline;
    color: var(--brand-color);
    font-size: 1rem;
  }

  .logo-and-title button {
    text-transform: uppercase;
    font-size: 0.8rem;
  }

  h2 {
    font-family: "LT Cushion", sans-serif;
    font-size: 2rem;
    text-wrap: balance;
  }

  h3 {
    font-family: "LT Cushion", sans-serif;
    color: var(--brand-color);
  }

  .screenshot-slider {
    display: flex;
    margin: 0.3rem 0 1rem 0;
    padding: 2rem 1rem;
    gap: 0.7rem;
    overflow: scroll;
    scroll-snap-type: x proximity;
    background-color: var(--brand-color);
    scroll-padding: 2rem;
  }

  .screenshot-slider > div {
    scroll-snap-align: start;
    width: 50vw;
    max-width: 15rem;
    aspect-ratio: 9/16;
    flex-shrink: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: var(--content-box-border-radius);
  }

  .screenshot-slider > .screenshot-camera {
    background: url("../images/screenshot-camera.png"), url("../images/sunflowers.jpg");
    background-size: 100%;
  }

  .screenshot-slider > .screenshot-editor {
    background: url("../images/screenshot-editor-duration.png"), url("../images/party.jpg");
    background-size: 100%;
  }

  .screenshot-slider > .screenshot-inbox {
    background: url("../images/screenshot-inbox.jpg");
    background-size: 100%;
  }

  section.uniqueness {
    margin-bottom: 4rem;
  }

  section.uniqueness > p {
    margin-top: 1.5rem;
  }

  section.uniqueness > p > strong {
    font-family: 'LT Cushion', serif;
    font-size-adjust: 0.6;
  }

  .dancing-pig {
    text-align: center;
    pointer-events: none;
  }

  .dancing-pig > img {
    max-width: 60%;
    width: 10rem;
  }

  footer {
    margin-top: 3rem;
    margin-bottom: -1rem;
    font-size: 0.8rem;
    background-color: var(--light-grey);
    padding: 1rem 0;
  }

  footer button {
    font-size: 0.8rem;
  }
</style>
