const IN_SECONDS = [
  ['year', 60 * 60 * 24 * 365],
  ['month', 60 * 60 * 24 * 31],
  ['week', 60 * 60 * 24 * 7],
  ['day', 60 * 60 * 24],
  ['hour', 60 * 60],
  ['minute', 60],
  ['second', 1]
]

function toString (dume) {
  const arr = []

  for (let i = 0; i < IN_SECONDS.length; i++) {
    if (Object.prototype.hasOwnProperty.call(dume, IN_SECONDS[i][0])) {
      arr.push(
        dume[IN_SECONDS[i][0]] +
        ' ' + IN_SECONDS[i][0] +
        ((dume[IN_SECONDS[i][0]] > 1) ? 's' : '')
      )
    }
  }

  return arr
}

export function dume (seconds, asObject) {
  seconds = parseInt(seconds) || 0

  const dume = {}

  for (let i = 0; i < IN_SECONDS.length; i++) {
    if (seconds >= IN_SECONDS[i][1]) {
      dume[IN_SECONDS[i][0]] = parseInt(seconds / IN_SECONDS[i][1])
      seconds = seconds % IN_SECONDS[i][1]
    }
  }

  return asObject ? dume : toString(dume)
}

export function dumeToNow (date) {
  return dume((Math.abs(Date.now() - date)) / 1000)[0]
}

export function parseIsoString (isoString) {
  return isoString ? new Date(isoString) : null
}
