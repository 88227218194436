import { inboxApi } from '../api'
import { contacts } from '../stores'

export async function checkIsContactAccepting (contact) {
  if (contact.exchangeCompletedAt != null) {
    return true
  }

  try {
    await inboxApi.parcelRequests.isContactAccepting(contact.id, contact.inboxServerHost)

    const exchangeCompletedAt = (new Date()).toISOString()
    contacts.set({ ...contact, exchangeCompletedAt })

    return true
  } catch (error) {
    if (error.cause === 'MISSING_INBOX_SERVER_KEY') {
      await inboxApi.metaRequests.getServerInfo(contact.inboxServerHost)
    }
  }

  return false
}
