import { writable } from 'svelte/store'
import { createViewStackStore } from './view-stack'
import { createContactsStore } from './contacts'
import { createSettingsStore } from './settings'
import { createLoadingStore } from './loading'
import { createLookupStore } from './lookup'
import { createUnopenedParcelsStore } from './unopened-parcels'
import { createLoggerStore } from './logger'
import { createContactExchangeActiveStore } from './contact-exchange-active'

export const viewStack = createViewStackStore()
export const contacts = createContactsStore()
export const settings = createSettingsStore()
export const loading = createLoadingStore()
export const uploadingProgress = writable()
export const lookup = createLookupStore()
export const unopenedParcels = createUnopenedParcelsStore()
export const logger = createLoggerStore()
export const contactExchangeActive = createContactExchangeActiveStore()
export const isCameraInScanMode = writable(null)
export const sendToContact = writable()
export const contactSuggestionsParcels = writable([])
export const isOnline = writable(window.navigator.onLine)
export const isUnauthorized = writable(false)

export async function loadAll () {
  await contacts.load()
  await settings.load()
  await contactExchangeActive.load()
  await unopenedParcels.load()
}

window.viewStack = viewStack
window.contacts = contacts
