import { box, randomBytes } from 'tweetnacl'
import { encodeBase64 } from 'tweetnacl-util'
import { packUint8Array } from '../functions/pack-uint8array.js'

export function createAuthorizationHeader (apiState) {
  const keyId = encodeBase64(apiState.publicKey)
  const serverPublicKey = apiState.inboxServerPublicKey

  if (!serverPublicKey || !apiState.privateKey) {
    const cause = !serverPublicKey ? 'MISSING_INBOX_SERVER_KEY' : 'MISSING_CLIENT_PRIVATE_KEY'
    throw new Error('Missing keys to create authorization header', { cause })
  }

  const nonce = randomBytes(box.nonceLength)
  const date = (new Date()).toISOString()

  const signature = box(
    new TextEncoder('utf-8').encode(date),
    nonce,
    serverPublicKey,
    apiState.privateKey
  )

  const nonceAndSignature = encodeBase64(packUint8Array(signature, [nonce]))

  return {
    Authorization: `Signature keyId="${keyId}",headers="x-date",signature="${nonceAndSignature}"`,
    'X-Date': date
  }
}
