<script>
  import { viewStack, contacts, settings, logger } from '../../stores'
  import ViewHeader from '../ViewHeader'
  import { inboxApi } from '../../api'
  import { PARCEL_TYPES } from '../../constants.js'
  import MoveInboxServerStep from '../MoveInboxServerStep.svelte'
  import ProfileRecoverySetting from '../ProfileRecoverySetting.svelte'
  import { contactsDeferredUpdate } from '../../functions/contacts-deferred-update.js'
  import { revokeSubscription } from '../../functions/push-notifications.js'

  export let newInboxServerHost
  export let newInboxServerAdminName
  export let inviterDisplayName

  let errorMessage

  let currentStepMessage = null
  let finishedSteps = new Set()
  let notifyingContactsProgress = 0
  let isDone = false

  const allContacts = $contacts.filter((contact) => contact.exchangeCompletedAt != null)

  const previousInboxServerHost = $settings.inboxServerHost

  async function deleteProfileStep () {
    try {
      await inboxApi.accountRequests.deleteProfile()
      finishedSteps.add('DELETE_PROFILE_SUCCESS')
    } catch (error) {
      if (error instanceof Response && error.status === 404) {
        finishedSteps.add('DELETE_PROFILE_NOT_FOUND')
      } else {
        finishedSteps.add('DELETE_PROFILE_FAIL')
      }
    }

    finishedSteps = finishedSteps
  }

  async function notifyContactsStep () {
    let notifyResponseError = false

    for (const contact of allContacts) {
      const parcel = {
        recipientContents: [{ recipientId: contact.id, content: JSON.stringify({ inboxServerHost: newInboxServerHost }) }],
        type: PARCEL_TYPES.UPDATE_INBOX_SERVER
      }

      try {
        // TODO: Upload could be grouped by inbox server
        await inboxApi.parcelRequests.upload(parcel, contact.inboxServerHost)

        notifyingContactsProgress++
      } catch (error) {
        notifyResponseError = true
      }
    }

    if (notifyResponseError) {
      finishedSteps.add('NOTIFY_CONTACTS_FAIL')
    } else {
      finishedSteps.add('NOTIFY_CONTACTS_SUCCESS')
    }
    finishedSteps = finishedSteps
  }

  async function move () {
    await inboxApi.metaRequests.getServerInfo(newInboxServerHost)

    if (inviterDisplayName) {
      finishedSteps.add('ACCEPTED_INVITE')
      finishedSteps = finishedSteps
    }
    // 1. TODO: persist in storage intention of moving. so it can delete, set and broadcast after being interrupted

    if (newInboxServerHost === previousInboxServerHost) {
      finishedSteps.add('TARGET_HOST_IS_SAME_AS_PREVIOUS')
      finishedSteps = finishedSteps
    } else {
      // 2. delete profile
      currentStepMessage = 'Deleting profile on previous inbox server'
      await deleteProfileStep()
    }

    // 3. set inbox as own in settings
    await settings.set('inboxServerHost', newInboxServerHost)

    // 4. broadcast inbox server change to all contacts
    if (allContacts.length > 0) {
      currentStepMessage = 'Silently notifying friends'
      await notifyContactsStep()
    }

    await contactsDeferredUpdate()

    currentStepMessage = null

    isDone = true

    // The profile on the previous inbox server and with it the subscription was deleted,
    // for a complete state the browser-local subscription should be also revoked:
    revokeSubscription()
  }

  async function setup () {
    viewStack.disableClose()

    try {
      await move()
    } catch (error) {
      logger.error(error)

      errorMessage = error.message
    }
  }

  setup()
</script>

<ViewHeader disableClose>
  Moving to {newInboxServerAdminName}'s inbox server
</ViewHeader>

{#if finishedSteps.has('ACCEPTED_INVITE')}
  <MoveInboxServerStep>Accepted friend invite and "{inviterDisplayName}" saved as friend</MoveInboxServerStep>
{/if}

{#if finishedSteps.has('TARGET_HOST_IS_SAME_AS_PREVIOUS')}
  <MoveInboxServerStep withWarning>The target inbox server is the same as the one you are moving from</MoveInboxServerStep>
{/if}

{#if finishedSteps.has('DELETE_PROFILE_SUCCESS')}
  <MoveInboxServerStep>Deleted profile on previous inbox server</MoveInboxServerStep>
{/if}

{#if finishedSteps.has('DELETE_PROFILE_FAIL')}
  <MoveInboxServerStep withWarning>
    <strong>Profile on previous inbox server could not be deleted.</strong>

    Please contact your
    <button class="-button-link" on:click={() => viewStack.push('InboxServerDetail', { inboxServerHost: previousInboxServerHost })}>
      previous inbox server
    </button>
    on you own, if you find it important to delete your data.
  </MoveInboxServerStep>
{/if}

{#if finishedSteps.has('DELETE_PROFILE_NOT_FOUND')}
  <MoveInboxServerStep>
    Profile on your previous inbox server was already deleted.
  </MoveInboxServerStep>
{/if}

{#if finishedSteps.has('NOTIFY_CONTACTS_SUCCESS')}
  <MoveInboxServerStep>Silently notified friends</MoveInboxServerStep>
{/if}

{#if finishedSteps.has('NOTIFY_CONTACTS_FAIL')}
  <MoveInboxServerStep withWarning>
    Silently notified all friends that could be reached.
    All other friends need to update your contact manually by exchanging a friend invite.
  </MoveInboxServerStep>
{/if}

{#if currentStepMessage}
  <MoveInboxServerStep>
    <svg slot="icon"class="feather-icon -rotate-animation">
      {#if !errorMessage}
        <use href="feather-sprite.svg#loader"/>
      {/if}
    </svg>

    {currentStepMessage}...
  </MoveInboxServerStep>
{/if}

{#if errorMessage}
  <MoveInboxServerStep>
    <svg slot="icon" class="feather-icon text-color-error"><use href="feather-sprite.svg#x-circle"/></svg>

    <strong>Aborting due to an error. Try again later.</strong> {errorMessage}
  </MoveInboxServerStep>
{/if}

{#if notifyingContactsProgress > 0}
  <div class="contact-progress">
    <div class="percentage" style:width="{notifyingContactsProgress / allContacts.length * 100}%">
      <span>{notifyingContactsProgress} / {allContacts.length}</span>
    </div>
  </div>
{/if}

<div class="vertical-empty-space" />

{#if isDone}
  <ProfileRecoverySetting
    showOnlyCopyPrompt
    copyPromptPrefix="Be aware that your recovery key for your profile backup changed!"
    backupInSetup
  />

  <div class="vertical-empty-space" />

  <p>
    <button on:click={() => viewStack.clear()} class="-button-primary full-width">Done</button>
  </p>
{:else if errorMessage}
  <p>
    <button on:click={() => viewStack.clear()} class="-button-secondary full-width">Close</button>
  </p>
{/if}

<style>
  .contact-progress {
    border-radius: 0.3rem;
    margin-left: 3.3rem;
    overflow: hidden;
    position: relative;
    height: 1.5rem;
    width: 75%;
    background-color: var(--light-grey);
  }

  .contact-progress > .percentage {
    height: 100%;
    position: absolute;
    text-align: center;
    background-color: var(--dark-grey);
  }

  .contact-progress > .percentage > span {
    position: relative;
    color: var(--white);
    font-size: 0.8rem;
    white-space: pre;
  }
</style>
