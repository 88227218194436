import { get } from 'svelte/store'
import { loading, logger, unopenedParcels, contacts, contactSuggestionsParcels } from '../stores'
import { inboxApi } from '../api'
import { updateLevelPointsCausedByContact, createPointsOfContact } from './contact-points.js'
import { PARCEL_TYPES } from '../constants.js'
import { parseIsoString } from '../functions/dume.js'

export async function checkForNewParcels () {
  if (get(loading) != null) {
    // NOTE:           ^ Hacky, i know...
    // Because the check is done at so many places, I feel like I need to prevent duplicated requests
    return
  }

  loading.set('Checking for new...')

  try {
    const responseIndex = await inboxApi.parcelRequests.getAll()
    const newParcels = await responseIndex.json()

    const contactIds = get(contacts).map((contact) => contact.id)
    const newParcelsOfContacts = newParcels.filter((parcel) => contactIds.includes(parcel.uploadedBy))

    const imageParcels = newParcelsOfContacts
      .filter((parcel) => [PARCEL_TYPES.IMAGE, PARCEL_TYPES.MEDIA].includes(parcel.type))
      .map((parcel) => {
        parcel.content = JSON.parse(parcel.content)
        return parcel
      })

    await unopenedParcels.setAll(imageParcels, contactIds)

    const suggestionParcels = newParcelsOfContacts.filter((parcel) => parcel.type === PARCEL_TYPES.CONTACT)
    contactSuggestionsParcels.set(suggestionParcels)

    const updateInboxServerParcels = newParcelsOfContacts.filter((parcel) => parcel.type === PARCEL_TYPES.UPDATE_INBOX_SERVER)
    await updateInboxServerOfContacts(updateInboxServerParcels)

    // NOTE: The passed parcels are always all those that are still on the inbox server, so it
    // is very likely that most of these were all already processed with this function.
    // But I'm not sure if this function is expensive and needs to be optimized in that regard...
    await updateContactsLastReceivedAt(imageParcels)
    // TODO: Also update the exchangeCompletedAt if previously `null`
    await updateContactsPoints(imageParcels)

    const newParcelsOfUnknownUser = newParcels.filter((parcel) => !contactIds.includes(parcel.uploadedBy))
    deleteParcelsOnInboxServer(newParcelsOfUnknownUser)
  } catch (error) {
    logger.error(error, 'checkForNewParcels')
  }

  loading.clear()
}

export async function cacheParcels (parcels) {
  try {
    const cacheRequests = []

    for (const parcel of parcels) {
      if (parcel.isCached) {
        continue
      }

      cacheRequests.push(getCachedParcel(parcel))
    }

    await Promise.allSettled(cacheRequests)
  } catch (error) {
    logger.error(error)
  }
}

export async function getCachedParcel (parcel) {
  const response = await inboxApi.parcelRequests.cacheById(parcel.id)

  parcel.isCached = true
  unopenedParcels.put(parcel) // no await necessary

  return response
}

export async function deleteExpiredMediaParcelsOnInboxServer () {
  const expiredParcels = await unopenedParcels.getAllExpired()

  const promises = await deleteParcelsOnInboxServer(expiredParcels)

  const successfullIds = (promises)
    .filter((promiseOutcome) => promiseOutcome.status === 'fulfilled')
    .map((promiseOutcome) => promiseOutcome.value)

  await unopenedParcels.deleteByIds(successfullIds)
}

async function deleteParcelsOnInboxServer (parcels) {
  const requests = parcels.map(async (parcel) => {
    return inboxApi.parcelRequests.delete(parcel.id)
      .then(() => parcel.id)
  })

  return Promise.allSettled(requests)
}

async function updateContactsLastReceivedAt (parcels) {
  const contactIds = new Set(parcels.map((parcel) => parcel.uploadedBy))
  const lastReceivedAtChanges = await contacts
    .getBulkTemplate('lastReceivedAt', contactIds)

  for (const parcel of parcels) {
    const lastDate = lastReceivedAtChanges.get(parcel.uploadedBy)

    if (lastDate == null || new Date(lastDate) < new Date(parcel.uploadedAt)) {
      lastReceivedAtChanges.set(parcel.uploadedBy, parcel.uploadedAt)
    }
  }

  await contacts.bulkUpdate('lastReceivedAt', lastReceivedAtChanges)
}

async function updateContactsPoints (parcels) {
  const contactIds = new Set(parcels.map((parcel) => parcel.uploadedBy))
  const pointChanges = await contacts.getBulkTemplate('points', contactIds)

  for (const parcel of parcels) {
    const pointsOfContact = pointChanges.get(parcel.uploadedBy) ?? createPointsOfContact()

    const newPointsOfContact = updateLevelPointsCausedByContact(pointsOfContact, parcel.content?.points)

    if (newPointsOfContact) {
      pointChanges.set(parcel.uploadedBy, newPointsOfContact)
    }
  }

  await contacts.bulkUpdate('points', pointChanges)
}

async function updateInboxServerOfContacts (parcels) {
  if (parcels.length === 0) {
    return
  }

  const parcelGrouped = new Map()
  const allInboxServerHosts = new Set()

  for (const parcel of parcels) {
    if (!parcelGrouped.has(parcel.uploadedBy)) {
      parcelGrouped.set(parcel.uploadedBy, parcel)

      continue
    }

    if (parseIsoString(parcelGrouped.get(parcel.uploadedBy).uploadedAt) < parseIsoString(parcel.uploadedAt)) {
      parcelGrouped.set(parcel.uploadedBy, parcel)
    }
  }

  const inboxServerHostChanges = new Map()

  for (const [contactId, parcel] of parcelGrouped) {
    const content = JSON.parse(parcel.content)

    if (content && content?.inboxServerHost) {
      inboxServerHostChanges.set(contactId, content.inboxServerHost)
      allInboxServerHosts.add(content.inboxServerHost)
    } else {
      logger.error(`A parcel with type UPDATE_INBOX_SERVER has invalid content "${content}"`)
    }
  }

  await contacts.bulkUpdate('inboxServerHost', inboxServerHostChanges)

  await deleteParcelsOnInboxServer(parcels)

  await Promise.allSettled(Array.from(allInboxServerHosts).map(
    (inboxServerHost) => inboxApi.metaRequests.getServerInfo(inboxServerHost)
  ))
}
