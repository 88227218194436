import { get } from 'svelte/store'
import { contactExchangeActive } from '../stores'
import { inboxApi } from '../api'
import { saveEncryptedContact } from './save-encrypted-contact.js'
import { contactsDeferredUpdate } from './contacts-deferred-update.js'

export async function checkActiveContactExchange () {
  const activeExchange = get(contactExchangeActive)

  if (!activeExchange) {
    return
  }

  try {
    const response = await inboxApi.contactExchangeRequests.get(activeExchange.oneTimeToken)
    const data = await response.json()

    if (data?.state === 'ACCEPTED') {
      await saveEncryptedContact(data.encryptedContact, activeExchange.secretKey)
      await contactsDeferredUpdate()

      contactExchangeActive.clear()

      return true
    }
  } catch (error) {
    if (error instanceof Response && error.status === 404) {
      await contactExchangeActive.clear()
    } else {
      throw error
    }
  }
}
