<script>
  import { cacheParcels } from '../functions/receive-parcels.js'
  import { settings, lookup, viewStack, unopenedParcels as unopenedParcelsStore, sendToContact, uploadingProgress } from '../stores'
  import { dumeToNow, parseIsoString } from '../functions/dume.js'
  import { getLevelProperties } from '../functions/contact-points.js'
  import { checkIsContactAccepting } from '../functions/check-is-contact-accepting.js'
  import { longpress } from '../actions/longpress.js'
  import SplashEffect from '../components/SplashEffect.svelte'

  export let contact = {}
  export let refreshKey

  let isCaching = false

  function clickPrimary () {
    if (cachedParcelCount > 0 && contact.error == null) {
      startLookup()
    } else if (contact.error != null || contact.exchangeCompletedAt == null) {
      openContactDetail()
    } else {
      openCamera()
    }
  }

  function openCamera () {
    $sendToContact = contact
    viewStack.clear()
  }

  async function clickLookupBadge () {
    if (cachedParcelCount === 0) {
      isCaching = true
      await cacheParcels(unopenedParcels)
      isCaching = false
    } else {
      startLookup()
    }
  }

  function openContactDetail () {
    viewStack.push('ContactDetail', { contact })
  }

  function startLookup () {
    lookup.view(contact.id, contact.displayName, contact.publicKey)
  }

  function calculateStatus (contact, totalParcelCount) {
    const status = { label: null, time: null, icon: null }

    const lastReceivedAt = parseIsoString(contact.lastReceivedAt)
    const lastSuccessfullySentAt = parseIsoString(contact.lastSuccessfullySentAt)
    const lastOpenedAt = parseIsoString(contact.lastOpenedAt)

    if (contact.error != null) {
      status.label = 'Failed to send'
      status.icon = 'alert-circle'
      status.time = dumeToNow(parseIsoString(contact.error.time))
      status.cssClass = 'font-weight-bold'
    } else if (totalParcelCount > 0) {
      status.label = 'New'
      status.icon = 'box'
      status.time = dumeToNow(lastReceivedAt)
    } else if (lastReceivedAt && lastSuccessfullySentAt < lastReceivedAt) {
      status.label = 'Received'
      status.icon = 'box'
      status.time = dumeToNow(lastReceivedAt)
    } else if (lastSuccessfullySentAt) {
      status.label = 'Sent'
      status.icon = 'send'
      status.time = dumeToNow(lastSuccessfullySentAt)

      if (lastSuccessfullySentAt < lastOpenedAt) {
        status.label += ' (Opened)'
      }
    } else if (contact.exchangeCompletedAt) {
      status.label = 'Ready'
      status.icon = 'user-check'
      status.time = dumeToNow(parseIsoString(contact.exchangeCompletedAt))
    } else {
      status.label = 'Waiting for them to confirm invite'
      status.icon = 'user'
    }

    return status
  }

  async function onRefresh (refreshKey) {
    // NOTE: this is a hack! refreshKey is a prop that the Inbox always increments when
    // a refresh is triggered. after the initial value `0` every increment is a refresh.
    // the items are rendered before the first refresh.
    if (refreshKey === 0) {
      return
    }

    checkIsContactAccepting(contact)

    if (!$settings.disableAutomaticParcelDownload && totalParcelCount > 0) {
      isCaching = true
      await cacheParcels(unopenedParcels)
      isCaching = false
    }
  }

  $: unopenedParcels = $unopenedParcelsStore.filter((parcel) => parcel.uploadedBy === contact.id)
  $: totalParcelCount = unopenedParcels.length
  $: cachedParcelCount = unopenedParcels.filter((parcel) => parcel.isCached).length
  $: activeLookupCount = unopenedParcels.filter((parcel) => parcel.openedAt != null).length
  $: isUploading = $uploadingProgress && $uploadingProgress?.recipientIds.includes(contact.id)
  $: status = calculateStatus(contact, totalParcelCount)
  $: level = contact.points ? getLevelProperties(contact.points) : null

  $: onRefresh(refreshKey)
</script>

<div class="item" use:longpress on:longpress={openContactDetail}>
  <button on:click={clickPrimary}>
    {contact.displayName}<br />

    {#if isUploading}
      <small>
        <svg class="feather-icon -rotate-animation"><use href="feather-sprite.svg#loader"/></svg>
        Sending...
      </small>
    {:else if status}
      <small class={status.cssClass}>
        <svg class="feather-icon">
          <use href="feather-sprite.svg#{status.icon}"/>
        </svg>

        {status.label}
        {#if status.time} ⋅ {status.time} ago{/if}
        {#if cachedParcelCount !== totalParcelCount} ⋅ {cachedParcelCount}/{totalParcelCount} Cached{/if}
      </small>
    {/if}
  </button>

  <div class="right-column">
    {#if level?.isVisible}
      <button on:click={openContactDetail} class="level">
        <div class="label">
          {level.label}
          <SplashEffect requestToShow={parseIsoString(contact.points.updatedAt) > Date.now() - 10000} />
        </div>

        {#if parseInt(level.progressPercentage) > 3}
          <div class="progress-container">
            <div class="progress" style="height: {level.progressPercentage}%"></div>
          </div>
        {/if}
      </button>
    {/if}

    {#if totalParcelCount > 0}
      <div class="lookup-badge">
        <button
          on:click={clickLookupBadge}
          class="{activeLookupCount > 0 ? '-with-pulse' : ''}"
          disabled={cachedParcelCount === 0 && isCaching}
        >
          {#if cachedParcelCount === 0}
            <svg class="feather-icon {isCaching ? '-rotate-animation' : '' }">
              <use href="feather-sprite.svg#{isCaching ? 'loader' : 'download'}"/>
            </svg>
          {:else if (cachedParcelCount - activeLookupCount) > 0}
            {cachedParcelCount - activeLookupCount}
          {/if}
        </button>
      </div>
    {/if}
  </div>
</div>

<style>
  .item {
    border-bottom: 1px solid var(--dark-light-grey);
    padding: 0 1rem 0 0;
    margin-left: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    transform: scale(1);
    transition: transform 600ms cubic-bezier(1,0,.6,-0.8);
  }

  .item:global(.longpress-active) {
    transform: scale(1.05);
  }

  .item:last-child {
    border-bottom: none;
  }

  .item > button {
    background: inherit;
    color: inherit;
    border: none;
    padding: 0.8rem 0;
    width: 100%;
    text-align: left;
    line-height: 1.2;
    -webkit-tap-highlight-color: transparent;
  }

  .item > button > small {
    color: var(--dark-grey);
  }

  .item small > .feather-icon {
    width: 0.8rem;
    height: 0.8rem;
    position: relative;
    top: 2px;
  }

  .item small.font-weight-bold > .feather-icon {
    stroke-width: 3px;
  }

  .item .lookup-badge {
    display: flex;
    align-items: baseline;
    margin-left: 0.8rem;
  }

  .item .lookup-badge > button > .feather-icon {
    width: 1.2rem;
  }

  .item .lookup-badge > button {
    min-width: 2.5rem;
    min-height: 2rem;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    padding: 0.5rem;
    background-color: var(--brand-color);
    color: var(--white);
    border-radius: 0.3rem;
    border: none;
  }

  .item .lookup-badge > button.-with-pulse {
    content: '';
    display: block;
    box-shadow: 0 0 0.5rem 0 var(--brand-color);
    animation: brand-shadow-pulse 1s infinite;
  }

  .item > .right-column {
    display: flex;
  }

  .item > .right-column > .level {
    width: max-content;
    height: 1.4rem;
    align-self: center;
    line-height: 1;
    text-align: right;

    /* reset because of button: */
    display: block;
    background-color: transparent;
    border: none;
    color: inherit;
    padding: 0;
    margin: 0;
  }

  .item > .right-column > .level > .label {
    display: inline-block;
    vertical-align: top;
    font-size: 1.5rem;
  }

  .item > .right-column > .level > .progress-container {
    display: inline-block;
    width: 0.4rem;
    height: 100%;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    background-color: var(--light-grey);
  }

  .item > .right-column > .level > .progress-container > .progress {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: var(--brand-color);
  }
</style>
