import { writable } from 'svelte/store'
import { database } from './indexeddb.js'

const STORE_NAME = 'contacts'

export function createContactsStore () {
  const { subscribe, set } = writable([])

  async function load (tx) {
    let contacts

    if (tx) {
      contacts = await tx.store.getAll()
    } else {
      contacts = await database.getAll(STORE_NAME)
    }

    set(contacts)
    return contacts
  }

  return {
    subscribe,
    load,

    async set (contact) {
      await database.put(STORE_NAME, contact)
      await load()
    },

    async get (id) {
      return await database.get(STORE_NAME, id)
    },

    async getSelection (contactIds) {
      const tx = database.transaction(STORE_NAME, 'readonly')

      const result = []

      for (const contactId of contactIds) {
        result.push(await tx.store.get(contactId))
      }

      await tx.done

      return result
    },

    async delete (id) {
      await database.delete(STORE_NAME, id)
      await load()
    },

    async getBulkTemplate (propertyKey, contactIds) {
      const tx = database.transaction(STORE_NAME, 'readonly')

      const template = new Map()

      for (const contactId of contactIds) {
        const contact = await tx.store.get(contactId)

        template.set(contactId, contact[propertyKey])
      }

      await tx.done

      return template
    },

    async bulkUpdate (propertyKey, changes) {
      const tx = database.transaction(STORE_NAME, 'readwrite')

      for (const [contactId, newProperty] of changes) {
        const contact = await tx.store.get(contactId)

        if (newProperty instanceof Set) {
          contact[propertyKey] = Array.from(newProperty)
        } else {
          contact[propertyKey] = newProperty
        }

        await tx.store.put(contact)
      }

      await load(tx)
      await tx.done
    },

    async clearAll () {
      await database.clear(STORE_NAME)
      await load()
    }
  }
}
