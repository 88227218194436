<script>
  import { settings, viewStack } from '../../stores'
  import ViewHeader from '../ViewHeader'
  import { box } from 'tweetnacl'
  import { checkForAppUpdate } from '../../functions/update-app.js'

  let displayName = null

  async function createProfile () {
    const settingSetsPromises = []

    if (!keypairExists) {
      const { secretKey, publicKey } = box.keyPair()

      settingSetsPromises.push(settings.set('publicKey', publicKey))
      settingSetsPromises.push(settings.set('privateKey', secretKey))
    }

    settingSetsPromises.push(settings.set('displayName', displayName))

    // NOTE: Necessary to wait for them all in one place, because otherwise the store subscribe handler
    // will be triggered too often and App.svelte confuses what views to close/open
    await Promise.allSettled(settingSetsPromises)

    if (keypairExists && $settings.inboxServerHost) {
      window.location.reload()
      return
    }

    viewStack.pop()
    viewStack.push('ContactExchangeAccept', { changeInboxServerHost: true })
  }

  $: isDisplayNameEmpty = displayName == null || displayName?.trim() === ''
  $: keypairExists = $settings.publicKey && $settings.privateKey

  viewStack.disableClose()

  checkForAppUpdate($settings.isDebugMode)
</script>

<ViewHeader disableClose>Hello! Let's set you up...</ViewHeader>

{#if keypairExists}
  <div class="content-box -has-warning">
    <p>Your piqchat identity is already set and won't be regenerated.</p>
  </div>

  <div class="vertical-empty-space-half" />
{/if}

<div class="form">
  <label for="displayName" class="label">Your Nickname</label>
  <input id="displayName" class="input" bind:value={displayName} />

  {#if displayName != null && isDisplayNameEmpty}
    <p class="text-color-error">You definitely need a great nickname! Nothing does not count.</p>
  {:else}
    <p><small>This nickname will be suggested to your friends when they add you.</small></p>
  {/if}

  <div class="vertical-empty-space" />

  <p class="text-align-center">
    <button on:click={createProfile} disabled={isDisplayNameEmpty} class="-button-primary full-width">
      Continue
    </button>
  </p>
</div>

<div class="vertical-empty-space-2x" />

<p class="display-flex justify-content-space-between">
  <button on:click={() => viewStack.push('ClientPrivacyPolicy')} class="-button-link">
    Privacy policy
  </button>

  <button on:click={() => viewStack.push('RecoverProfile')} class="-button-link">
    Recover profile
  </button>
</p>
