<script>
  import { inboxApi } from '../../api'
  import { settings, unopenedParcels, viewStack } from '../../stores'
  import { clearQueue } from '../../functions/upload-queue.js'
  import ViewHeader from '../ViewHeader'
  import { deleteServiceWorkerKeyValueStore } from '../../functions/service-worker-key-value-store.js'
  import StorageSetting from '../StorageSetting.svelte'

  let resetStorageSelected = null

  async function showConfirmForStorageReset () {
    const text = `Are you sure you want to delete the local data of "${resetStorageSelected}"?`
    if (!window.confirm(text)) {
      resetStorageSelected = null
      return
    }

    switch (resetStorageSelected) {
      case 'queue':
        await clearQueue()
        break
      case 'inbox':
        await inboxApi.parcelRequests.clearAllFromCache()
        await unopenedParcels.clearAll()
        break
      case 'service-worker-key-value-store':
        await deleteServiceWorkerKeyValueStore()
        break
    }

    resetStorageSelected = null
    viewStack.pop()
  }

  async function toggleSetting (key) {
    await settings.set(key, !$settings[key])
  }
</script>

<ViewHeader>Advanced Settings</ViewHeader>

<div class="form -oneline-checkbox">
  <input
    id="disableAutomaticCameraStart"
    class="input"
    type="checkbox"
    checked={$settings.disableAutomaticCameraStart}
    readonly
    on:click={() => toggleSetting('disableAutomaticCameraStart')}
  />
  <label for="disableAutomaticCameraStart" class="label">Disable automatic camera start</label>
  <span class="info">
    Useful on iOS devices to prevent iOS from constantly asking about camera permissions.
  </span>
</div>

<div class="form -oneline-checkbox">
  <input
    id="disableAutomaticParcelDownload"
    class="input"
    type="checkbox"
    checked={!$settings.disableAutomaticParcelDownload}
    readonly
    on:click={() => toggleSetting('disableAutomaticParcelDownload')}
  />
  <label for="disableAutomaticParcelDownload" class="label">Download pics automatically</label>
  <span class="info">
    If disabled, you have more control over when pics are downloaded to preserve precious mobile data.
  </span>
</div>

<div class="form -oneline-checkbox">
  <input
    id="tapAnywhereToShoot"
    class="input"
    type="checkbox"
    checked={$settings.tapAnywhereToShoot}
    readonly
    on:click={() => toggleSetting('tapAnywhereToShoot')}
  />
  <label for="tapAnywhereToShoot" class="label">Tap anywhere to shoot pic</label>
  <span class="info">
    If you find it inconvenient to press the white circle at the bottom of the camera.
  </span>
</div>

{#if 'storage' in window.navigator}
  <div class="vertical-empty-space-half" />

  <StorageSetting />
{/if}

<div class="vertical-empty-space-half" />

<h4>Experiments & Debugging</h4>

<div class="form -oneline-checkbox">
  <input
    id="isDebugMode"
    class="input"
    type="checkbox"
    checked={$settings.isDebugMode}
    readonly
    on:click={() => toggleSetting('isDebugMode')}
  />
  <label for="isDebugMode" class="label">Enable debug mode</label>
  <span class="info">
    Logs more stuff and may change behaviour or show some additional info or button at some places.
  </span>
</div>

<div class="form -oneline-checkbox">
  <input
    id="enableExperimentalVideoSupport"
    class="input"
    type="checkbox"
    checked={$settings.enableExperimentalVideoSupport}
    readonly
    on:click={() => toggleSetting('enableExperimentalVideoSupport')}
  />
  <label for="enableExperimentalVideoSupport" class="label">Enable experimental video support</label>
  <span class="info">
    It's a really rough feature. Note: Every client can play videos without this setting enabled.
    However, the iOS Safari client can't play videos from Android clients. There is currently no time limit and no audio.
  </span>
</div>

<div class="vertical-empty-space" />

<div class="content-box -has-error">
  <header class="text-color-error">
    Danger Zone: Delete Storage
  </header>

  <p>You may need this to fix a bug which prevents you from using the app. This may result in data loss.</p>

  <div class="button-row">
    <select bind:value={resetStorageSelected} on:change={showConfirmForStorageReset} style:width="100%">
      <option value={null} disabled>Select what to delete...</option>
      <option value="queue">Upload Queue</option>
      <option value="inbox">Inbox & Cache</option>
      <option value="service-worker-key-value-store">App Badge & Contact Names for Notifications</option>
    </select>
  </div>
</div>
