export const BROWSER_STORAGE_PREFIX = 'piqchat' // NOTE: this is duplicated in service worker!
export const APP_NAME = 'piqchat.net'

export const MEDIA_TEXT_TYPES = { OVERLAY: 'OVERLAY', FREE: 'FREE' }
export const MEDIA_DURATION_OPTIONS = [1, 4, 9, null]
export const MEDIA_DURATION_OPTIONS_LABELS = ['blink', 'breath', 'classic', 'chill']
export const MEDIA_WIDTH = 1080
export const MEDIA_HEIGHT = 1920
export const MEDIA_FILTERS = [
  { label: '#nofilter' },
  { label: 'Soften', filter: 'brightness(110%) saturate(90%) sepia(10%)', blendMode: 'soft-light', blendColor: 'rgb(208, 186, 142)' },
  { label: 'Sepia', filter: 'contrast(115%) saturate(120%) sepia(50%)', blendMode: 'multiply', blendColor: 'rgb(178, 93, 118)', blendOpacity: '0.25' },
  { label: 'Inkwell', filter: 'contrast(110%) brightness(110%) sepia(30%) grayscale(100%)', blendColor: 'rgb(200, 200, 200)' },
  { label: 'Purple', filter: 'brightness(80%) saturate(129%)', blendMode: 'screen', blendColor: 'rgb(112, 24, 95)', blendOpacity: '0.5' },
  // { label: 'Blue X', filter: 'contrast(150%) brightness(80%) saturate(150%)', blendMode: 'screen', blendColor: 'rgb(76, 115, 140)', blendOpacity: '0.6' },
  { label: 'Blue X2', filter: 'contrast(130%) brightness(90%) saturate(170%)', blendMode: 'hard-light', blendColor: 'rgb(137, 171, 192)', blendOpacity: '0.5' }
]

export const LEVEL_EMOJIS = [
  '🐣',
  '🐌',
  '🦊',
  '🦋',
  '🐗',
  '🐘',
  '🦁',
  '🦉',
  '🦄',
  '🐝',
  '🐳',
  '🐢',
  '🦕'
]

export const RELOAD_HANDLING_TYPES = { ASK: 'ASK', FORCE: 'FORCE', NONE: 'NONE' }

export const IS_IOS = /iPhone|iPad/.test(navigator.userAgent)

export const LAST_SEEN_LABELS = {
  NEVER: 'never',
  LONG_TIME: 'a long time ago',
  WITHIN_MONTH: 'within a month',
  WITHIN_WEEK: 'within a week',
  RECENTLY: 'recently'
}

export const PARCEL_TYPES = { IMAGE: 'IMAGE', MEDIA: 'MEDIA', CONTACT: 'CONTACT', UPDATE_INBOX_SERVER: 'UPDATE_INBOX_SERVER' }
export const UPLOAD_QUEUE_ITEM_TYPES = { IMAGE: 'IMAGE', MEDIA_GROUP: 'MEDIA_GROUP' }

export const SCAN_MODE_TYPE = { SAVE_CONTACT: 'SAVE_CONTACT', SIGNUP_INBOX: 'SIGNUP_INBOX' }

export const CONTACT_EXCHANGE_HOST_PLACEHOLDER = 'input_inbox_server_host' // This is lowercase because it is part of an URL

export const APP_WRAPPER_MESSAGE_TYPES = {
  OUTGOING: {
    NOTIFICATION_REQUEST_PERMISSION: 'NOTIFICATION_REQUEST_PERMISSION',
    NOTIFICATION_CLOSE_ALL: 'NOTIFICATION_CLOSE_ALL',
    NOTIFICATION_SET_BADGE: 'NOTIFICATION_SET_BADGE',
    NOTIFICATION_SET_CONTACTS: 'NOTIFICATION_SET_CONTACTS',
    PING: 'PING'
  },

  INCOMING: {
    NOTIFICATION_CLICKED: 'NOTIFICATION_CLICKED',
    NOTIFICATION_RECEIVED: 'NOTIFICATION_RECEIVED',
    NOTIFICATION_PERMISSION: 'NOTIFICATION_PERMISSION',
    NOTIFICATION_PERMISSION_TYPES: {
      DENIED: 'DENIED',
      SUBSCRIPTION_DATA: 'SUBSCRIPTION_DATA',
      PUSH_ENABLE_FAIL: 'PUSH_ENABLE_FAIL'
    },
    PING_RESPONSE: 'PING_RESPONSE'
  }
}
