import { logger } from '../stores'
import { APP_WRAPPER_MESSAGE_TYPES } from '../constants.js'
import { sendMessageToAppWrapper, subscribeAppWrapperMessageOnce, isInAppWrapper } from './app-wrapper-communication.js'

export function isSupported () {
  return isInAppWrapper()
}

export async function createSubscription () {
  const response = subscribeAppWrapperMessageOnce(APP_WRAPPER_MESSAGE_TYPES.INCOMING.NOTIFICATION_PERMISSION)

  sendMessageToAppWrapper({ type: APP_WRAPPER_MESSAGE_TYPES.OUTGOING.NOTIFICATION_REQUEST_PERMISSION })

  const message = await response

  if (message.permissionType === APP_WRAPPER_MESSAGE_TYPES.INCOMING.NOTIFICATION_PERMISSION_TYPES.DENIED) {
    throw new Error('Permission to use push notifications was denied.')
  }

  if (message.permissionType === APP_WRAPPER_MESSAGE_TYPES.INCOMING.NOTIFICATION_PERMISSION_TYPES.PUSH_ENABLE_FAIL) {
    throw new Error('Push functionality could not be enabled.')
  }

  if (message.permissionType !== APP_WRAPPER_MESSAGE_TYPES.INCOMING.NOTIFICATION_PERMISSION_TYPES.SUBSCRIPTION_DATA || !message.subscriptionData) {
    logger.log(`App Wrapper sent unknown permissionType: ${message?.permissionType}.`)
    throw new Error('The app wrapper has sent an unknown response to the request to activate push notifications.')
  }

  const subscription = message.subscriptionData

  logger.log(`New subscription data received by the app wrapper. Endpoint "${subscription.endpoint}"`)

  return {
    endpoint: subscription.endpoint,
    keys: {
      auth: subscription.keys.auth,
      p256dh: subscription.keys.p256dh
    },
    encoding: subscription.encoding
  }
}

export async function revokeSubscription () {
  // This relies on the presence or not-presence of a subscription on the users inbox server.
  // A truthy value signals that this operation was successfull.
  return true
}

export async function checkPermissions () {
  // This relies on the presence or not-presence of a subscription on the users inbox server.
  return true
}
