export async function fileToImage (file) {
  const image = new Image()

  await new Promise((resolve) => {
    const url = URL.createObjectURL(file)

    image.onload = function () {
      resolve(image)
      URL.revokeObjectURL(image.src)
    }

    image.src = url
  })

  return image
}

export function imageToImageData (image, width, height) {
  const context = document.createElement('canvas').getContext('2d')

  context.canvas.width = width
  context.canvas.height = height

  context.drawImage(image, 0, 0)

  return context.getImageData(0, 0, width, height)
}
