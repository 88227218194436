import { logger } from '../stores'
import { drawImageOnCanvas, calculateCoordinatesWithFit } from '../functions/draw-image-on-canvas.js'
import { MEDIA_WIDTH, MEDIA_HEIGHT } from '../constants.js'

export function createVideoRecorder (videoElement, postDrawFn) {
  const currentPosition = calculateCoordinatesWithFit(videoElement.videoWidth, videoElement.videoHeight, 'cover')
  let videoFrameCallbackId

  let recordPromiseResolveFn
  let recordPromiseRejectFn
  const recordPromise = new Promise((resolve, reject) => {
    recordPromiseResolveFn = resolve
    recordPromiseRejectFn = reject
  })

  const offscreenCanvasElement = document.createElement('canvas')

  offscreenCanvasElement.width = MEDIA_WIDTH
  offscreenCanvasElement.height = MEDIA_HEIGHT
  offscreenCanvasElement.getContext('2d') // NOTE: firefox needs to get the context otherwise captureStream() does not work

  const blobs = []
  const mimeType = MediaRecorder.isTypeSupported('video/mp4') ? 'video/mp4' : 'video/webm'
  const mediaRecorder = new MediaRecorder(offscreenCanvasElement.captureStream(), {
    mimeType,
    videoBitsPerSecond: 5 * 1000 * 1000
  })

  videoElement.addEventListener('ended', videoOnEnded)
  mediaRecorder.addEventListener('dataavailable', onMediaRecorderDataAvailable)
  mediaRecorder.addEventListener('error', onMediaRecorderError)
  mediaRecorder.addEventListener('stop', onMediaRecorderStop)

  function videoOnEnded () {
    console.log('videoRecorder/videoOnEnded')
    mediaRecorder.stop()
  }

  function onMediaRecorderDataAvailable (event) {
    console.log('videoRecorder/onMediaRecorderDataAvailable')

    if (event.data) {
      blobs.push(event.data)
    }
  }

  function onMediaRecorderError (error) {
    console.log('videoRecorder/onMediaRecorderError', error)

    recordPromiseRejectFn(error)
  }

  function onMediaRecorderStop () {
    console.log('videoRecorder/onMediaRecorderStop')

    videoElement.removeEventListener('ended', videoOnEnded)
    cancelVideoFrameCallback()
    videoElement.loop = true

    if (!recordPromiseRejectFn) {
      return
    }

    if (blobs.length > 0) {
      logger.log(`videoRecorder/onMediaRecorderStop export with mimetype ${mediaRecorder.mimeType} and bitrate ${mediaRecorder.bitsPerSecond} (video: ${mediaRecorder.videoBitsPerSecond})`)
      const blob = new Blob(blobs, { type: mediaRecorder.mimeType })

      recordPromiseResolveFn(blob)
    } else {
      recordPromiseRejectFn(new Error('VideoRecord no data available'))
    }
  }

  function drawVideoFrame () {
    drawImageOnCanvas(videoElement, offscreenCanvasElement, currentPosition)

    if (postDrawFn) {
      postDrawFn(offscreenCanvasElement.getContext('2d'))
    }

    if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
      videoFrameCallbackId = videoElement.requestVideoFrameCallback(drawVideoFrame)
    } else {
      videoFrameCallbackId = requestAnimationFrame(drawVideoFrame)
    }
  }

  function cancelVideoFrameCallback () {
    if ('cancelVideoFrameCallback' in HTMLVideoElement.prototype) {
      videoElement.cancelVideoFrameCallback(videoFrameCallbackId)
    } else {
      cancelAnimationFrame(videoFrameCallbackId)
    }
  }

  return {
    async record () {
      videoElement.pause()

      videoElement.loop = false
      videoElement.currentTime = 0

      await videoElement.play()

      cancelVideoFrameCallback()
      drawVideoFrame()

      mediaRecorder.start()

      return recordPromise
    },

    stop () {
      console.log('videoRecorder/abort')

      mediaRecorder.stop()
    },

    abort () {
      console.log('videoRecorder/abort')
      recordPromiseRejectFn(new Error('VideoRecord abort called'))
      recordPromiseRejectFn = null

      mediaRecorder.stop()
    }
  }
}
