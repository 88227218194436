<script>
  import { settings, isUnauthorized as isUnauthorizedStore, viewStack } from '../stores'

  export let contact = null

  $: host = contact ? contact.inboxServerHost : $settings.inboxServerHost
  $: inboxServerHost = $settings.allInboxServerMap[host]
  $: isUnauthorized = !contact && $isUnauthorizedStore
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class:-has-error={isUnauthorized || inboxServerHost?.isDown !== false}
  on:click={() => viewStack.push('InboxServerDetail', { inboxServerHost: host })}
>
  <p class="display-flex">
    <svg class="feather-icon flex-shrink-0">
      <use href="feather-sprite.svg#hard-drive"/>
    </svg>

    <span>
      {#if contact}Their{:else}Your current{/if}
      inbox server is provided by "{inboxServerHost?.adminDisplayName}" on <code>{host}</code>
    </span>
  </p>

  <footer class="text-align-right">
    <span>
      {#if inboxServerHost?.isDown !== false}
        Connection couldn't be established!
      {:else if isUnauthorized}
        You are not authorized!
      {:else}
        More info...
      {/if}
    </span>

    <svg class="feather-icon -normalize">
      <use href="feather-sprite.svg#arrow-right-circle"/>
    </svg>
  </footer>
</div>

<style>
  div {
    background-color: var(--light-grey);
    padding-top: 0.1rem;
    border-radius: var(--content-box-border-radius);
    overflow: hidden;
    margin: 1rem;
  }

  div.-has-error {
    background-color: var(--error-background-color);
  }

  code {
    word-break: keep-all;
    font-size: 0.9em;
    background-color: color-mix(in srgb, var(--white) 80%, transparent);
    padding: 0.1em;
    border-radius: 3px;
  }

  div > p > .feather-icon {
    margin-top: 0.3rem;
    margin-right: 0.6rem;
    width: 1.4rem;
    height: 1.4rem;
  }

  div.-has-error > p > .feather-icon {
    color: var(--error-color);
  }

  footer {
    background-color: var(--dark-light-grey);
    padding: 0.2rem 1rem 0.2rem 3rem;
    font-size: 0.8rem;
    font-weight: bold;
    color: var(--black);
  }

  div.-has-error > footer {
    background-color: var(--error-color);
  }

  footer .feather-icon {
    position: relative;
    top: 2px;
    stroke-width: 3px;
  }
</style>
