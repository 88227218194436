<script>
  import { createEventDispatcher } from 'svelte'
  import { box } from 'tweetnacl'
  import { decodeBase64 } from 'tweetnacl-util'
  import { contactExchangeActive, contacts, viewStack, settings, contactSuggestionsParcels } from '../stores'
  import PopoverList from './PopoverList.svelte'
  import { unpackUint8Array } from '../functions/pack-uint8array.js'
  import { parseEncryptedContact } from '../functions/contact-exchange-coder.js'
  import { checkActiveContactExchange } from '../functions/check-contact-exchange.js'
  import { inboxApi } from '../api'

  const dispatch = createEventDispatcher()

  $: hasActiveContactExchange = $contactExchangeActive != null
  $: contactSuggestions = transformParcelsToSuggestions($contactSuggestionsParcels, $settings.privateKey)

  const decidedHandlerFn = (contactParcelId) => {
    return async () => {
      await inboxApi.parcelRequests.delete(contactParcelId)

      dispatch('change')
    }
  }

  function transformParcelsToSuggestions (suggestionParcels, privateKey) {
    if (suggestionParcels.length === 0) {
      return
    }

    const contactDisplayNameMap = new Map($contacts.map((contact) => [contact.id, contact]))

    function mapParcelToSuggestion (parcel) {
      const sender = contactDisplayNameMap.get(parcel.uploadedBy)

      if (!sender) {
        return null
      }

      const content = JSON.parse(parcel.content)
      const encryptedSecretKeyAndNonce = decodeBase64(content.secretKey)
      const { flexibleArray: encryptedSecretKey, fixedArrays: arrayWithNonce } = unpackUint8Array(encryptedSecretKeyAndNonce, [box.nonceLength])

      const secretKey = box.open(
        encryptedSecretKey,
        arrayWithNonce[0],
        sender.publicKey,
        privateKey
      )

      const { displayName } = parseEncryptedContact(content.contact, secretKey)

      return {
        sender,
        displayName,
        viewPayload: {
          encryptedContact: content.contact,
          secretKey,
          decidedHandlerFn: decidedHandlerFn(parcel.id),
          message: 'You have been suggested this contact by a friend. You can only send pics to each other if you save each other as contacts.'
        }
      }
    }

    return suggestionParcels.map(mapParcelToSuggestion)
  }

  checkActiveContactExchange()
</script>

<div class="content-box">
  <header>
    <span>
      <svg class="feather-icon -normalize"><use href="feather-sprite.svg#users"/></svg>
      Add friend
    </span>

    <PopoverList options={[
      { label: 'Invite tree...', handler: () => viewStack.push('InviteTree') },
      { label: 'Match friends...', handler: () => viewStack.push('ContactMatcher') }
    ]} />
  </header>

  <div class="button-row">
    <button class="-button-secondary -button-small" on:click={() => viewStack.push('ContactExchangeAccept')}>
      Accept invite
      <svg class="feather-icon"><use href="feather-sprite.svg#maximize" /></svg>
    </button>

    <button
      class="-button-secondary -button-small create"
      on:click={() => viewStack.push('ContactExchangeOverview')}
    >
      {#if hasActiveContactExchange}Pending{:else}Create{/if} invite
      <svg class="feather-icon"><use href="feather-sprite.svg#share-2" /></svg>
    </button>
  </div>

  {#if contactSuggestions?.length > 0}
    <div class="vertical-empty-space-half" />
    {#each contactSuggestions as suggestion}
      {#if suggestion}
        <p>
          {suggestion.sender.displayName} suggests
          <button
            class="-button-link"
            on:click={() => viewStack.push('ContactExchangeSaveContact', suggestion.viewPayload)}
          >
            {suggestion.displayName}
          </button>
          as friend
        </p>
      {/if}
    {/each}
  {/if}
</div>
