<script>
  import { viewStack, logger } from '../../stores'
  import { deleteLocalData } from '../../functions/delete-local-data.js'
  import { countItemsInQueue } from '../../functions/upload-queue'
  import { inboxApi } from '../../api'
  import { revokeSubscription } from '../../functions/push-notifications.js'
  import ViewHeader from '../ViewHeader'
  import ProfileRecoverySetting from '../ProfileRecoverySetting'

  let isLoading = false
  let errorMessage
  let queueCount = 0

  async function logout () {
    isLoading = true
    viewStack.disableClose()

    try {
      inboxApi.accountRequests.updatePushSubscription({})
      await revokeSubscription()

      await deleteLocalData()

      window.location.reload()
    } catch (error) {
      errorMessage = `There was an error. ${error.message}`

      logger.error(error, 'Logout')

      isLoading = false
    }
  }

  async function setup () {
    queueCount = await countItemsInQueue()
  }

  setup()
</script>

<ViewHeader disableClose={isLoading}>Until next time! 👋</ViewHeader>

{#if errorMessage}
  <p class="text-color-error">Logout failed. {errorMessage}</p>
  <div class="vertical-empty-space-half" />
{/if}

{#if queueCount > 0}
  <p>You have {queueCount} unsent pic{#if queueCount > 1}s{/if} which will be deleted on logout.</p>
{/if}

<div class="vertical-empty-space-half" />

<ProfileRecoverySetting showOnlyCopyPrompt copyPromptSuffix="You need it to login again." backupInSetup />

<div class="vertical-empty-space" />

<p class="text-align-center">
  <button class="-button-primary" on:click={logout} disabled={isLoading || errorMessage}>
    Logout

    {#if isLoading}
      <svg class="feather-icon -rotate-animation"><use href="feather-sprite.svg#loader"/></svg>
    {/if}
  </button>
</p>

<div class="vertical-empty-space-2x" />
