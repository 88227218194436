<script>
  import { viewStack, isOnline } from '../stores'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  export let popWhenClose = true
  export let disableClose = false
  export let showOfflineInfo = false

  function close () {
    if (popWhenClose) {
      viewStack.pop()
    } else {
      dispatch('close')
    }
  }
</script>

<header>
  <h2><slot></slot></h2>

  <div class="display-flex">
    <slot name="secondary"></slot>

    {#if !disableClose}
      <button class="-button-reset" on:click={close}>
        <svg class="feather-icon">
          <use href="feather-sprite.svg#x"/>
        </svg>
      </button>
    {/if}
  </div>

  {#if showOfflineInfo && !$isOnline}
    <div class="offline-info">
      <svg class="feather-icon -normalize"><use href="feather-sprite.svg#wifi-off"/></svg>

      You are offline
    </div>
  {/if}
</header>

<style>
  header {
    position: sticky;
    top: 0;
    background-color: inherit;
    box-shadow: 0 0 20px 0 var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
  }

  h2 {
    margin-bottom: 0.8rem;
    position: relative;
    flex-grow: 1;
  }

  header :global(h3) {
    margin: 0;
    font-size: 0.8rem;
    font-weight: normal;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -1rem;
  }

  .offline-info {
    color: var(--dark-grey);
    background-color: var(--light-grey);
    font-size: 0.6rem;
    padding: 0.3rem 0.5rem 0.1rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
</style>
