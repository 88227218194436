import { MEDIA_WIDTH, MEDIA_HEIGHT, MEDIA_FILTERS } from '../constants.js'

export function drawImageOnCanvas (source, canvas, coordinates, filterBlendIndex) {
  const context = canvas.getContext('2d')

  context.filter = 'none' // NOTE: reset for iOS Safari
  canvas.width = MEDIA_WIDTH
  canvas.height = MEDIA_HEIGHT

  if (filterBlendIndex != null) {
    const filter = MEDIA_FILTERS[filterBlendIndex] ?? filterBlendIndex

    if (filter.filter) {
      context.filter = filter.filter
    }
  }

  context.drawImage(source, coordinates.x, coordinates.y, coordinates.width, coordinates.height)

  if (filterBlendIndex != null) {
    const filter = MEDIA_FILTERS[filterBlendIndex] ?? filterBlendIndex

    if (filter.blendMode && filter.blendColor) {
      context.save()
      context.globalCompositeOperation = filter.blendMode
      context.fillStyle = filter.blendColor
      context.globalAlpha = filter.blendOpacity
      context.fillRect(0, 0, canvas.width, canvas.height)
      context.restore()
    }
  }
}

export function calculateCoordinatesWithFit (sourceWidth, sourceHeight, fit) {
  let width = MEDIA_WIDTH
  let height = MEDIA_HEIGHT
  let y = 0
  let x = 0

  const ratioOfSourceToTarget = MEDIA_WIDTH / MEDIA_HEIGHT > sourceWidth / sourceHeight

  if ((fit === 'contain' && !ratioOfSourceToTarget) || (fit === 'cover' && ratioOfSourceToTarget)) {
    // If the ratio of the source is smaller than the source would overflow vertically
    height = width * sourceHeight / sourceWidth
  } else {
    // If the ratio of the source is larger than the source would overflow horizontally
    width = height * sourceWidth / sourceHeight
  }

  y = (MEDIA_HEIGHT - height) / 2
  x = (MEDIA_WIDTH - width) / 2

  return { x, y, width, height }
}
