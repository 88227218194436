<script>
  import ViewHeader from '../ViewHeader'
  import { viewStack, settings } from '../../stores'

  async function toggleSetting (key) {
    await settings.set(key, !$settings[key])
  }
</script>

<ViewHeader>iOS Quirks 🍏</ViewHeader>

<p>Bugs that only apple can fix.</p>

<details name="ios-quirks">
  <summary>Why is the camera sometimes just showing a black screen?</summary>

  <p><em>This was fixed by Apple with the iOS 17 update.</em></p>

  <p>
    Sometimes the in-app camera only shows a black screen.
    Unfortunately, there is no easy way to get the in-app camera working again.
    So to the rescue, you can use the backup camera of iOS!
  </p>

  <p>
    What you can do, if you want the in-app camera back: Wait a few hours (or days).
    Or restart the device (always works). Or try to restart the app multiple times.
  </p>

  <p>
    Which is all ridiculous, I know.
    I experience the bug almost only when I close the piqchat app in the
    app task switcher. So maaaaaybe just don't do that 😬
  </p>

  <p>Tip: You can always access this backup camera via the photo library button.</p>
</details>

<details name="ios-quirks">
  <summary>Why am I constantly asked "piqchat would like to access the camera"?</summary>

  <p>
    Hopefully this is fixed with iOS 17.2 (december 2023). Because the app opens to
    the camera view, there is the following option:
  </p>

  <div class="form -oneline-checkbox">
    <input
      id="disableAutomaticCameraStart"
      class="input"
      type="checkbox"
      checked={$settings.disableAutomaticCameraStart}
      readonly
      on:click={() => toggleSetting('disableAutomaticCameraStart')}
    />
    <label for="disableAutomaticCameraStart" class="label">Disable automatic camera start</label>
  </div>

  <p>
    When disabled, it will only prompt you if you click the "Start camera" button yourself. Maybe you will find that less annoying.
    You can also find this option in the <button class="-button-link" on:click={() => viewStack.push('Settings')}>Advanced Settings</button>.
  </p>
</details>

<details name="ios-quirks">
  <summary>Why do notifications not disappear automatically?</summary>

  <p>
    Notifications are a relative new feature for web apps (released in spring 2023 with iOS 16.4). Currently, notifications
    only close when they are clicked. It can quickly get annoying if you receive a lot of pics and only click one
    notification. After you viewed all pics, the other notifications won't close automatically.
  </p>

  <p>
    What you can do: Open the notification settings of piqchat in the <a href="App-prefs://prefs:root">iOS Settings App</a>
    and remove the checkbox "Notification Center" in the "Alerts" section. You will still get notifications when you
    use your phone or when you look at the lock screen, but they don't accumulate in the notification center.
  </p>
</details>

<div class="vertical-empty-space" />

<p>I'm using myself an iPhone and will try the best to make this "Quirks" page unnecessary!</p>
