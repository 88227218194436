<script>
  import { enqueueGroup, flushQueue } from '../functions/upload-queue'
  import { encryptAttachment } from '../functions/parcel-attachment-encryption.js'
  import { viewStack, sendToContact } from '../stores'
  import Camera from './Camera'
  import EditorImage from './EditorImage'
  import EditorVideo from './EditorVideo'
  import LayeredView from './LayeredView'
  import ContactSelection from './layered-views/ContactSelection'
  import Loading from './Loading'
  import ContactSelectionMediaPreview from './ContactSelectionMediaPreview'
  import { UPLOAD_QUEUE_ITEM_TYPES } from '../constants.js'

  let showEditor = null // null or VIDEO or IMAGE
  let isContactSelectionVisible = false
  let originalSource // can be an image element, canvas element or video file as blob
  let editedMediaBlob
  let duration

  function onTakeImage (event) {
    originalSource = event.detail.canvas
    showEditor = 'IMAGE'
  }

  function onTakeVideo (event) {
    originalSource = event.detail.file
    showEditor = 'VIDEO'
  }

  function retake () {
    originalSource = null
    showEditor = null
  }

  async function onExportFinished (event) {
    editedMediaBlob = event.detail.blob
  }

  async function showContactSelection (event) {
    isContactSelectionVisible = true

    if (!event.detail.waitForExport) {
      editedMediaBlob = event.detail.blob
    }

    if (event.detail.duration != null) {
      duration = event.detail.duration
    }
  }

  async function send (event) {
    $sendToContact = null

    const meta = { type: editedMediaBlob.type, duration, createdAt: (new Date()).toISOString() }
    const recipients = event.detail.selectedContacts

    const { secretKeys, attachment } = await encryptAttachment(editedMediaBlob, meta, recipients)
    const parcelGroupId = await enqueueGroup(
      UPLOAD_QUEUE_ITEM_TYPES.MEDIA_GROUP,
      recipients.map((recipient) => recipient.id),
      secretKeys,
      attachment
    )

    isContactSelectionVisible = false
    showEditor = null
    editedMediaBlob = null
    duration = null

    flushQueue([parcelGroupId])

    viewStack.push('Inbox')
  }

  function onCloseContactSelection () {
    editedMediaBlob = null
    duration = null
    isContactSelectionVisible = false
  }

  function allowSend (blob) {
    return blob && (blob.type?.includes('image') || blob.type?.includes('video'))
  }
</script>

<LayeredView visible={isContactSelectionVisible} on:close={onCloseContactSelection}>
  <ContactSelection on:cancel={onCloseContactSelection} on:confirm={send} disabled={!allowSend(editedMediaBlob)}>
    <ContactSelectionMediaPreview slot="footer" blob={editedMediaBlob} />
  </ContactSelection>
</LayeredView>

<div class="create-media" class:blur={$viewStack.length > 0}>
  <div class="component-wrapper">
    <Loading />

    {#if showEditor === 'IMAGE'}
      <EditorImage {originalSource} on:retake={retake} on:submit={showContactSelection} />
    {:else if showEditor === 'VIDEO'}
      <EditorVideo {originalSource} on:retake={retake} on:submit={showContactSelection} on:export={onExportFinished} isInBackground={isContactSelectionVisible} />
    {:else}
      <Camera on:image={onTakeImage} on:video={onTakeVideo} />
    {/if}
  </div>
</div>

<style>
  .create-media {
    height: var(--viewport-height);
    width: 100%;
    overflow: hidden;
    background-color: black;
  }

  .component-wrapper {
    border-radius: 1rem;
    aspect-ratio: 9 / 16;
    overflow: hidden;

    position: relative;
    transform: translate3d(0, -50%, 0); /* fixes border-radius overflow hidden on ios safari */
    max-height: var(--viewport-height);
    margin: 0 auto;
    top: 50%;
  }

  .create-media.blur :global(.media-view-container) {
    filter: blur(10px);
    transform: scale(1.3);
  }

  .create-media :global(.media-view-container) {
    position: relative;
    aspect-ratio: 9 / 16;
    transition: filter 300ms ease, transform 300ms cubic-bezier(1,0,0,1);
  }
</style>
