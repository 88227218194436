import { isUnauthorized, logger } from '../stores'

export function trackUnauthorizedResponse (response) {
  try {
    if (response.status === 403 || response.status === 401) {
      logger.error(new Error(`Unauthorized ${response.status}`))

      isUnauthorized.set(true)
    } else {
      isUnauthorized.set(false)
    }
  } catch (error) {
    console.error(error)
  }

  return response
}
