import { APP_WRAPPER_MESSAGE_TYPES } from '../constants.js'

const messageListeners = {}

window.appWrapperMessageListener = function appWrapperMessageListener (rawMessage) {
  let message = null

  try {
    message = JSON.parse(rawMessage)
  } catch {}

  const type = message?.type

  if (APP_WRAPPER_MESSAGE_TYPES.INCOMING[type] == null) {
    throw new Error(`Type "${type}" for message from app wrapper is unknown and can not be processed.`)
  }

  if (!Array.isArray(messageListeners[type])) {
    return
  }

  for (const listener of messageListeners[type]) {
    listener(message)
  }
}

export async function subscribeAppWrapperMessageOnce (type) {
  if (!Array.isArray(messageListeners[type])) {
    messageListeners[type] = []
  }

  return new Promise((resolve) => {
    messageListeners[type].push(resolve)
  })
}

export function sendMessageToAppWrapper (message) {
  if (APP_WRAPPER_MESSAGE_TYPES.OUTGOING[message?.type] == null) {
    throw new Error(`Type "${message?.type}" for message for app wrapper is unknown and will not be sent.`)
  }

  window.webkit.messageHandlers.appWrapper.postMessage(JSON.stringify(message))
}

export function isInAppWrapper () {
  return window?.webkit?.messageHandlers?.appWrapper != null
}
