<script>
  export let requestToShow = false

  let isVisible = false

  $: triggerVisibility(requestToShow)

  async function triggerVisibility () {
    if (isVisible || !requestToShow) {
      return
    }

    isVisible = true

    await new Promise((resolve) => setTimeout(resolve, 2000))

    isVisible = false
  }
</script>

{#if isVisible}
  <div class="splash">
    <div class="splash-particle" style:transform="rotate(0deg)" />
    <div class="splash-particle" style:transform="rotate(30deg)" />
    <div class="splash-particle" style:transform="rotate(60deg)" />
    <div class="splash-particle" style:transform="rotate(90deg)" />
    <div class="splash-particle" style:transform="rotate(120deg)" />
    <div class="splash-particle" style:transform="rotate(150deg)" />
    <div class="splash-particle" style:transform="rotate(180deg)" />
    <div class="splash-particle" style:transform="rotate(210deg)" />
    <div class="splash-particle" style:transform="rotate(240deg)" />
    <div class="splash-particle" style:transform="rotate(270deg)" />
    <div class="splash-particle" style:transform="rotate(300deg)" />
    <div class="splash-particle" style:transform="rotate(330deg)" />
  </div>
{/if}

<style>
  @keyframes splash-opacity-keyframes {
      0% {
          opacity: 0;
      }
      10%,
      80% {
          opacity: 1;
      }
      100% {
          opacity: 0;
      }
  }

  @keyframes splash-movement-keyframes {
      0% {
          width: 0;
          right: 40%;
      }
      10% {
          width: 10%;
          right: 50%;
      }
      50% {
          width: 60%;
          right: 100%;
      }
      100% {
          width: 0;
          right: 200%;
      }
  }

  .splash {
      position: relative;
      z-index: -1;
      top: -0.5em;
      left: 0;
      transform: rotate(10deg);

      --animation-iteration-count: 1;
      --animation-duration: 700ms;
  }

  .splash-particle {
      animation-name: splash-opacity-keyframes;
      animation-duration: var(--animation-duration);
      animation-iteration-count: var(--animation-iteration-count);
      animation-timing-function: linear;
      opacity: 0;
      position: absolute;
      height: 1px;
      width: 1em;
      transform-origin: bottom center;
  }

  .splash-particle:after {
      animation-name: splash-movement-keyframes;
      animation-duration: var(--animation-duration);
      animation-iteration-count: var(--animation-iteration-count);
      animation-timing-function: linear;
      content: '';
      position: absolute;
      display: block;
      height: 100%;
      width: 0;
      border-radius: 2px;
      background-color: var(--brand-color);
  }
</style>
