import { writable } from 'svelte/store'

export function createLoadingStore () {
  const { subscribe, set } = writable()

  return {
    subscribe,

    set (message) {
      console.log('Loading:', message)
      set(message)
    },

    clear () {
      set()
    }
  }
}
