function setViewportHeightCssVariable () {
  const rootElement = document.querySelector(':root')
  rootElement.style.setProperty('--viewport-height', window.innerHeight + 'px')
}

export function setupWindowResizeListener () {
  let resizeTimer

  window.addEventListener('resize', function () {
    clearTimeout(resizeTimer)

    resizeTimer = setTimeout(() => {
      setViewportHeightCssVariable()
    }, 250)
  })

  setViewportHeightCssVariable()
}
