const SIZE_LENGTH_OF_UTF8_TEXT = 1 // Can't be changed. The code assumes 1 byte

/**
 * Bytes Distribution (0 - 255)
 *
 * - The first byte is the length of the included UTF8 text (displayName and server)
 * - The following bytes are the UTF8 text, which length is defined by the previous byte
 * - The bytes after that, until the end is the publicKey in "raw"
 */

export function addMetaObjectToUint8Array (object, uint8array) {
  const jsonBytes = new TextEncoder('utf-8').encode(JSON.stringify(object))

  if (jsonBytes.length > 255) {
    throw new Error('Meta object encoding failed: Stringified object is exceeding character limit')
  }

  const mergedBytes = new Uint8Array(SIZE_LENGTH_OF_UTF8_TEXT + jsonBytes.length + uint8array.length)

  mergedBytes.set([jsonBytes.length])
  mergedBytes.set(jsonBytes, SIZE_LENGTH_OF_UTF8_TEXT)
  mergedBytes.set(uint8array, jsonBytes.length + SIZE_LENGTH_OF_UTF8_TEXT)

  return mergedBytes
}

export function splitMetaObjectFromUint8Array (mergedBytes) {
  let cursor = SIZE_LENGTH_OF_UTF8_TEXT
  const nameAndServerByteLength = mergedBytes.subarray(0, cursor)

  cursor += nameAndServerByteLength[0]
  const jsonBytes = mergedBytes.subarray(SIZE_LENGTH_OF_UTF8_TEXT, cursor)
  const object = JSON.parse(new TextDecoder().decode(jsonBytes))

  const uint8array = mergedBytes.slice(cursor)

  return { object, uint8array }
}
