import { inboxApi } from '../api'

export function isSupported () {
  return 'Notification' in window
}

export async function createSubscription (inboxServerHost) {
  const { vapidPublicKey } = await inboxApi.metaRequests.getServerInfo(inboxServerHost)

  await window.Notification.requestPermission()

  const registration = await window.navigator.serviceWorker.getRegistration()

  const subscription = await getSubscription()
  if (subscription != null) {
    subscription.unsubscribe()
  }

  return await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(vapidPublicKey)
  })
}

export async function revokeSubscription () {
  return (await getSubscription())?.unsubscribe()
}

export async function checkPermissions () {
  const hasNotification = await window.navigator.permissions.query({ name: 'notifications' })

  // TODO: find a solution which also works for iOS
  // let hasPush
  // try {
  //   hasPush = await window.navigator.permissions.query({ name: 'push', userVisibleOnly: true })
  // } catch (error) {
  //   // push is ignored because iOS throws a "Type error" when calling the query...
  // }

  const subscription = await getSubscription()

  return subscription && hasNotification?.state === 'granted'
}

function urlBase64ToUint8Array (base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }

  return outputArray
}

async function getSubscription () {
  const registration = await window.navigator.serviceWorker.getRegistration()
  return await registration?.pushManager?.getSubscription()
}
