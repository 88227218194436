import { writable } from 'svelte/store'

export function createViewStackStore () {
  const { subscribe, update } = writable([])

  return {
    subscribe,

    /**
     * @param {string} name - view name as registered in the views/index.js
     * @param {object} [props={}] - props for the view component
     * @param {string[]} [namesWhenToIgnore] - view names when to ignore the push
     */
    push (name, props, options) {
      update((stack) => {
        const once = options?.once === true
        const namesWhenToIgnore = options?.ignoreWhen ?? []
        const cssClass = options?.cssClass
        namesWhenToIgnore.push(name)

        let shouldBeIgnored = false

        if (once) {
          shouldBeIgnored = stack.some((view) => view.name === name)
        } else {
          shouldBeIgnored = namesWhenToIgnore.includes(stack[stack.length - 1]?.name)
        }

        if (!shouldBeIgnored) {
          stack.push({
            id: crypto.randomUUID(),
            name,
            cssClass,
            props: props ?? {}
          })
        }

        return stack
      })
    },

    disableClose () {
      update((stack) => {
        if (stack.length > 0) {
          stack[stack.length - 1].disableClose = true
        }

        return stack
      })
    },

    updateOptionsOfCurrent (options) {
      update((stack) => {
        if (stack.length > 0) {
          for (const [key, value] of Object.entries(options)) {
            stack[stack.length - 1][key] = value
          }
        }

        return stack
      })
    },

    pop (additionalProps) {
      update((stack) => {
        stack.pop()

        if (stack.length > 0 && additionalProps) {
          // When pop are given props, they are merged with the props of the new active view.
          const activeView = stack[stack.length - 1]
          for (const propKey in additionalProps) {
            activeView.props[propKey] = additionalProps[propKey]
          }
        }

        return stack
      })
    },

    popIfNotDisabled () {
      update((stack) => {
        if (stack.length > 0 && stack[stack.length - 1].disableClose !== true) {
          stack.pop()
        }

        return stack
      })
    },

    show (id) {
      update((stack) => {
        if (!stack.some((item) => item.id === id)) {
          stack.push({ id })
        } else {
          // TODO: this still triggers a subscribe!
        }

        return stack
      })
    },

    close (id) {
      update((stack) => {
        const index = stack.findIndex((item) => item.id === id)

        if (index > -1) {
          stack.splice(index, 1)
        }

        return stack
      })
    },

    clear () {
      update((stack) => {
        stack.splice(0)
        return stack
      })
    }
  }
}
