<script>
  import { viewStack, settings, logger } from '../../stores'
  import ViewHeader from '../ViewHeader'
  import InboxServerStatistics from '../InboxServerStatistics.svelte'
  import { processAndAcceptExchangeUrl, parseExchangeUrl, replaceHostPlaceholderInExchangeUrl } from '../../functions/contact-exchange-coder.js'
  import { inboxApi } from '../../api'
  import { contactsDeferredUpdate } from '../../functions/contacts-deferred-update.js'
  import { handleError } from '../../functions/handle-error.js'
  import { saveEncryptedContact, isContactDecryptable } from '../../functions/save-encrypted-contact.js'
  import { CONTACT_EXCHANGE_HOST_PLACEHOLDER } from '../../constants.js'

  export let exchangeUrl = null

  const hasProfile = $settings.inboxServerHost != null
  let errorMessage = ''
  let isLoading
  let isAccepting
  let newInboxServerHost
  let modifiedExchangeUrl
  let serverInfo = {}

  function cancel () {
    viewStack.pop()
    viewStack.push('ContactExchangeAccept', { changeInboxServerHost: true })
  }

  async function signup () {
    isAccepting = true

    try {
      const {
        encryptedContact,
        secretKey
      } = await processAndAcceptExchangeUrl(modifiedExchangeUrl || exchangeUrl, null, $settings.publicKey, $settings.displayName)

      let inviterDisplayName

      if (isContactDecryptable(encryptedContact, secretKey)) {
        inviterDisplayName = (await saveEncryptedContact(encryptedContact, secretKey)).displayName
      }

      if (hasProfile) {
        viewStack.pop()
        viewStack.push('MoveInboxServer', { newInboxServerHost, newInboxServerAdminName: serverInfo.adminDisplayName, inviterDisplayName })

        await contactsDeferredUpdate()
      } else {
        await settings.set('inboxServerHost', newInboxServerHost)

        viewStack.pop()
        viewStack.push('SetupFinish')
      }
    } catch (error) {
      errorMessage = handleError(error)
    }

    isAccepting = false
  }

  async function setup () {
    if (!hasProfile) {
      viewStack.disableClose()
    }

    isLoading = true

    try {
      newInboxServerHost = parseExchangeUrl(exchangeUrl).acceptUrlInboxServerHost

      if (newInboxServerHost === CONTACT_EXCHANGE_HOST_PLACEHOLDER) {
        newInboxServerHost = window.prompt('What is the domain of the inbox server?')

        try {
          const url = new URL(newInboxServerHost)

          if (url?.host) {
            // NOTE: .host can be empty if "{host}:{port}" is passed, because URL parses it as a protocol and path segment
            newInboxServerHost = url.host
          }
        } catch {}

        modifiedExchangeUrl = replaceHostPlaceholderInExchangeUrl(exchangeUrl, newInboxServerHost)

        logger.log(`Parsed ${newInboxServerHost} to this exchange url: ${modifiedExchangeUrl}`)
      }

      const shouldPersistServerInfo = modifiedExchangeUrl != null
      serverInfo = await inboxApi.metaRequests.getServerInfo(newInboxServerHost, shouldPersistServerInfo)

      if (!serverInfo.adminDisplayName || serverInfo.adminDisplayName?.trim() === '') {
        isLoading = false
        errorMessage = 'The inbox server is invalid. The inbox server info is not missing or incomplete.'
        return
      }
    } catch (error) {
      logger.error(error)

      if (error instanceof Response) {
        errorMessage = 'The address inside the friend invite does not belong to an inbox server.'
      } else {
        errorMessage = 'An error occurred while trying to get information about the inbox server.'
      }
    }

    isLoading = false
  }

  setup()
</script>

<ViewHeader disableClose={!hasProfile}>
  {#if errorMessage}
    Sign up to inbox server
  {:else}
    You are invited to join {serverInfo.adminDisplayName}'s inbox server
  {/if}
</ViewHeader>

{#if isLoading}
  <p>
    Loading...
    <svg class="feather-icon -normalize -rotate-animation"><use href="feather-sprite.svg#loader"/></svg>
  </p>
{:else if errorMessage}
  <p class="text-color-error">{errorMessage}</p>
{:else}
  {#if hasProfile}
    <div class="content-box -has-warning">
      <p>You are about to move to another inbox server. Your profile on your old inbox server will be permanently deleted.</p>
      <p>Your friends will be silently notified of your new inbox server so they know how to reach you.</p>
    </div>
  {:else}
    <p>You need to sign up to an inbox server to receive pics from your friends.</p>
  {/if}

  <p>
    Take a look at this inbox server and its policies.
    If you don't like what you see, you always have the option
    to accept a friend invite of another inbox server.
  </p>

  <div class="vertical-empty-space" />

  <div class="content-box">
    <p>
      The inbox server is provided by "{serverInfo.adminDisplayName}" on "<code>{newInboxServerHost}</code>".
    </p>

    <div class="button-row">
      <a
        class="-button-secondary -button-small -link-button text-align-center full-width"
        href="{serverInfo.privacyPolicyUrl}"
        target="_blank"
        class:-button-disabled={!serverInfo.privacyPolicyUrl}
      >
        Open privacy policy
        <svg class="feather-icon -normalize"><use href="feather-sprite.svg#external-link"/></svg>
      </a>
    </div>

    {#if !serverInfo.privacyPolicyUrl}
      <p>Caution! The inbox server didn't provide a link to their privacy policy.<p>
    {/if}
  </div>

  {#if serverInfo.adminMessage}
    <div class="content-box">
      <header>Message from {serverInfo.adminDisplayName}</header>

      <p class="white-space-pre-line">{serverInfo.adminMessage}</p>
    </div>
  {/if}

  {#if serverInfo.statistics}
    <h5>How popular is this server?</h5>

    <InboxServerStatistics statistics={serverInfo.statistics} />
  {/if}

  <p>
    <small>
      <svg class="feather-icon third-party-warning"><use href="feather-sprite.svg#alert-triangle"/></svg>
      Inbox servers are third-parties and are not related with the piqchat app.
    </small>
  </p>
{/if}

<div class="vertical-empty-space" />

<p class="display-flex justify-content-space-between">
  <button on:click={cancel} class="-button-secondary" disabled={isAccepting}>Cancel</button>

  <span style="margin-left: 30px" />

  <button on:click={signup} class="save -button-primary" disabled={isLoading || errorMessage || isAccepting}>
    Sign up {#if hasProfile}and move{/if}
  </button>
</p>

<div class="vertical-empty-space" />

<p>
  <button on:click={() => viewStack.push('InboxServerExplanation')} class="-button-link">What is an inbox server?</button>
</p>

<p>
  <button on:click={() => viewStack.push('ClientPrivacyPolicy')} class="-button-link">App Privacy Policy</button>
</p>

<style>
  .third-party-warning {
    width: 0.7rem;
    height: 0.7rem;
  }
</style>
