<script>
  export let top = false
  export let thin = false
  export let left = false
  export let right = false
</script>

<div
    class="action-bar"
    class:-thin={thin}
    class:-top={top}
    class:-right={right}
    class:-side={right || left}
>
  <slot name="left"><div /></slot>
  <slot name="middle"><div /></slot>
  <slot name="right"><div /></slot>
</div>

<style>
  .action-bar {
    --action-bar-height: 6rem;
    --action-bar-thin-height: 5rem;

    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    min-height: var(--action-bar-height);
    padding: 0 1.5rem;
    color: var(--white);
    touch-action: none;
    pointer-events: none;

    transition: opacity 100ms ease-in-out;
  }

  .action-bar.-thin {
    min-height: var(--action-bar-thin-height);
  }

  .action-bar.-top {
    bottom: auto;
    top: 0;
  }

  .action-bar.-side {
    flex-direction: column;
    width: var(--action-bar-height);
    min-height: auto;
  }

  .action-bar.-right {
    right: 0;
  }

  .action-bar.-side {
    height: 100%;
    justify-content: end;
  }

  .action-bar.-side.-top {
    justify-content: start;
  }

  .action-bar.-side > div:first-child,
  .action-bar.-side > div:last-child {
    min-height: var(--action-bar-height);
  }

  .action-bar.-side.-thin > div:first-child,
  .action-bar.-side.-thin > div:last-child {
    min-height: var(--action-bar-thin-height);
  }

  .action-bar :global(input),
  .action-bar :global(label),
  .action-bar :global(select),
  .action-bar :global(button) {
    color: var(--white);
    touch-action: initial;
    pointer-events: initial;
  }

  .action-bar :global(label),
  .action-bar :global(button) {
    display: inline-block;
    border-radius: 100%;
    aspect-ratio: 1/1;
    background-color: rgba(0, 0, 0, 0.4);
  }
</style>
