<script>
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  export let disabled
  export let disableHoldEvents

  let holdDelayTimer
  let isHolding

  async function startHolding (event) {
    clearTimeout(holdDelayTimer)

    if (disabled || disableHoldEvents) {
      return
    }

    holdDelayTimer = setTimeout(() => {
      isHolding = true

      dispatch('holdstart')

      document.addEventListener('touchend', cancelHolding)
      document.addEventListener('touchcancel', cancelHolding)

      event.preventDefault()
    }, 250)
  }

  async function cancelHolding () {
    clearTimeout(holdDelayTimer)

    document.removeEventListener('touchend', cancelHolding)
    document.removeEventListener('touchcancel', cancelHolding)

    dispatch('holdend')

    setTimeout(() => {
      // Wait a millisec so no click is triggered
      isHolding = false
    }, 100)
  }

  function onClick () {
    clearTimeout(holdDelayTimer)

    if (!isHolding) {
      dispatch('click')
    }
  }
</script>

<button
  class:-holding={isHolding}
  on:click={onClick}
  on:touchstart={startHolding}
  {disabled}
>
  <div class="circle" />
</button>

<style>
  button {
    width: 6em;
    height: 6em;
    padding: 1em;
    background-color: transparent;
    border: none;

    -webkit-tap-highlight-color: transparent;
  }

  button > .circle {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 5px solid var(--white);
    background-color: transparent;

    transition: background 100ms ease-in-out, transform 100ms ease-in-out;
  }

  button.-holding > .circle {
    transform: scale(1.4);
    background-color: var(--white);
    border: 2px solid var(--brand-color);

    animation: brand-shadow-pulse 1s infinite;
  }
</style>
