import { database } from '../stores/indexeddb'
import { loading, logger } from '../stores'
import { uploadMediaGroupParcel } from './upload-media-group-parcel.js'
import { UPLOAD_QUEUE_ITEM_TYPES, PARCEL_TYPES } from '../constants.js'

const STORE_NAME = 'upload_parcels_queue'

export function clearQueue () {
  return database.clear(STORE_NAME)
}

/**
 * @deprecated unused since switch to grouped parcel upload
 */
export async function enqueueBulk (parcels, type) {
  const tx = database.transaction(STORE_NAME, 'readwrite')

  for (const parcel of parcels) {
    const id = crypto.randomUUID()

    await tx.store.add({
      id,
      type,
      recipient: parcel.recipientId,
      attachment: parcel.attachment
    })
  }

  await tx.done
}

export async function enqueueGroup (type, recipientIds, secretKeys, attachment) {
  const id = crypto.randomUUID()

  await database.add(STORE_NAME, {
    id,
    type,
    recipientIds,
    secretKeys,
    attachment
  })

  return id
}

export function countItemsInQueue () {
  return database.count(STORE_NAME)
}

export async function getQueueItemIdsByRecipient () {
  const tx = database.transaction(STORE_NAME)

  let cursor = await tx.store.openCursor()
  const queueItemIds = new Map()

  while (cursor) {
    for (const recipientId of cursor.value.recipientIds) {
      if (queueItemIds.has(recipientId)) {
        queueItemIds.get(recipientId).push(cursor.value.id)
      } else {
        queueItemIds.set(recipientId, [cursor.value.id])
      }
    }

    cursor = await cursor.continue()
  }

  return queueItemIds
}

export async function removeItemsByRecipient (recipientId) {
  const tx = database.transaction(STORE_NAME, 'readwrite')

  let cursor = await tx.store.openCursor()

  while (cursor) {
    if (cursor.value.recipientIds.includes(recipientId)) {
      if (cursor.value.recipientIds.length === 1) {
        cursor.delete()
      } else {
        // Remove the recipient from the queue entry if it has other recipients
        cursor.update({
          ...cursor.value,
          recipientIds: cursor.value.recipientIds.filter((id) => id !== recipientId)
        })
      }
    }

    cursor = await cursor.continue()
  }
}

export async function flushQueue (queueItemIds) {
  loading.set('Sending')

  const queueItemIdsByRecipient = await getQueueItemIdsByRecipient()
  const queueItemIdsSet = new Set(queueItemIds)

  for (const queueItemId of queueItemIdsSet) {
    const queueItem = await database.get(STORE_NAME, queueItemId)

    if (!queueItem) {
      continue
    }

    let actionForQueueItem

    if (queueItem.type === UPLOAD_QUEUE_ITEM_TYPES.IMAGE) {
      logger.error('The upload queue item IMAGE type is no longer supported! The image is NOT dropped from the queue.')
    } else if (queueItem.type === UPLOAD_QUEUE_ITEM_TYPES.MEDIA_GROUP) {
      actionForQueueItem = await uploadMediaGroupParcel(queueItem, PARCEL_TYPES.MEDIA)
    }

    if (actionForQueueItem === 'DROP') {
      await database.delete(STORE_NAME, queueItem.id)

      addQueueItemIdsToFlushLoop(queueItemIdsSet, queueItem.recipientIds, queueItemIdsByRecipient)
    } else if (typeof actionForQueueItem === 'object') {
      await database.put(STORE_NAME, actionForQueueItem)

      const successfullRecipientIds = queueItem.recipientIds
        .filter((id) => !actionForQueueItem.recipientIds.includes(id))

      addQueueItemIdsToFlushLoop(queueItemIdsSet, successfullRecipientIds, queueItemIdsByRecipient)
    }
  }

  loading.clear()
}

/**
 * While the flush loop in the flushQueue method runs, all queue items of successful recipients
 * are added to the current loop. So it won't come to contact states which have unsent pics and no error.
 */
function addQueueItemIdsToFlushLoop (queueItemIdsSet, recipientIds, queueItemIdsByRecipient) {
  for (const recipientId of recipientIds) {
    for (const queueItemId of queueItemIdsByRecipient.get(recipientId)) {
      queueItemIdsSet.add(queueItemId)
      logger.log(`upload-queue.js/addQueueItemIdsToFlushLoop adding ${queueItemId.substring(0, 6)} because of recipient ${recipientId.substring(0, 6)}`)
    }
  }
}
