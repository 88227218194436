import { logger } from '../stores'
import { RELOAD_HANDLING_TYPES } from '../constants.js'

let alreadyProminentlyAsked = false

function isUpdatePossible () {
  return !!window.navigator.serviceWorker.controller
}

function requestRecachingInServiceWorker (reloadHandlingType) {
  window.navigator.serviceWorker.controller.postMessage({
    label: 'RELOAD_STATIC_CACHE',
    reloadHandling: reloadHandlingType
  })
}

export function updateApp (reloadHandlingType) {
  logger.log(`update app with reloadHandlingType: ${reloadHandlingType}`)

  if (isUpdatePossible()) {
    requestRecachingInServiceWorker(reloadHandlingType)
  } else if (reloadHandlingType === RELOAD_HANDLING_TYPES.FORCE) {
    window.location.reload()
  }
}

export async function checkForAppUpdate (isDebugMode) {
  const versionRegex = /^\d+.\d+.\d+$/
  const buildInfo = ROLLUP.BUILD_INFO
  let newVersion

  try {
    const response = await fetch(ROLLUP.VERSION_FILE)
    newVersion = (await response.json()).version
  } catch {}

  if (typeof newVersion !== 'string' || !versionRegex.test(newVersion)) {
    if (isDebugMode) logger.log(`checkForUpdate aborted because newVersion is invalid format: ${newVersion}`)
    return false
  }

  if (newVersion === buildInfo.version) {
    if (isDebugMode) logger.log(`checkForUpdate aborted because is the same as buildInfo, newVersion: ${newVersion}`)
    return false
  }

  logger.log(`version when redownloading: ${newVersion}`)

  if (!alreadyProminentlyAsked && isUpdatePossible()) {
    alreadyProminentlyAsked = true
    const confirmMessage = `A new app update is available!\n${buildInfo.version} -> ${newVersion}\n\nUpdate now?`

    if (window.confirm(confirmMessage)) {
      updateApp(RELOAD_HANDLING_TYPES.FORCE)
    }
  }

  return true
}
