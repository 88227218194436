<script>
  import { LAST_SEEN_LABELS } from '../constants.js'

  export let users
  export let createdBy

  const filteredUsers = users.filter((user) => user.createdBy === createdBy)

  function openPopup (user) {
    let message = ''

    if (user.displayName) {
      message += `Nickname: ${user.displayName}\n\n`
    }

    message += `Identifier: ${user.id}\n\nLast Seen: ${LAST_SEEN_LABELS[user.clientLastSeen]}`

    if (user.clientVersion) {
      message += `\n\nVersion: ${user.clientVersion}`
    }

    window.alert(message)
  }
</script>

{#each filteredUsers as user}
  <div class="user">
    <div class="info">
      <button class="-button-link" style={user.id == null ? 'text-decoration: none' : ''} on:click={() => openPopup(user)}>
        {#if user.displayName}
          {user.displayName}
        {:else if user.id != null}
          <code>{user.id.substring(0, 5)}</code>...
        {:else}
          —
        {/if}
      </button>

      <div>
        {#if user.clientVersion}{user.clientVersion.substring(0, 10)}, {/if}
        {#if user.id != null}{LAST_SEEN_LABELS[user.clientLastSeen]}{:else}deleted{/if}
      </div>
    </div>

    <svelte:self {users} createdBy={user.entryId} />
  </div>
{/each}

<style>
  .user {
    margin-left: 1rem;
    margin-top: 0.3rem;
  }

  .user > .info {
    display: flex;
    justify-content: space-between;
    line-height: 1.2;
  }

  .user:nth-child(2n) {
    background-color: var(--white);
  }

  .user:nth-child(2n+1) {
    background-color: var(--light-grey);
  }
</style>
