import { viewStack, logger } from '../stores'
import { RELOAD_HANDLING_TYPES, PARCEL_TYPES } from '../constants.js'
import { backgroundSync } from './background-sync.js'

export function handleServiceWorkerMessages (eventData, settings) {
  const typesHandler = {
    SERVER_PUSH: handleServerPush,
    NOTIFICATION_CLICKED: handleNotificationClick,
    STATIC_CACHE_MANUAL_UPDATE: handleManualCacheUpdate,
    LOG: handleLogMessage
  }

  if (eventData.label in typesHandler) {
    typesHandler[eventData.label](eventData, settings)
  } else {
    logger.warn(`handleServiceWorkerMessages unhandled message '${eventData?.label}'`)
  }
}

function handleServerPush (eventData, settings) {
  logger.log(`handleServiceWorkerMessages/handleServerPush ${eventData.pushType}, ${JSON.stringify(eventData.pushPayload)}`)

  if (eventData.pushType === 'PARCEL' && [PARCEL_TYPES.IMAGE, PARCEL_TYPES.MEDIA].includes(eventData.pushPayload.type)) {
    // TODO: somehow solve this, so settings does not need to be passed around all the time...
    backgroundSync(settings)
  }
}

function handleNotificationClick (eventData) {
  logger.log(`handleServiceWorkerMessages/handleNotificationClick ${eventData.notificationTag}`)

  viewStack.push('Inbox')
}

function handleManualCacheUpdate (eventData) {
  const showPrompt = () => window.confirm('Reload to finish redownload of app?')

  if (
    eventData.reloadHandling === RELOAD_HANDLING_TYPES.FORCE ||
    (eventData.reloadHandling === RELOAD_HANDLING_TYPES.ASK && showPrompt())
  ) {
    window.location.reload()
  }
}

function handleLogMessage (eventData) {
  logger.log(`SW: ${eventData.message}`)
}
