<script>
  import { loading } from '../stores'
  import { fly } from 'svelte/transition'
</script>

{#if $loading}
  <div transition:fly="{{ y: -10, duration: 100 }}">
    <svg class="feather-icon -rotate-animation">
      <use href="feather-sprite.svg#loader"/>
    </svg>

    <span>{$loading}...</span>
  </div>
{/if}

<style>
  div {
    position: absolute;
    z-index: 10000;
    top: 1rem;
    left: 1.3rem;
    color: var(--white);
    font-size: 0.8rem;
  }

  div > span,
  div > .feather-icon {
    vertical-align: middle;
  }

  div > .feather-icon {
    width: 1.2rem;
  }
</style>
