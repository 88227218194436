import { viewStack } from '../stores'
import { IS_IOS } from '../constants.js'

export function handleBrowserBackNavigation () {
  if (IS_IOS) {
    // Installed iOS apps don't give a possibility to navigate back, but propably(?) do if there is a pushstate available.
    return
  }

  window.addEventListener('load', function () {
    window.history.pushState({}, '')
  })

  window.addEventListener('popstate', function () {
    window.history.pushState({}, '')

    viewStack.popIfNotDisabled()
  })
}
