<script>
  import { onDestroy } from 'svelte'

  export let blob

  $: editedMediaDataUrl = getDataUrlFromBlob(blob)

  function getDataUrlFromBlob (blob) {
    if (!blob) {
      return null
    }

    return URL.createObjectURL(blob)
  }

  function revokeDataUrl () {
    URL.revokeObjectURL(editedMediaDataUrl)
  }

  async function openShareMenuForVideo () {
    if (navigator.share) {
      const extension = blob.type.split('/')[1]?.split(';')[0]
      const file = new File([blob], `media.${extension}`, { type: blob.type })

      navigator.share({ files: [file] })
    }
  }

  onDestroy(() => {
    revokeDataUrl()
  })
</script>

<div class="display-flex align-items-end">
  {#if blob && editedMediaDataUrl}
    {#if blob.type?.includes('image')}
      <img src={editedMediaDataUrl} class="media-preview" alt="Preview" on:load={revokeDataUrl} />
    {:else if blob.type?.includes('video')}
      <video src={editedMediaDataUrl} class="media-preview" autoplay playsinline muted loop />
    {/if}
  {:else}
    <div class="media-preview" />
  {/if}

  <button on:click={openShareMenuForVideo} disabled={!blob || !navigator.share} class="-button-reset">
    <svg class="feather-icon -normalize"><use href="feather-sprite.svg#share"/></svg>
  </button>
</div>

<style>
  .media-preview {
    width: 2.5rem;
    max-width: 2.5rem;
    border-radius: 0.3rem;
    vertical-align: middle;
    background-color: var(--light-grey);
    aspect-ratio: 9/16;
    text-align: center;

    pointer-events: initial;
    touch-action: initial;
    user-select: initial;
    -webkit-user-select: initial;
    -webkit-touch-callout: initial;
  }
</style>
