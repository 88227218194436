import { box, randomBytes } from 'tweetnacl'
import { addMetaObjectToUint8Array, splitMetaObjectFromUint8Array } from './meta-object-with-uint8array'
import { packUint8Array, unpackUint8Array } from '../functions/pack-uint8array.js'
import { database } from '../stores/indexeddb'

/**
 * @deprecated
 */
export async function encryptImageAsSeparateParcels (blob, meta, recipients) {
  const arrayBuffer = await blob.arrayBuffer()
  const secretContent = addMetaObjectToUint8Array(meta, new Uint8Array(arrayBuffer))

  const privateKey = (await database.get('settings', 'privateKey')).value

  return recipients.map((recipient) => {
    const nonce = randomBytes(box.nonceLength)

    const encrypted = box(
      secretContent,
      nonce,
      recipient.publicKey,
      privateKey
    )

    const attachment = packUint8Array(encrypted, [nonce])

    return { recipientId: recipient.id, attachment }
  })
}

/**
 * @deprecated
 */
export async function decryptImageParcel (attachment, senderPublicKey) {
  const privateKey = (await database.get('settings', 'privateKey')).value

  const { flexibleArray: encrypted, fixedArrays } = unpackUint8Array(attachment, [box.nonceLength])

  const secretContent = box.open(
    encrypted,
    fixedArrays[0],
    senderPublicKey,
    privateKey
  )

  const { object, uint8array } = splitMetaObjectFromUint8Array(secretContent)

  return {
    meta: object,
    mediaBytes: uint8array
  }
}
