export function getIosVersion () {
  const matchGroups = /iP.+OS (\d{1,}_*\d*_*\d*)/
    .exec(navigator.userAgent)

  if (!matchGroups || matchGroups.length !== 2) {
    return
  }

  return matchGroups[1].split('_')?.map((n) => parseInt(n))
}
